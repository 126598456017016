import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography, Divider, Button } from "@material-ui/core";
import CustomButton from "./CustomButton";
import { bookicon, bulbicon, cancleicon, chaticon, reficon, rightgreenicon, suitecaseicon, unlikeicon } from "../assets";
import CustomChip from "./CustomChip";
import JobTitleComponent from "./JobTitleComponent";
import ApplyJobForm from "./ApplyJobForm";
import { JobDetailLoader, JobDetails, StateOfCatelogue } from "../CatalogueController1";
import CustomChipComponent from "../../../../../packages/components/src/CustomChipComponent.web";

const useStyles = makeStyles((theme: Theme) => ({
    jobDetailsContainer: {
        "borderRadius": "16px",
        "border": "1px solid #F4F4F4",
        "background": "#FFF",
        padding: "16px 12px",
        display: "flex",
        "flexDirection": "column"
    },
    title0: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "display": "flex",
        "alignItems": "center",
        "paddingTop": "7px"
    },
    title1: {
        "color": "#6D97C1",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px"
    },
    title2: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "textAlign": "left",
        "minWidth": "100px"
    },
    description: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "whiteSpace": "pre-wrap"
    },
    expandBtn: {
        "color": "#1F497D",
        "textAlign": "right",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "textTransform": "none",
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    noBtn: {
        "borderRadius": "8px",
        "border": "1px solid #1F497D",
        "color": "#17365D",
        "padding": "8px",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        }
    },
    yesBtn: {
        "borderRadius": "8px",
        "border": "1px solid #C7D9F0",
        "color": "#17365D",
        "padding": "8px",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        background: "#C7D9F0",
        "&:hover": {
            background: "#C7D9F0"
        }
    },
    questionText: {
        "color": "#1F497D",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px"
    },
    refBtn: {
        padding: "0px",
        margin: "0px",
        "minWidth": "12px"
    },
    title3: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px"
    },
    rootContainer: {
        height: 'fit-content'
    }
}));

type FormValues = {
    full_phone_number: string,
    email: string,
    resume: string | File,
    cover_letter: string | File
}

export type Props = {
    setSkillToShow: () => void,
    handleApplyJob: (type: string) => void,
    getTitleOfApplyButton: (type: string) => string,
    getJobDetails: JobDetails,
    handleSaveAndUnSaveJob: (flag: boolean) => void,
    applyJobHandler: (status: boolean) => void,
    applyJob?: boolean,
    submitJobDetailHandler?: (data: FormValues) => void,
    jobStatus: boolean,
    resumeRadioButtonSelected?: (file: any) => void,
    handleShowMore?: () => void,
    showMore?: boolean,
    handleLikeAndDislikeJob: (flag: boolean) => void,
    loader:JobDetailLoader,
    progress:number,
    remainingTime:number,
    mainState: StateOfCatelogue,
    goToSignUpPage: () => void,
    goToLoginPage: () => void,
    handleNavigationToMessage: () => void,
    getJobFilledDetails: () => void,
    saveDraftJobApplyData: (data: {email: string, full_phone_number: string, resume: { url: string | File, name: string, size: number, selectId: string | number }[], cover_letter: { url: string | File, name: string, size: number, selectId: string | number }[]}) => void;
}

const JobDetailsCard = (Props: Props) => {

    const { setSkillToShow, handleApplyJob, getTitleOfApplyButton, getJobFilledDetails, saveDraftJobApplyData, handleNavigationToMessage, goToLoginPage, goToSignUpPage, getJobDetails, handleLikeAndDislikeJob, mainState, handleSaveAndUnSaveJob, handleShowMore, showMore, resumeRadioButtonSelected, applyJobHandler, applyJob, submitJobDetailHandler, jobStatus, loader, progress, remainingTime } = Props
    const classes = useStyles();
    return (
        <Grid item container sm={12} md={6} xs={12} lg={6} style={{ position: "sticky", top: "10px" }}>
            {applyJob ? <ApplyJobForm applyJob={applyJob} saveDraftJobApplyData={saveDraftJobApplyData} loader={loader} progress={progress} remainingTime={remainingTime} mainState={mainState} resumeRadioButtonSelected={resumeRadioButtonSelected} applyJobHandler={applyJobHandler} getJobDetails={getJobDetails} submitJobDetailHandler={submitJobDetailHandler ? (data: FormValues) => submitJobDetailHandler(data) : () => { }} />
                :
                <>
                    <JobTitleComponent handleApplyJob={handleApplyJob} getTitleOfApplyButton={getTitleOfApplyButton} getJobFilledDetails={getJobFilledDetails} handleNavigationToMessage={handleNavigationToMessage} goToLoginPage={goToLoginPage} goToSignUpPage={goToSignUpPage} jobStatus={jobStatus} getJobDetails={getJobDetails} handleSaveAndUnSaveJob={handleSaveAndUnSaveJob} applyJobHandler={applyJobHandler} handleLikeAndDislikeJob={handleLikeAndDislikeJob}/>

                    <Grid style={{ paddingTop: "25px" }} sm={12}>
                        <Grid container spacing={1} className={classes.jobDetailsContainer} sm={12} xs={12}>
                            <Grid item>
                                <Typography className={classes.title0}>Job Description</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.description}>{getJobDetails?.attributes?.job_description}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title0}><img src={suitecaseicon} alt="icon" />&nbsp;Job Type</Typography>
                            </Grid>
                            <Grid item container>
                                <Grid item>
                                    <CustomChip title={getJobDetails?.attributes?.job_categories} type="approve" icon={rightgreenicon} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title0}><img src={bookicon} alt="icon" />&nbsp;Education</Typography>
                            </Grid>
                            <Grid item container>
                                <Grid item>
                                    <CustomChip title={getJobDetails?.attributes?.education} type="approve" icon={rightgreenicon} />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title0}>Industry Type</Typography>
                            </Grid>
                            <Grid item container>
                                <Typography className={classes.title3}>
                                    {getJobDetails?.attributes?.industry_type}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title0}>Department</Typography>
                            </Grid>
                            <Grid item container>
                                <Typography className={classes.title3}>
                                    {getJobDetails?.attributes?.department}
                                </Typography>
                            </Grid>

                            {getJobDetails.attributes.skills_required.length > 0 && <>
                                <Grid item>
                                    <Typography className={classes.title0}><img src={bulbicon} alt="icon" />&nbsp;Skills</Typography>
                                </Grid>
                                <Grid item container spacing={2}>
                                    {[...new Set(getJobDetails.attributes.skills_required.slice(0, 6))].map((item: any, index: number) => (
                                        <Grid item key={index}>
                                            <CustomChipComponent type="decline" title={item} key={item} />
                                        </Grid>
                                    ))}
                                    {[...new Set(getJobDetails.attributes.skills_required.slice(0, 6))].length >= mainState.skillCount && (
                                        <Grid item>
                                            <Button className={classes.expandBtn} onClick={setSkillToShow} id="load-more">
                                                + three more
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </>}
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>

    )
}

export default JobDetailsCard