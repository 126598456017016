import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { getLocation, getLoginToken, getUserRole, isLoggedIn, getIdFromParams } from "../../../components/src/Utils.web";
import { toast } from "react-toastify";
import { RefObject, createRef } from "react";
import { save, saveBlue } from "./assets";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
export interface ImageOrVideo {
  id: number;
  url: string;
}

export interface UserReasons {
  id: number,
  reason: string,
  created_at: string,
  updated_at: string
  }


export interface EventDataOne{
  id: string,
  isdisliked: boolean,
  isliked:boolean,
  likes_count:number,
  dislikes_count:number
}

export interface UpdateResponse {
  id: number,
  parent_id?: number,
  isliked: boolean,
  isdisliked: boolean,
  likes_count: number,
  dislikes_count: number,
  post_id: string
}

export interface Account {
  account_name: string;
  account_profile: string;
  account_id: string | number;
}

export interface FollowResponse {
  attributes: {
    is_followed: boolean,
    profile_details: {
      data: {
        attributes: {
          account_details: { id: string }
        },
        id: string
      }
    }
  }
}

export interface Post {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    body: string;
    location: string;
    account_id: number;
    category_id: number;
    created_at: string;
    updated_at: string;
    model_name: string;
    images: ImageOrVideo[];
    isliked: boolean;
    isdisliked: boolean;
    likes_count: number;
    dislikes_count: number;
    comment_count: number;
    account: Account;
    comment: Comment[];
    is_saved: boolean;
    following_account: boolean;
    sub_topic?: string;
    connection_request_details: ConnectionRequestDetails | null;
  };
}

export interface ConnectionRequestDetails {
  id?: number;
  sender_id?: number;
  receiver_id?: number;
  status?: string | "accepted" | "pending";
}

export interface Comment {
  id: number;
  commentable_type: string;
  account_id: number;
  commentable_id: number;
  parent_id: number | null;
  created_at: string;
  comment: string;
  updated_at: string;
  dislikes_count: number;
  likes_count: number;
  isliked: boolean;
  comment_images: CommentImage[];
  isdisliked: boolean;
  account: Account;
  reply_to: number | null;
  replies: Reply[];
  reply_count: number;
}

export interface Reply extends Comment {
  commentable_id: number;
  commentable_type: string;
  parent_id: number | null;
  comment_images: CommentImage[];
  reply_to: number | null;
  replies: Reply[];
  reply_count: number;
}

export interface CommentImage {
  id: number | string;
  url: string;
}


export interface State {
  token: string;
  commentData: any;
  loading: boolean;
  errorMsg: string;
  commentsArray: any;
  comment: string;
  currentPage: number;
  replyCommentId: any;
  allPosts: Post[];
  isInfiniteLoading: boolean;
  postShowID: number | string;
  showComment: boolean;
}

export interface EventData extends Post {
}
export interface UserDetails {
  id: string;
  type: string;
  attributes: {
    id: number;
    full_name: string;
    location: string;
    current_title: string;
    full_phone_number: string;
    email: string;
    experience: string;
    salary: string;
    future_self: string;
    current_company: string;
    company_indusry: string;
    bio: string;
    work_email_id: string | null;
    personal_email_id: string | null;
    city: string;
    country: string;
    skills: string[];
    selected_group_context: string;
    open_for_direct_approach: boolean | null;
    potential_score: number | null;
    relevance_score: number | null;
    overall_rank: number | null;
    photo: string;
    account_details: {
      id: number;
      full_name: string;
      email: string;
      activated: boolean;
      full_phone_number: string;
      type: string;
      role: string;
    };
    work_experiences: {
      data: string[];
    };
    resume: string;
    cover_letter: string;
  };
};
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  selectedSubCategory: string | number;
  postType: string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  jobIdFromQuery: string;
  postLoading: boolean;
  isLoadingPost: boolean;
  progressComment:number,
  remainingTimeComment:number,
  loaderComment:boolean,
  commentData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  currentPage: number;
  isInfiniteLoading: boolean;
  allPosts: Post[];
  showComment: boolean;
  postShowID: number | string;
  showOptions: any,
  deletePost: any,
  deletedPostSuccess: boolean,
  showMoreOptions: boolean,
  showCreatedPost: boolean,
  commentslength: number,
  selectedPost: number | string,
  userDetails: UserDetails,
  openInvitationDialog: {
    open: boolean;
    receiverId: number | string
  };
  coords : {
    latitude: number,
    longitude: number,
  };
  currentPost?: Post,
  isExpanded: boolean,
  reportedSuccessfully: boolean,
  expandedPosts: string[],
  reportClick: boolean,
  userReasons: UserReasons[],
  selectedReason: {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string,
    otherReason: string
  },
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  apiCommentItemCallId: string = "";
  commentApiCallId: string = "";
  createCommentId: string = "";
  likeCommentId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.sentinelRef = createRef();
    this.intersectionObserver = null;
    this.getAllPostsApiId = "";
    this.getAllCommentsForApiId = "";
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      jobIdFromQuery: "",
      isLoadingPost: false,
      postLoading: false,
      progressComment: 0,
      remainingTimeComment: 0,
      loaderComment: false,
      commentData: [],
      errorMsg: "",
      token: "",
      loading: false,
      comment: "",
      commentsArray: [],
      replyCommentId: null,
      currentPage: 1,
      isInfiniteLoading: false,
      allPosts: [] as Post[],
      showComment: false,
      postShowID: Infinity,
      showOptions: {},
      deletePost: {},
      deletedPostSuccess: false,
      showMoreOptions: false,
      showCreatedPost: false,
      commentslength: 2,
      selectedPost: Infinity,
      userDetails: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          full_name: "",
          location: "",
          current_title: "",
          full_phone_number: "",
          email: "",
          experience: "",
          salary: "",
          future_self: "",
          current_company: "",
          company_indusry: "",
          bio: "",
          work_email_id: "",
          personal_email_id: "",
          city: "",
          country: "",
          skills: [],
          selected_group_context: "",
          open_for_direct_approach: null,
          potential_score: null,
          relevance_score: null,
          overall_rank: null,
          photo: "",
          account_details: {
            id: 0,
            full_name: "",
            email: "",
            activated: false,
            full_phone_number: "",
            type: "",
            role: ""
          },
          work_experiences: {
            data: []
          },
          resume: "",
          cover_letter: "",
        }
      },
      openInvitationDialog: {
        open: false,
        receiverId: ""
      },
      coords : {
        latitude: 0,
        longitude: 0,
      },
      isExpanded: false,
      expandedPosts: [],
      reportClick: false,
      reportedSuccessfully: false,
      selectedReason: {
        id: 0,
        reason: "",
        created_at: "",
        updated_at: "",
        otherReason: ""
      },
      userReasons: [{
        id: 1,
        reason: "",
        created_at: "",
        updated_at: ""
      }],

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    } else {
      this.getToken();
    }
    // Customizable Area Start

    let paramID = this.props.navigation.getParam("postId");
    const userRole = getUserRole()

      this.getCurrentLocation()
        .finally(() => {
          if(paramID) {
            this.getSinglePost(paramID);
          } else {
            this.getAllPostData(1);
          }
        userRole === 'recruiter' ?
          window.dispatchEvent(new CustomEvent("TALENT_POOL_LIST", { detail: true })) :
          window.dispatchEvent(new CustomEvent("JOB_LIST", { detail: true }))
      })
      this.setupIntersectionObserver();
      this.setState({ deletedPostSuccess: false })
      window.addEventListener("UPVOTE_DOWNVOTE", this.handleUpvoteDownvote as EventListener);
      window.addEventListener("CREATED_POST", this.getCreatedPost as EventListener);
      window.addEventListener("GET_USER_DETAILS", this.handleProfiledata as EventListener);
      window.addEventListener("RENDER_MY_FEED", this.handleMyFeed as EventListener);
      window.addEventListener("DISPLAY_ALL_POST", this.handleNewPost as EventListener);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        switch (webApiRequestCallId) {
          case this.getAllPostsApiId:
            return this.handleAllPostResponse(responseJson);
          case this.getAllSavedPostsApiId:
            return this.handleAllSavedPostResponse(responseJson);
          case this.getAllCommentsForApiId:
            return this.handleAllCommentsResponse(responseJson);
          case this.downvotePostCommentApiCallId:
          case this.upvotePostCommentApiCallId:
            return this.handleAllCommentsResponseOne({...responseJson.content} as UpdateResponse);
          case this.getPostDetailsApiId:
            return this.handleGetPostDetailsResponse(responseJson);
          case this.deletePostApiId:
            return this.handleDeletePostResponse(responseJson);
          case this.deleteCommentApiId:
            return this.getCommentsOfPost(this.state.postShowID);
          case this.updateCommentApiCallID:
            return this.getCommentsOfPost(this.state.postShowID);
          case this.hidePostApiId:
              return this.handleHidePostResponse();
          case this.savePostApiId: 
            return this.handleSaveResponse(responseJson);
          case this.removeInvitationPostApiCallId:
            return this.handleRemoveInviteResponse(responseJson);
          case this.sendInvitationPostApiCallId:
            return this.handleInviteResponse(responseJson);
          case this.followUserPostApiCallId:
            return this.handleFollowResponse(responseJson);
          case this.followUserDeleteApiCallId:
            return this.handleFollowDeleteResponse(responseJson);
          case this.getsinglePostApiId:
            this.getCreatedPost({ detail: responseJson.data } as CustomEvent)
            return;
          case this.reasonApiCallId:
            return this.handleGetReasonResponse(responseJson);
          case this.addReasonsApiCallId:
            return this.handleAddReasonAPIResp(responseJson)  
          default:
            return null
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAllPostsApiId: string | Message = "";
  getAllSavedPostsApiId: string | Message = "";
  getAllCommentsForApiId: string | Message = "";
  downvotePostCommentApiCallId: string | Message = "";
  upvotePostCommentApiCallId: string | Message = "";
  getPostDetailsApiId: string | Message = "";
  deletePostApiId: string | Message = "";
  deleteCommentApiId: string | Message = "";
  updateCommentApiCallID: string | Message = "";
  savePostApiId: string = "";
  followUserPostApiCallId: string = "";
  followUserDeleteApiCallId: string = "";
  sendInvitationPostApiCallId: string = "";
  removeInvitationPostApiCallId:string = ""
  hidePostApiId:string = ""
  sentinelRef: RefObject<HTMLDivElement>;
  intersectionObserver: IntersectionObserver | null;
  getsinglePostApiId: string = "";
  addReasonsApiCallId: string = "";
  reasonApiCallId: string = ""

  async componentDidUpdate(prevProps: Props) {
    if(prevProps.selectedSubCategory !== this.props.selectedSubCategory || prevProps.postType !== this.props.postType) {
      const containerDiv = document.getElementById('create-comment')
      if (containerDiv) { containerDiv.scrollIntoView() }  
      this.setState({
        postLoading: true,
        currentPage: 0, 
        deletedPostSuccess: false
      })  
      this.props.postType === 'saved' ? this.getAllSavedPostData(1, this.props.postType) : this.getAllPostData(1, this.props.postType)
    }
  }

  getCurrentLocation = async (): Promise<void> => {
    return getLocation()
    .then((position) => {
      this.setState({
        coords: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        },
      });
    }).catch(() => {
      this.setState({
        coords: {
          latitude: 0,
          longitude: 0
        },
      });
    })
  };

  handleReasonAPIResp = (apiResponse: {flag_reasons?: UserReasons[]}) => {
    if (apiResponse.flag_reasons) {
      this.setState({ userReasons: apiResponse.flag_reasons })
    }
  }

  renderSaveIcon = (saved: boolean) => {
    return saved ? saveBlue : save
  }
  
  handleAllPostResponse = (responseJson : {data : []}) => {
    this.setState({
      postLoading: false,
      isLoadingPost: false
    })
    if(responseJson.data) {
      if (this.state.currentPage <= 1) {
        this.setState({
          allPosts: responseJson.data
        });
        return;
      }
      this.setState({
        allPosts: [...this.state.allPosts, ...responseJson.data]
      })
    }
  }

  getProfileUrl = ()=> {
    const userRole = getUserRole()
    return userRole === 'recruiter' ? 'RecruiterProfile' : 'JobApplicantProfile'
  }

  handleRecruiterNavigation = (recruiterId: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), recruiterId === this.state.userDetails.attributes.account_details.id ? this.getProfileUrl() : 'Profile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), recruiterId);
    this.send(message);
}


  handleAllSavedPostResponse = (responseJson : {saved_items : {data: []}} ) => {
    this.setState({
      postLoading: false
    })
    if(responseJson?.saved_items) {
      if (this.state.currentPage <= 1) {
        this.setState({
          allPosts: responseJson.saved_items.data
        });
        return;
      }
      this.setState({
        allPosts: [...this.state.allPosts, ...responseJson.saved_items.data]
      })
    } else {
      this.setState({
        allPosts: [...this.state.allPosts]
      })
    }
  }

  getSinglePost = async(postId: string) => {
    let token = await getStorageData("authToken");

    const header = {
      'Content-Type': configJSON.commentsContentType,
      token: token
    };
    const getSinglePost = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getsinglePostApiId = getSinglePost.messageId;
    getSinglePost.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getSinglePost}/${postId}`);
    getSinglePost.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getSinglePost.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.commentsApiMethodType);

    runEngine.sendMessage(getSinglePost.id, getSinglePost);
  }

  handleAllCommentsResponse = (responseJson: {data: Comment[] }) => {
    if(responseJson?.data) {
      this.setState({
        allPosts: this.state.allPosts.map((post: Post) => {
          return post.attributes.id === parseInt(this.state.postShowID as string) ? { ...post, attributes: { ...post.attributes, comment: responseJson.data, comment_count: responseJson.data.length }, } : post
        })
      })
    }
  }

  handleAllCommentsResponseOne = (responseJson: UpdateResponse) => {
    const { id, isdisliked, isliked, likes_count, dislikes_count, post_id, parent_id } = responseJson;
  
    const postsMap = new Map(this.state.allPosts.map(post => [post.id, post]));
    const post = postsMap.get(post_id);
  
    if (post) {
      let updated = false;
  
      if (!!parent_id) {
        updated = this.updateCommentMethod(post.attributes.comment, responseJson);
      } else {
        const comment = post.attributes.comment.find(comment => comment.id === id);
        if (comment) {
          Object.assign(comment, { isdisliked, isliked, dislikes_count, likes_count });
          updated = true;
        }
      }
  
      if (updated) {
        this.setState({
          allPosts: this.state.allPosts.map(p => p.id === post_id ? post : p),
        });
      }
    }
  }
  
  updateCommentMethod = (comments: Comment[], response: UpdateResponse): boolean => {
    for (let comment of comments) {
      if (comment.id === response.id) {
        Object.assign(comment, {
          likes_count: response.likes_count,
          dislikes_count: response.dislikes_count,
          isliked: response.isliked,
          isdisliked: response.isdisliked,
        });
        return true;
      }
  
      if (comment.replies?.length && this.updateCommentMethod(comment.replies, response)) {
        return true;
      }
    }
    return false;
  }
  

  handleGetPostDetailsResponse = (responseJson: { data: { id: string } }) => {
    const index = this.state.allPosts.findIndex(post => post.id === responseJson.data.id);
    if (index !== -1) {
      const updatedPosts = [...this.state.allPosts];

      updatedPosts[index] = {
        ...updatedPosts[index],
        ...responseJson.data
      };
      this.setState({
        allPosts: updatedPosts
      });
    }
  }

  handleDeletePostResponse = (responseJson: {message: string}) => {
    if (responseJson.message) {
      this.setState({ deletePost: {}, deletedPostSuccess: true })
      window.dispatchEvent(new CustomEvent("DELETE_POST", { detail: true }))
    }
  }

  handleHidePostResponse = () => {
    let updatedPosts = [...this.state.allPosts];
    updatedPosts = updatedPosts.filter((post) => post.id !== this.state.selectedPost)

    this.setState({
      allPosts: updatedPosts
    });
  }

  handleFollowDeleteResponse = (responseJson: {message: string, data: {data: FollowResponse}}) => {
    const followResponse = 'data' in responseJson.data ? responseJson.data.data : null;

    if (followResponse) {
      this.setState({
        allPosts: this.state.allPosts.map((post) => {
          const postAccountId = Number(post.attributes.account_id);
          const responseAccountId = Number(followResponse.attributes.profile_details.data.attributes.account_details.id);

          if (postAccountId === responseAccountId) {
            return {
              ...post,
              attributes: {
                ...post.attributes,
                following_account: followResponse.attributes.is_followed,
              },
            };
          } else {
            return post;
          }
        }),
      });
    }
  }

  handleFollowResponse = (responseJson: {message: string, data: FollowResponse }) => {
    const followData = responseJson?.data;

    if (followData) {
      this.setState({
        allPosts: this.state.allPosts.map((postData) => {
          const postAccountId = Number(postData.attributes.account_id);
          const responseAccountId = Number(followData.attributes.profile_details.data.attributes.account_details.id);

          if (postAccountId === responseAccountId) {
            return {
              ...postData,
              attributes: { ...postData.attributes, following_account: followData.attributes.is_followed },
            };
          } else {
            return postData;
          }
        }),
      });
    }
};

  handleInviteResponse = (responseJson: { connection_request: {data: {attributes: {receiver_details: { data: { id: string } }, status: string, id: number}}}, error?: string; errors?: { message: string }[]; meta?: { message: string }; message?: string }) => {
    if (responseJson.error) {
      toast.error(responseJson.error);
    } else if (responseJson.errors && responseJson.errors.length > 0) {
      toast.error(responseJson.errors[0].message);
    } else {
      toast.success(responseJson.message || (responseJson.meta && responseJson.meta.message));
      if(this.state.currentPost) {
        this.followUser(this.state.currentPost.attributes.account_id, this.state.currentPost.attributes.id)
      }
    }

    if (responseJson.connection_request) {
      this.setState({
        allPosts: this.state.allPosts.map((post, index) => {
          const postAccountId = Number(post.attributes.account_id);
          const responseAccountId = Number(responseJson.connection_request.data.attributes.receiver_details.data.id);

          if (postAccountId === responseAccountId) {
            return {
              ...post,
              attributes: {
                ...post.attributes,
                connection_request_details: { ...post.attributes.connection_request_details, status: responseJson.connection_request.data.attributes.status, id: responseJson.connection_request.data.attributes.id }
              },
            };
          } else {
            return post;
          }
        }),
      });
    }

    this.setState({
      currentPost: undefined
    })
  }

  handleRemoveInviteResponse = (responseJson: { connection_request: {data: {attributes: {receiver_details: {data: {id: string}}, id: number , status: string}}}, error?: string; errors?: { message: string }[]; meta?: { message: string }; message?: string }) => {
    if (responseJson.error) {
      toast.error(responseJson.error);
    } else if (responseJson.errors && responseJson.errors.length > 0) {
      toast.error(responseJson.errors[0].message);
    } else {
      toast.success(responseJson.message || (responseJson.meta && responseJson.meta.message));
    }
    if (responseJson.connection_request) {
      this.setState({
        allPosts: this.state.allPosts.map((post, index) => {
          const postAccountId = Number(post.attributes.account_id);
          const responseAccountId = Number(responseJson.connection_request.data.attributes.receiver_details.data.id);

          if (postAccountId === responseAccountId) {
            return {
              ...post,
              attributes: {
                ...post.attributes,
                connection_request_details: null
              },
            };
          } else {
            return post;
          }
        }),
      });
    }

  }

  handleCondition = (condition: boolean, truePart: string, falsePart: string) => {
    return condition ? truePart : falsePart
  }

  renderDivider = (condition: boolean, element: JSX.Element) => {
    return condition && element
  }

  renderImage = (condition: boolean, element: JSX.Element, elementTwo: JSX.Element) => {
    return condition  ? element : elementTwo
  }

  handleSaveResponse = (responseJson: { saved_post: {id: number, is_saved: boolean}, error?: string; errors?: { message: string }[]; meta?: { message: string }; message?: string }) => {
    if (responseJson.error) {
      toast.error(responseJson.error);
    } else if (responseJson.errors && responseJson.errors.length > 0) {
      toast.error(responseJson.errors[0].message);
    } else {
      toast.success(responseJson.message || (responseJson.meta && responseJson.meta.message));
    }
    if (this.props.postType === 'saved') {
      return this.getAllSavedPostData(this.state.currentPage)
    }
    if(responseJson.saved_post) {
      this.setState({
        allPosts: this.state.allPosts.map((post) => {return Number(post.id) === responseJson.saved_post.id ? {...post , attributes: {...post.attributes, is_saved: responseJson.saved_post.is_saved}} : post})
      })
    }
  }

  handleMyFeed = () => {
    this.setState({ currentPage: 1, deletedPostSuccess: false })
    this.getAllPostData(1)
    const containerDiv = document.getElementById('create-comment')
    if (containerDiv) { containerDiv.scrollIntoView() }
  }

  handleNewPost = (event: CustomEvent<EventData>) => {
    this.setState({
      allPosts: [event.detail, ...this.state.allPosts]
    })
  }

  handleLoadmoreComment = () => {
    this.setState({
      commentslength: this.state.commentslength + 2
    })
  }
  handleProfiledata = (event: CustomEvent<UserDetails>) => {
    this.setState({
      userDetails: event.detail
    })
  }


  handleGetPostDetailsResponseOne = ( id: string, isdisliked: boolean, isliked:boolean, likes_count:number, dislikes_count:number) => {
    const index = this.state.allPosts.findIndex(post => post.id == id);
    if (index !== -1) {
      const updatedPosts = [...this.state.allPosts];

      updatedPosts[index] = { 
        ...updatedPosts[index],
        attributes: {...updatedPosts[index].attributes, id: +id, isliked, isdisliked,likes_count, dislikes_count}
      };
      this.setState({
        allPosts: updatedPosts
      });
    }
  }

  handleUpvoteDownvote = (event: CustomEvent<EventDataOne>) => {
    let {id, isdisliked, isliked, likes_count, dislikes_count} = event.detail;
    this.handleGetPostDetailsResponseOne(id, isdisliked, isliked, likes_count, dislikes_count)
  }

  getSaveText = (isSaved: boolean) => {
    return  isSaved ? 'Unsave' : 'Save'
  }

  handleFollowPost = (post: Post) => {
    return post.attributes.following_account ?  this.deleteFollowUsers(post.attributes.account_id, post.attributes.id) : this.followUser(post.attributes.account_id, post.attributes.id)
  }

  handleCOnditionrender = (condition :any, truePart :any, falsePart: any) => {
    return condition ? truePart : falsePart
  }

  handleInvitation = (post: Post) => {
    this.setState({
      currentPost : post
    })
    if(post.attributes.connection_request_details === null) {
      this.handleInvitationDialogOpen(post.attributes.account_id, post.attributes.id)
    }
  }

  truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength);
  };

  toggleReadMore = (postId: string) => {
    this.setState((prevState) => {
      const { expandedPosts } = prevState;
      const isPostExpanded = expandedPosts.includes(postId);

      return {
        expandedPosts: isPostExpanded
          ? expandedPosts.filter(id => id !== postId)
          : [...expandedPosts, postId],
      };
    });
  };
  getFollowText = (isFollowed: boolean) => {
    return isFollowed ? 'Following' : 'Follow'
  }

  handleInvitationDialogOpen = (receiverId: number | string, postId: number | string) => {
    this.setState({
      selectedPost: postId,
      openInvitationDialog: {
        open: true,
        receiverId: receiverId
      },
    })
  } 

  handleLoadMore = () => {
    if(this.state.showCreatedPost || this.state.allPosts.length <= 0) {
      return;
    }
    let newPage = this.state.showMoreOptions ? 1 : (this.state.currentPage + 1)
    this.setState({ currentPage: newPage })
    this.props.postType === 'saved' ? this.getAllSavedPostData(newPage, this.props.postType) : this.getAllPostData(newPage, this.props.postType)
  }



  setupIntersectionObserver = () => {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.handleLoadMore()
        }
      });
    });
    if (this.sentinelRef.current) {
      this.intersectionObserver.observe(this.sentinelRef.current);
    }
  }

  disconnectIntersectionObserver = () => {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  componentWillUnmount = async () => {
    this.disconnectIntersectionObserver();
  }



  handleDeletePostApi = (id: string) => {
    let token = getLoginToken();

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };

    const deletePost = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePostApiId = deletePost.messageId;
    deletePost.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostApi}/${id}`
    );

    deletePost.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deletePost.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(deletePost.id, deletePost);
  }

  handleToggleOptions = (postId: any) => {
    this.setState((prevState) => ({
      showOptions: {
        ...prevState.showOptions,
        [postId]: !prevState.showOptions[postId]
      }
    }));
  };

  deletePostHandler = (postId: any) => {
    this.setState((prevState) => ({
      deletePost: {
        ...prevState.deletePost,
        [postId]: !prevState.deletePost[postId]
      },
      showOptions: {
        ...prevState.showOptions,
        [postId]: !prevState.showOptions[postId]
      }
    }));
  }

  editPostHandler = (postId: any) => {
    window.dispatchEvent(new CustomEvent("EDIT_POST", { detail: true }))
    this.setState((prevState) => ({
      showOptions: {
        ...prevState.showOptions,
        [postId]: !prevState.showOptions[postId]
      }
    }));
  }

  getCreatedPost = (event: CustomEvent<EventData>) => {
    if (event.detail) {
      this.setState({
        allPosts: [event.detail],
        showMoreOptions: true,
        showCreatedPost: true
      })
    }
  }

  handleGetReasonResponse = (apiResponse: {flag_reasons?: UserReasons[]}) => {
    if (apiResponse.flag_reasons) {
      this.setState({ userReasons: apiResponse.flag_reasons })
    }
  }

  handleInvite = (noteValue: string) => {
    this.sendInvitation(noteValue, this.state.openInvitationDialog.receiverId)
  }


  handleInvitationClose = () => {
    this.setState({
      openInvitationDialog: {
        ...this.state.openInvitationDialog,
        open: false,
      },
    })
  }

  getConnectionStatus = (post : Post) => {
    return this.handleCondition(post?.attributes?.connection_request_details?.status === 'pending', "Pending Request"
    , this.handleCondition(post?.attributes?.connection_request_details?.status === 'accepted', "Connected", "Connect"))
  }


  getAllPostData = (page: number, postType?:string) => {
    this.setState({
      postLoading: page <= 1 ? true : false,
      isLoadingPost: true,
      showCreatedPost: false
    })

    let token = getLoginToken();
    if (page) {
      this.setState({
        isInfiniteLoading: true, showMoreOptions: false
      })
    }
    const header = token ? {
      'Content-Type': configJSON.ApiContentType,
      token: token
    } : {
      'Content-Type': configJSON.ApiContentType
    };

    
    let URL = `${configJSON.getAllPosts}?page=${page}&per_page=${5}`
    
    if(this.props.selectedSubCategory){
      URL+= `&sub_topic=${this.props.selectedSubCategory}`
    }
    if(postType === "following" || postType === "popular") {
      if (!isLoggedIn()) {
        this.goToLoginPage();
        return;
      }
      URL += `&section=${postType}`
    }
    if(this.state.coords.latitude && this.state.coords.longitude ) {
      URL += `&latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPostsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllSavedPostData = (page?: number, postType?:string) => {
    if (!isLoggedIn()) {    
      this.goToLoginPage();
      return;
    }

    let token = getLoginToken();
    if (page) {
      this.setState({
        isInfiniteLoading: true, showMoreOptions: false, showCreatedPost: false
      })
    }
    const header = token ? {
      'Content-Type': configJSON.ApiContentType,
      token: token
    } : {
      'Content-Type': configJSON.ApiContentType
    };

    let URL = `${configJSON.getAllSavedPosts}?item_type=posts&page=${page}&per_page=${5}`

    if(this.props.selectedSubCategory){
      URL+= `&sub_topic=${this.props.selectedSubCategory}`
    }
    if(postType) {
      URL += `&section=${postType}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSavedPostsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      URL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCommentsOfPost = (id: number | string) => {
    let token = getLoginToken();

    const header = token ? {
      'Content-Type': configJSON.ApiContentType,
      token: token
    } : {
      'Content-Type': configJSON.ApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCommentsForApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_comments/comments?commentable_id=${id}&commentable_type=BxBlockPosts::Post`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPostDetails = (id: number | string) => {
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPostDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createComment = (comment: string = "", files: File[] = [], nested: boolean = false, cID: number | string = "", postID: number | string = "", callback: () => void = () => {}) => {
    const authToken = getLoginToken();
    const startTime = Date.now();
    if (files.length > 0) {
      this.setState({ loaderComment: true })
    }
    const formData = new FormData();
    formData.append("comment[commentable_id]", `${cID}`);
    formData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
    formData.append("comment[comment]", `${comment}`);
    formData.append("comment[post_id]", `${postID}`);

    files.forEach((file, index) => {
      formData.append(`comment[comment_images][]`, file);
    });

    const config: AxiosRequestConfig = {
      headers: { token: authToken ?? '' },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const { loaded, total } = progressEvent;
        if (total) {
          const percentCompleted = Math.round((loaded * 100) / total);

          const currentTime = Date.now();
          const elapsedTime = (currentTime - startTime) / 1000;

          const uploadSpeed = loaded / elapsedTime;

          const remainingTime = (total - loaded) / uploadSpeed;

          this.setState({ progressComment: percentCompleted, remainingTimeComment: remainingTime })

          if(percentCompleted == 100){
            callback();
          }
        }
      }
    };

    axios.post(`${configJSON.baseURL.baseURL}/${configJSON.createCommentEndpoint}`, formData, config)
      .then(response => {
        this.getCommentsOfPost(this.state.postShowID);
        this.setState({
          allPosts: this.state.allPosts.map((post: Post) => {
            return post.attributes.id == this.state.postShowID ? { ...post, attributes: { ...post.attributes, comment_count: post.attributes.comment_count + 1 }, } : post
          }),loaderComment: false, progressComment: 0, remainingTimeComment: 0
        })
      })
      .catch(error => {
      })
      .finally(() => {
        this.setState({
          loaderComment: false, progressComment: 0, remainingTimeComment: 0
        })
      });
  }

  getCommentData(): boolean {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCommentItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  setComment = (comment: string) => {
    this.setState({ comment });
  };

  likeChildComments = (commentId: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const data = {
      attributes: {
        comment_id: commentId,
      },
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCommentText(comment: any) {
    try {
      return JSON.parse(comment.replace("=>", ":"))?.text;
    } catch {
      return comment;
    }
  }

  toggleComments = (post: Post) => {
    this.getCommentsOfPost(post.id);
    this.setState({ showComment: !this.state.showComment, postShowID: post.id !== this.state.postShowID ? post.id : "", commentslength: 2 });
  };

  handleSelect = (reason: {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string,
    otherReason: string
  }) => {
    this.setState({ selectedReason: reason })
  }

  handleReportDialogue = (postId?: number | string ) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    if (!this.state.reportClick) {
      this.setState({
        selectedPost: postId || ""
      })
      this.getReasonsList()
    }
    this.setState({
      reportClick: !this.state.reportClick,
      selectedReason: {
        id: 0,
        reason: "",
        created_at: "",
        updated_at: "",
        otherReason: ""
      },
    })
  }

  handleAddReasonAPIResp = (apiResponse: { message?: string } | { error?: string }) => {
    if ('error' in apiResponse) {
      toast.error(apiResponse.error)
    } else if ('message' in apiResponse) {
      const allPosts = [...this.state.allPosts]
      const updatedPosts = allPosts.filter((post) => post.id !== this.state.selectedPost)

      this.setState({
        allPosts: updatedPosts,
        selectedPost: "",
        reportedSuccessfully: true,
        reportClick: false,
        selectedReason: {
          id: 0,
          reason: "",
          created_at: "",
          updated_at: "",
          otherReason: ""
        }
      })
    }
  }

  closeSuccessDialogue = () => {
    this.setState({
      reportedSuccessfully: false,
    })
  }

  addReasons1 = async () => {
    const token = await getStorageData("authToken")
    const headers = {
      token,
    };

    const formData = new FormData();
    formData.append("post_id", `${this.state.selectedPost}`);
    this.state.selectedReason.otherReason ?
      formData.append("other_reason", this.state.selectedReason.otherReason) :
      formData.append("reason_id", `${this.state.selectedReason.id}`)
    
    const addReportsDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReasonsApiCallId = addReportsDataMessage.messageId;
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReasonsApiEndPoint
    );
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(addReportsDataMessage.id, addReportsDataMessage);
  };

  getReasonsList = async () => {
    const token = await getStorageData("authToken")

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const reasonsData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reasonsData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.reasonApiCallId = reasonsData.messageId;
    reasonsData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resonEndPointApi}`
    );
    reasonsData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(reasonsData.id, reasonsData);
  }

  upvotePostComment = (id: number | string, type: string, postID: number|string) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    const header = {
      token: getLoginToken(),
    };


    let formData = new FormData();
    formData.append("likeable_id", `${id}`);
    formData.append("likeable_type", type);
    formData.append("post_id",`${postID}` )

    const upvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upvotePostCommentApiCallId = upvotePostMessage.messageId;

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upvoteEndpoint
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
  }

  downvotePostComment = (id: number | string, type: string, postId: number | string) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    const header = {
      token: getLoginToken(),
    };

    let formData = new FormData();
    formData.append("dislikeable_id", `${id}`);
    formData.append("dislikeable_type", type);
    formData.append("post_id", `${postId}`);

    const downvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downvotePostCommentApiCallId = downvotePostMessage.messageId;

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downvoteEndpoint
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
  }

  goToLoginPage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleDeleteComment = (commentId: number | string) => {
    const header = {
      token: getLoginToken(),
    };

    const downvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCommentApiId = downvotePostMessage.messageId;

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCommentApiEndpoint}/${commentId}`
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
  }


  updateComment = (comment: string = "", files: (File | { url: string })[] = [], nested: boolean = false, cID: number | string = "", postID: number | string = "") => {
    const headers = {
      "token": getLoginToken(),
    };
    let data = new FormData();

    data.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
    data.append("comment[comment]", `${comment}`);

    files.forEach((file: File | { url: string }, _index: number) => {
      data.append(`comment[comment_images][]`, (file as { url: string })?.url ? (file as { url: string }).url : file as File);
    });

    const updateUserDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCommentApiCallID = updateUserDetails.messageId;

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_comments/comments/${cID}`
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );
    runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
  }

  handleSavePostApi = async (postId: number | string, isSaved?: boolean) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    this.setState({
      selectedPost: postId
    })
    const authToken = await getLoginToken()
  
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": authToken
    };
  
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.savePostApiId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isSaved ? `${configJSON.savePostEndpoint}/${postId}/unsave_post` : `${configJSON.savePostEndpoint}/${postId}/save_post`
    );
  
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
  
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        isSaved ? configJSON.httpDeleteMethod : configJSON.exampleAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  followUser = async (accountId: number | string, postId: number | string) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }

    this.setState({
      selectedPost: postId
    })

    const authToken = await getLoginToken()

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
        token: authToken,
    };

    let requestBody = {
      "data": {
        "type": "follow",
        "attributes": {
          "account_id": accountId
        }
      }
    }

    const followUserMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followUserPostApiCallId = followUserMessage.messageId;

    followUserMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    followUserMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
    );

    followUserMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.followUserApiEndPoint}?token=${authToken}`
    );

    followUserMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(followUserMessage.id, followUserMessage);
}

deleteFollowUsers = async (accountId: number | string, postId: number | string) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }

  this.setState({
    selectedPost: postId
  })

  
  const authToken = await getLoginToken()

  const header = {
    "Content-Type": configJSON.commentsContentType,
      token: authToken,
  };

  const followUserMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.followUserDeleteApiCallId = followUserMessage.messageId;

  followUserMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  followUserMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followUserApiEndPoint}/${accountId}?token=${authToken}`
  );

  followUserMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
  );

  runEngine.sendMessage(followUserMessage.id, followUserMessage);
}

sendInvitation = async (noteValue: string, receiverId: string | number) => {    
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }

  const authToken = await getLoginToken()

  const header = {
    "Content-Type": configJSON.exampleApiContentType,
      token: authToken,
  };

  let invitationBody = {
    "connection_request": {
      "receiver_id": receiverId,
      "note": noteValue
    }
  }

  const sendInvitationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.sendInvitationPostApiCallId = sendInvitationMessage.messageId;

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(invitationBody)
  );

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendInvitationEndpoint
  );

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
}

removeInvitation = async (connectionId: string | number, postId: string | number) => {    
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }

  this.setState({
    selectedPost: postId
  })

  const authToken = await getLoginToken()

  const header = {
    "Content-Type": configJSON.exampleApiContentType,
      token: authToken,
  };

  const sendInvitationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.removeInvitationPostApiCallId = sendInvitationMessage.messageId;

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeInvitationEndpoint}/${connectionId}/withdraw_connection_request`
  );

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
  );

  runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
}

handleHidePostApi = async (postId: number | string) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }

  this.setState({
    selectedPost: postId
  })


  const authToken = await getLoginToken()

  const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.hidePostApiId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savePostEndpoint}/${postId}/hide_post`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  // Customizable Area End
}
