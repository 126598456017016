import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RefObject, createRef } from "react";
import { toast } from "react-toastify";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { getUserRole, handleConditionFunction, isLoggedIn } from "../../../../packages/components/src/Utils.web";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { getLocation } from "../../../components/src/Utils.web";

export interface UserReasons {
  id: number,
  reason: string,
  created_at: string,
  updated_at: string
  }

export interface FollowResponse {
  attributes: {
    is_followed: boolean,
    profile_details: {
      data: {
        attributes: {
          account_details: { id: string }
        },
        id: string
      }
    }
  }
}

export interface JobDetails {
  id: string;
  type: "job";
  attributes: {
    job_title: string;
    job_description: string;
    remote_job: boolean;
    location: string;
    employment_type_id: string | null;
    total_inteview_rounds: number | null;
    company_name: string;
    requirements: string | null;
    salary: string | null;
    experience_required: string;
    skills_required: string[];
    application_deadline: string | null;
    job_categories: string;
    country: string;
    city: string;
    post_expire_after: string;
    salary_from: string;
    salary_to: string;
    disclose: boolean;
    education: string;
    industry_type: string;
    department: string;
    status: string;
    draft_page: number;
    created_at: string;
    is_saved: boolean;
    is_disliked: boolean;
  };
}

export interface Reply {
  id: number;
  commentable_type: string;
  commentable_id: number;
  parent_id: number | null;
  comment: string;
  created_at: string;
  updated_at: string;
  likes_count: number;
  dislikes_count: number;
  isliked: boolean;
  isdisliked: boolean;
  comment_images: { id: number | string, url: string }[];
  account: Account;
  reply_count: number;
  reply_to: number | null;
  replies: Reply[];
}

export interface Comment {
  id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  account_id: number,
  parent_id: number | null;
  updated_at: string;
  created_at: string;
  dislikes_count: number;
  likes_count: number;
  isliked: boolean;
  isdisliked: boolean;
  comment_images: { id: number | string, url: string }[];
  account: Account;
  reply_to: number | null;
  reply_count: number;
  replies: Reply[];
}

export interface PostDetails {
  id: string;
  type: "post";
  attributes: {
    id: number;
    name: string;
    body: string;
    description: string;
    location: string;
    category_id: number;
    account_id: number;
    sub_category_id: string;
    created_at: string;
    sub_topic: string;
    updated_at: string;
    images: Image[];
    model_name: string;
    isliked: boolean;
    is_saved: boolean;
    isdisliked: boolean;
    likes_count: number;
    comment_count: number;
    dislikes_count: number;
    account: Account;
    sub_category: SubCategory;
    category: Category;
    following_account: boolean;
    comment: Comment[];
    connection_request_details: {
      id?:number,
      sender_id?:number,
      receiver_id?:number,
      status?: string | "accepted" | "pending",
    } | null
  };
}

export interface UserDetails {
  id?: string,
  bio?: string,
  email?: string,
  city?: string,
  full_name?: string,
  experience?: string,
  account_details?: {
      id?: number
  },
  resume?: {
      url: string,
      name: string,
      size: number
  } | null,
  skills: [string][],
  photo?: string,
  cover_letter?: {
      url: string,
      name: string,
      size: number,
  } | null,
  open_for_direct_approach: boolean,
  full_phone_number?: string,
  work_experiences: {
      data: []
  },
}

export interface Image {
  id: number;
  url: string;
  type: string;
}

export interface Account {
  account_id: number;
  account_name: string;
  account_profile: string;
}
export interface Category {
  name: string;
}

export interface SubCategory {
  name: string;
}

export interface AccountDetails {
  id: string;
  type: "account";
  attributes: {
    activated: boolean;
    country_code: string | null;
    email: string;
    full_name: string | null;
    full_phone_number: string;
    phone_number: string | null;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    role: string;
    profile_details: ProfileDetails;
    connection_request_details: {
      id?:number,
      sender_id?:number,
      receiver_id?:number,
      status?: string | "accepted" | "pending",
    } | null
  };
}

export interface ProfileDetails {
  data: {
    id: string;
    type: "profile";
    attributes: ProfileAttributes;
  };
}

export interface ProfileAttributes {
  id: number;
  full_name: string | null;
  location: string | null;
  current_title: string | null;
  full_phone_number: string | null;
  email: string;
  experience: string | null;
  salary: string | null;
  future_self: string | null;
  current_company: string | null;
  company_industry: string | null;
  bio: string | null;
  work_email_id: string | null;
  personal_email_id: string | null;
  city: string | null;
  country: string | null;
  skills: string[];
  selected_group_context: string | null;
  open_for_direct_approach: boolean;
  potential_score: number | null;
  relevance_score: number | null;
  overall_rank: number | null;
  company_name: string | null;
  website: string | null;
  company_size: string | null;
  added_to_pipeline: boolean;
  pipeline_id: number | null;
  photo: string | null;
  account_details: AccountSummary;
  work_experiences: WorkExperiences;
  resume: string | null;
  cover_letter: string | null;
}

export interface AccountSummary {
  id: number;
  full_name: string | null;
  email: string;
  activated: boolean;
  full_phone_number: string;
  type: string;
  role: string;
}

export interface WorkExperiences {
  data: any[];
}

export interface SearchResult {
  jobs: {
    data: JobDetails[],
  },
  posts: {
    data: PostDetails[],
  },
  accounts: {
    data: AccountDetails[]
  },
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  userRole:string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  searchText:string;
  getAllSearchResults:SearchResult | null,
  showSearchResults:boolean,
  showAllPost:boolean,
  showAllPeople:boolean,
  showAllJobs:boolean,
  anchorPopoverEl:HTMLElement | null,
  loading: boolean,
  userDetails: UserDetails,
  selectedPostId: number | string,
  showComment: boolean;
  postShowID: number | string;
  openInvitationDialog: {
    open: boolean;
    receiverId: number | string
  };
  selectedJobId: number | string;
  progressComment:number,
  remainingTimeComment:number,
  loaderComment:boolean,
  coords : {
    latitude: number,
    longitude: number,
  },
  expandedPosts: string[],
  currentReceiverId: string | number
  reportedSuccessfully: boolean,
  reportClick: boolean,
  userReasons: UserReasons[],
  selectedReason: {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string,
    otherReason: string
  },
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  divRef: RefObject<HTMLDivElement>;
  advancedsearchApiCallId: any = "";
  userProfileGetApiCallId: string = "";
  followUserPostApiCallId: string = "";
  followDeleteUserPostApiCallId: string = ""
  getPostApiCallId: string = "";
  upVotePostApiCallId: string = ""
  downVotePostApiCallId: string = ""
  upVotePostCommentApiCallId: string = ""
  downVotePostCommentApiCallId: string = ""
  getAllCommentsForApiId: string = ""
  createCommentApiCallID: string = ""
  savePostApiId: string = ""
  sendInvitationPostApiCallId: string = ""
  removeInvitationPostApiCallId: string = ""
  saveJobApiCallId: string = ""
  unsaveJobApiCallId: string = ""
  jobUnlikeApiCallId: string = ""
  hidePostApiId: string = ""
  updateCommentApiCallID: string = ""
  deleteCommentApiId: string = ""
  addReasonsApiCallId: string = "";
  reasonApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      userRole:'',
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      searchText:"",
      getAllSearchResults:null,
      showSearchResults:false,
      showAllPost:true,
      showAllPeople:true,
      showAllJobs:true,
      anchorPopoverEl:null,
      loading: false,
      userDetails: {
        open_for_direct_approach: false,
        work_experiences: { data: [] },
        skills: []
      },
      selectedPostId: 0,
      showComment: false,
      postShowID: Infinity,
      openInvitationDialog: {
        open: false,
        receiverId: ""
      },
      selectedJobId: "",
      progressComment: 0,
      remainingTimeComment: 0,
      loaderComment: false,
      coords : {
        latitude: 0,
        longitude: 0,
      },
      expandedPosts: [],
      currentReceiverId: "",
      reportClick: false,
      reportedSuccessfully: false,
      selectedReason: {
        id: 0,
        reason: "",
        created_at: "",
        updated_at: "",
        otherReason: ""
      },
      userReasons: [{
        id: 1,
        reason: "",
        created_at: "",
        updated_at: ""
      }],
      // Customizable Area End
    };
    // Customizable Area Start
    this.divRef = createRef<HTMLDivElement>();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const userRole = localStorage.getItem('userRole');
    if (userRole) {
    this.setState({ userRole });
  }
    this.getUserProfile();
    document.addEventListener('mousedown', this.handleClickOutside);
    const searchKey = this.props.navigation.getParam("search_key")
    if(searchKey) {
      this.getCurrentLocation().finally(() => {
        this.handleSearchData(searchKey);
      })
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.advancedsearchApiCallId:
            return this.handleAdvanceSearchResult(responseJson);
          case this.getPostApiCallId:
            return this.handleGetPostResponse(responseJson)
          case this.userProfileGetApiCallId:
            return this.handleGetUserData(responseJson);
          case this.followUserPostApiCallId:
            return this.handleFollowResponse(responseJson);
          case this.followDeleteUserPostApiCallId:
            return this.handleFollowDeleteResponse(responseJson);          
          case this.upVotePostApiCallId:
          case this.downVotePostApiCallId:
            return this.handleVoteResponse(responseJson)
          case this.upVotePostCommentApiCallId:
            return this.handleCreateComment(responseJson)
          case this.downVotePostCommentApiCallId:
            return this.handleCreateComment(responseJson)
          case this.createCommentApiCallID:
            return this.handleCreateComment(responseJson)
          case this.getAllCommentsForApiId:
            return this.handleUpdateComments(responseJson)
          case this.savePostApiId:
            return this.handleVoteResponseOne(responseJson)
          case this.sendInvitationPostApiCallId:
            return this.handleInvitationResponse(responseJson)
          case this.removeInvitationPostApiCallId: 
            return this.handleInvitationResponse(responseJson, true)
          case this.saveJobApiCallId: 
            return this.handleSaveJobResponse(responseJson, "is_saved")
          case this.unsaveJobApiCallId: 
            return this.handleSaveJobResponse(responseJson, "is_saved")
          case this.jobUnlikeApiCallId: 
            return this.handleSaveJobResponse(responseJson, "is_disliked")
          case this.hidePostApiId: 
            return this.handleHidePostResponse(responseJson)
          case this.deleteCommentApiId: 
            return this.getCommentsOfPost(this.state.postShowID)
          case this.reasonApiCallId:
            return this.handleGetReasonResponse(responseJson);
          case this.addReasonsApiCallId:
            return this.handleAddReasonAPIResp(responseJson)  
          default:
            return null
        }
      }

      runEngine.debugLog("API Message Recived", message);

    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  handleClickOutside(event: MouseEvent) {
    if (this.divRef.current && !this.divRef.current.contains(event.target as Node)) {
      this.setState({
        showSearchResults: false
      })
      }
  }

  getCurrentLocation = async (): Promise<void> => {
    return getLocation()
      .then((position) => {
        this.setState(
          {
            coords: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            },
          }
        );
      }).catch(() => {
        this.setState({
          coords: { latitude: 0, longitude: 0 },
        });
      })
  };

  navigateToMessage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Messaging');
    message.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props
    );

    this.send(message);
  }

  handleRecruiterNavigation = (recruiterId: string | number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), recruiterId === this.state.userDetails?.account_details?.id ? this.getProfileUrl() : 'Profile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), recruiterId);
    this.send(message);
  }

  getProfileUrl = () => {
    const userRole = getUserRole()
    return handleConditionFunction(userRole === 'recruiter', 'RecruiterProfile', 'JobApplicantProfile')
  }

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleCondition = (condition: boolean, truePart: string, falsePart: string) => {
    return condition ? truePart : falsePart
  }

  hasNoResultFound = () => {
    return this.state.getAllSearchResults && Object.values(this.state.getAllSearchResults).every((result) => result.data.length === 0)
  }

  hasSearchResults() {
    const { getAllSearchResults } = this.state;
    return getAllSearchResults !== null &&
      Object.values(getAllSearchResults).some(result => result.data.length > 0);
  }

  showAllResults() {
    const { showAllPost, showAllPeople, showAllJobs } = this.state;
    return showAllPost && showAllPeople && showAllJobs;
  }

  notShowAllResults() {
    const { showAllPost, showAllPeople, showAllJobs } = this.state;
    return !showAllPost || !showAllPeople || !showAllJobs;
  }

  getDisplayText() {
    const { showAllPost, showAllPeople } = this.state;
    if (showAllPost) {
      return "All posts";
    }
    if (showAllPeople) {
      return "All people";
    }
    return "All jobs";
  }

  shouldDisplayPost() {
    const { showAllPost } = this.state;
    return this.showAllResults() || showAllPost;
  }

  shouldDisplayPeople () {
    const { showAllPeople } = this.state;
    return this.showAllResults() || showAllPeople;
  }

  shouldDisplayJob () {
    const { showAllJobs } = this.state;
    return this.showAllResults() || showAllJobs;
  }

  shouldDisplayCustomPost() {
    const { showAllPost, showAllPeople, showAllJobs } = this.state;
    return showAllPost && !showAllPeople && !showAllJobs;
  }

  noPostFound () {
    return this.state.getAllSearchResults && this.state.getAllSearchResults.posts && this.state.getAllSearchResults.posts.data.length === 0
  }

  noJobFound () {
    return this.state.getAllSearchResults && this.state.getAllSearchResults.jobs && this.state.getAllSearchResults.jobs.data.length === 0
  }

  noPeopleFound () {
    return this.state.getAllSearchResults && this.state.getAllSearchResults.accounts && this.state.getAllSearchResults.accounts.data.length === 0
  }

  showAllPostText () {
    return this.state.getAllSearchResults && this.state.getAllSearchResults.posts && this.state.getAllSearchResults.posts.data.length > 0 && this.state.showAllPost && this.state.showAllJobs && this.state.showAllPeople
  }

  toggleReadMore = (postId: string) => {
    this.setState((prevState) => {
      const { expandedPosts } = prevState;
      const isPostExpanded = expandedPosts.includes(postId);

      return {
        expandedPosts: isPostExpanded
          ? expandedPosts.filter(id => id !== postId)
          : [...expandedPosts, postId],
      };
    });
  };

  getStatusText = (item: AccountDetails) => {
    const { connection_request_details } = item.attributes;
    
    if (connection_request_details && connection_request_details.status) {
      return connection_request_details.status === "accepted" ? "Message" : connection_request_details.status;
    }
  
    return 'Connect';
  };

  getUserName = () => {
    return this.state.userDetails.full_name ? `${this.state.userDetails.full_name}` : ''
  }

  handleAdvanceSearchResult(responseJson: any) {
    this.setState({
      showSearchResults: true,
      getAllSearchResults: responseJson,
      loading: false
    })
    if(!responseJson.errors && !responseJson.error) {
      if (responseJson.accounts.data.length === 0 &&
        responseJson.jobs.data.length === 0 &&
        responseJson.posts.data.length === 0 &&
        this.state.searchText)
        {
          this.props.navigation.navigate('AdvancedSearchResults', {search_key: this.state.searchText})
        }
      }
  }

  goToLoginPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleVoteResponse = (response :any) => {
    if(!response.errors && !response.error) {
      this.getPostById()
    }
  }

  handleVoteResponseOne = (response :{
    errors?: string;
    error?: string;
    message: string;
    saved_post: {
      id: number;
      is_saved: boolean;
    };
  }) => {
    if(!response.errors && !response.error) {
      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          posts: {
            data:this.state.getAllSearchResults!.posts.data.map(post => +post.id === response.saved_post.id ? {...post, attributes: {...post.attributes, is_saved: response.saved_post.is_saved} } : post) ,
          },
        }});
    }
  }
  handleHidePostResponse = (response: any) => {
    if(!response.errors && !response.error) {
      this.getPostById()
      toast.success(response.message)
      
      const allPosts = [...this.state.getAllSearchResults!.posts.data]
      const updatedPosts = allPosts.filter((post) => post.id !== response.hidden_post.data.id) 

      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          posts: {
            data: updatedPosts
          }
        }
      })

      const searchKey = this.props.navigation.getParam("search_key")
      if(searchKey) {
        this.handleSearchData(searchKey);
      }
      } else if(response.error) {
      toast.error(response.error)
    }
  }

  handleInvitationResponse = (response: any, removeInvitation?: boolean) => {
    if (!response.errors && !response.error) {
      toast.success(response.message)
      const allPeople = [...this.state.getAllSearchResults!.accounts.data!]
      const updatedPeople = allPeople.map((people) =>
        parseInt(people.id) === parseInt(this.state.openInvitationDialog.receiverId as string) ? { ...people, attributes: { ...people.attributes, connection_request_details: handleConditionFunction(removeInvitation as boolean, null, { id: response.connection_request.data.id, status: "pending" }) } } : people
      )

      const allPosts = [...this.state.getAllSearchResults!.posts.data]
      const updatedPosts = allPosts.map((post) => {
        const postAccountId = post.attributes.account_id
        const responseAccountId = Number(response.connection_request.data.attributes.receiver_details.data.id)

        if (postAccountId === responseAccountId) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              following_account: response.connection_request.data.attributes.status,
              connection_request_details: handleConditionFunction(removeInvitation as boolean, null, { id: response.connection_request.data.id, status: response.connection_request.data.attributes.status })
            },
          }
        } else {
          return post
        }
      })


      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          accounts: {
            data: updatedPeople
          },
          posts: {
            data: updatedPosts
          }
        }
      })
      const searchKey = this.props.navigation.getParam("search_key")
      if (searchKey) {
        this.handleSearchData(searchKey);
      }
      if (this.state.currentReceiverId) {
        this.followUser(this.state.currentReceiverId)
      }
    } else if (response?.error) {
      toast.error(response.error)
    }
    this.setState({
      currentReceiverId: "",
      openInvitationDialog: {
        open: false,
        receiverId: "",
      },
    })
  }

  handleSaveJobResponse = (response: any, type: "is_disliked" | "is_saved") => {
    if(!response.errors && !response?.error) {
      toast.success(response?.message)
      const allJobs = [...this.state.getAllSearchResults?.jobs.data!];
      const updatedJobs = allJobs.map((jobDetail) =>
        parseInt(jobDetail.id) === parseInt(this.state.selectedJobId as string) ? {...jobDetail, attributes: { ...jobDetail.attributes, [type] : !jobDetail.attributes[type]}} : jobDetail
      );
      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          jobs: {
            data: updatedJobs,
          },
        },
      })

    } else {
      toast.error(response?.error)
    }
  }
  
  handleCreateComment = (response: any) => {
    if (!response.errors && !response.error) {
        this.getCommentsOfPost(this.state.postShowID);
    }
  }

  handleUpdateComments = (response: any) => {
    if(!response.errors && !response.error) {
      const allPosts = [...this.state.getAllSearchResults?.posts.data!];
      const updatedPosts = allPosts.map((post) =>
      parseInt(post.id) === parseInt(this.state.selectedPostId as string) ? {...post, attributes: { ...post.attributes, comment: response.data, comment_count: response.data.length}} : post
      );
      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          posts: {
            data: updatedPosts,
          },
        },
      })
    }
  }

  handleGetPostResponse = (response: any) => {
    if (!response.errors && !response.error) {
      const allPosts = [...this.state.getAllSearchResults?.posts.data!];
      const updatedPosts = allPosts.map((post) =>
        parseInt(post.id) === parseInt(this.state.selectedPostId as string) ? response.data : post
      );
      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          posts: {
            data: updatedPosts,
          },
        },
      })
    }
  }

  handleGetUserData = (response: any) => {
    if (!response.errors && !response.error) {
        this.setState({
            userDetails: response.data.attributes
        })
    }
  }

  handleFollowDeleteResponse = (response: any) => {
    if(!response.errors && !response.error) {
      const searchKey = this.props.navigation.getParam("search_key")
      if(searchKey) {
        this.handleSearchData(searchKey);
      }

      const allPosts = [...this.state.getAllSearchResults?.posts.data!];
      const updatedPosts = allPosts.map((post) => {

        const postAccountId = post.attributes.account_id 
        const responseAccountId = response.data.data.attributes.profile_details.data.attributes.account_details.id

        if(postAccountId === responseAccountId) {
          return {
            ...post,
            attributes: {
              ...post.attributes,
              following_account: response.data.data.attributes.is_followed
            }
          }
        } else {
          return post
        }
      })

      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          posts: {
            data: updatedPosts,
          },
        },
      }) 

      if (response.meta)
        return toast.success(response.meta.message)
    }
  }

  handleFollowResponse = (responseJson: {errors: [], error: string , message: string, data: FollowResponse }) => {

    if(!responseJson.errors && !responseJson.error) {
      const searchKey = this.props.navigation.getParam("search_key")
      if(searchKey) {
        this.handleSearchData(searchKey);
      } 

    const followData = responseJson?.data;

    if (followData && this.state.getAllSearchResults?.posts) {
      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults,
          posts: {
            data: this.state.getAllSearchResults.posts.data.map((postData) => {
              const postAccountId = Number(postData.attributes.account_id);
              const responseAccountId = Number(followData.attributes.profile_details.data.attributes.account_details.id);
    
              if (postAccountId === responseAccountId) {
                return {
                  ...postData,
                  attributes: { ...postData.attributes, following_account: followData.attributes.is_followed },
                };
              } else {
                return postData;
              }
            }),
          },
        },
      }) 
    }
    }
};

  handlePostMenu = (receiverId: number | string, menuType?: 'connect' | 'following' | 'hidePost' | 'reportPost', invitationId?: number | string | boolean) => {
    if(menuType) {
      if (menuType === 'connect') {
        if (invitationId && typeof invitationId !== 'boolean')
          return this.removeInvitation(invitationId)
        this.handleInvitationDialogOpen(receiverId)
      } else if (menuType === 'hidePost') {
        this.handleHidePostApi(receiverId)
      } else if(menuType === 'following') {
        invitationId ? this.deleteFollowUsers(receiverId)  : this.followUser(receiverId)
      } else if (menuType === "reportPost") {
        this.handleReportDialogue(receiverId)
    }
    }
  }

  handleInvitationDialogOpen = (receiverId: number | string) => {
    this.setState({
      openInvitationDialog: {
        open: true,
        receiverId: receiverId
      },
    })
  } 

  handleInvitationClose = () => {
    this.setState({
      openInvitationDialog: {
        ...this.state.openInvitationDialog,
        open: false,
      },
    })
  }

  goBack = () => {
    this.props.navigation.goBack()
  }

  scrollToTop = () => {
    const containerDiv = document.getElementById('pageContainer')
    if (containerDiv) {
        containerDiv.scrollIntoView();
    }
  }

  handleInvite = (noteValue: string) => {
    this.sendInvitation(noteValue, this.state.openInvitationDialog.receiverId)
  }

  handleRedirection(value:string) {
    this.scrollToTop()
    if(value === 'post') {
      this.setState({showAllJobs: false});
      this.setState({showAllPeople: false});
    } else if(value === 'people') {
      this.setState({showAllPost: false});
      this.setState({showAllJobs: false});
    } else if(value === 'jobs') {
      this.setState({showAllPost: false});
      this.setState({showAllPeople: false});
    }
  }

  handleRerender() {
    this.setState({showAllPost: true, showAllJobs: true, showAllPeople: true});
  }
  
  handleSearch(event : any) {
    const value = (event.target as HTMLInputElement).value

    if(!value) {
      this.setState({searchText : ""});
    }

    if(event.key === 'Enter' && value) {
      this.setState({getAllSearchResults: null, searchText : value, anchorPopoverEl: event.currentTarget});
      this.getCurrentLocation().finally(() => {
        this.handleSearchData(value);
      })
    }
  }

  handleUnlike (jobId: number, isDislike: boolean) {
    if(isDislike) {
      this.handleJobUnlikeRemove(jobId)
    } else {
      this.handleJobUnlike(jobId)
    }
  }

  handleSave (jobId: number, isSaved: boolean) {
    if(isSaved) {
      this.unSaveJobsHandler(jobId)
    } else {
      this.saveJobsHandler(jobId)
    }
  }

  handleInvitationDialogCondition = (item: AccountDetails) => {
    const { connection_request_details } = item.attributes;
    
    if (connection_request_details && connection_request_details.status === "accepted") {
      this.navigateToMessage()
    }
    else { 
      return !connection_request_details ? this.handleInvitationDialogOpen(item.id) : this.removeInvitation(connection_request_details.id!, item.id)
    }
  }

  handleReasonAPIResp = (apiResponse: {flag_reasons?: UserReasons[]}) => {
    if (apiResponse.flag_reasons) {
      this.setState({ userReasons: apiResponse.flag_reasons })
    }
  }

  handleGetReasonResponse = (apiResponse: {flag_reasons?: UserReasons[]}) => {
    if (apiResponse.flag_reasons) {
      this.setState({ userReasons: apiResponse.flag_reasons })
    }
  }

  handleSelect = (reason: {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string,
    otherReason: string
  }) => {
    this.setState({ selectedReason: reason })
  }

  handleReportDialogue = (postId?: number | string ) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    if (!this.state.reportClick) {
      this.setState({
        selectedPostId: postId || ""
      })
      this.getReasonsList()
    }
    this.setState({
      reportClick: !this.state.reportClick,
      selectedReason: {
        id: 0,
        reason: "",
        created_at: "",
        updated_at: "",
        otherReason: ""
      },
    })
  }

  handleAddReasonAPIResp = (apiResponse: { message?: string } | { error?: string }) => {
    if ('error' in apiResponse) {
      toast.error(apiResponse.error)
    } else if ('message' in apiResponse && this.state.getAllSearchResults) {
      const allPosts = [...this.state.getAllSearchResults.posts.data]
      const updatedPosts = allPosts.filter((post) => Number(post.id) !== this.state.selectedPostId)

      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults,
          posts: {
            data: updatedPosts
          }
        },
        selectedPostId: 0,
        reportedSuccessfully: true,
        reportClick: false,
        selectedReason: {
          id: 0,
          reason: "",
          created_at: "",
          updated_at: "",
          otherReason: ""
        }
      })
    }
  }

  closeSuccessDialogue = () => {
    this.setState({
      reportedSuccessfully: false,
    })
  }

  addReasons1 = async () => {
    const token = await getStorageData("authToken")
    const headers = {
      token,
    };

    const formData = new FormData();
    formData.append("post_id", `${this.state.selectedPostId}`);
    this.state.selectedReason.otherReason ?
      formData.append("other_reason", this.state.selectedReason.otherReason) :
      formData.append("reason_id", `${this.state.selectedReason.id}`)
    
    const addReportsDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addReasonsApiCallId = addReportsDataMessage.messageId;
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addReasonsApiEndPoint
    );
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    addReportsDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(addReportsDataMessage.id, addReportsDataMessage);
  };

  getReasonsList = async () => {
    const token = await getStorageData("authToken")

    const headers = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token,
    };
    const reasonsData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reasonsData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.reasonApiCallId = reasonsData.messageId;
    reasonsData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resonEndPointApi}`
    );
    reasonsData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(reasonsData.id, reasonsData);
  }

  getDiscloseText = (item: JobDetails) => {
    return `$ ${item.attributes.disclose ? "Not disclosed" : item.attributes.salary_to}`
  }

  async getUserProfile() {
    const authToken = await getStorageData("authToken")
    const requestMessage = new Message(

        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateUserDetailsEndPoint
    );

    const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: authToken
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  followUser = async (accountId: number | string) => {
      if (!isLoggedIn()) {
        this.goToLoginPage();
        return;
      }
      const authToken = await getStorageData("authToken")
  
      const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
          token: authToken,
      };
  
      let requestBody = {
        "data": {
          "type": "follow",
          "attributes": {
            "account_id": accountId
          }
        }
      }
  
      const followUserMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.followUserPostApiCallId = followUserMessage.messageId;
  
      followUserMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
  
      followUserMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(requestBody)
      );
  
      followUserMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.followUserApiEndPoint}?token=${authToken}`
      );
  
      followUserMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPostMethod
      );
  
      runEngine.sendMessage(followUserMessage.id, followUserMessage);
  }

  deleteFollowUsers = async (accountId: number | string) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    const authToken = await getStorageData("authToken")

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
        token: authToken,
    };

    const followUserMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.followDeleteUserPostApiCallId = followUserMessage.messageId;

    followUserMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    followUserMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.followUserApiEndPoint}/${accountId}?token=${authToken}`
    );

    followUserMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(followUserMessage.id, followUserMessage);
  }

  sendInvitation = async (noteValue: string, receiverId: string | number) => {    
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    const authToken = await getStorageData("authToken")

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
        token: authToken,
    };

    this.setState({
      currentReceiverId : receiverId
    })

    let invitationBody = {
      "connection_request": {
        "receiver_id": receiverId,
        "note": noteValue
      }
    }

    const sendInvitationMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendInvitationPostApiCallId = sendInvitationMessage.messageId;

    sendInvitationMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    sendInvitationMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(invitationBody)
    );

    sendInvitationMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendInvitationEndpoint
    );

    sendInvitationMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
    );

    runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
}

  handleSearchData = async(value: string) => {
    let coordsUrl = ''
    if(this.state.coords.latitude && this.state.coords.longitude ) {
      coordsUrl += `&latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`
    }
    this.setState({loading: true})
    const authToken = await getStorageData("authToken")

    if (value.length === 0) {
      return;
    }
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: authToken ?? undefined
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.advancedsearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?search_key=${value}${coordsUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  handleNavigateToSearchResult = () => {
    this.props.navigation.navigate('AdvancedSearchResults', {search_key: this.state.searchText})
  }

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getPostById() {
    const authToken = await getStorageData("authToken")

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getPostEndPoint}/${this.state.selectedPostId}`
    );

    const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: authToken
    };

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  upvotePost = async (postId: number | string, type: string, activityType: string) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    this.setState({
      selectedPostId: postId 
    })
    
    const authToken = await getStorageData("authToken")

    const header = {
        token: authToken,
    };


    let formData = new FormData();
    formData.append("likeable_id", `${postId}`);
    formData.append("likeable_type", type);

    const upvotePostMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upVotePostApiCallId = upvotePostMessage.messageId;

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.upvoteEndpoint
    );

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
    );

    runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
}

toggleComments = (post: any) => {
  this.getCommentsOfPost(post.id);
  this.setState({ showComment: !this.state.showComment, postShowID: post.id !== this.state.postShowID ? post.id : "", selectedPostId: post.id });
};

createComment = async (comment: string = "", files: File[] = [], nested: boolean = false, commentId: number | string = "", postID: number | string = "") => {
  const authToken = await getStorageData("authToken")
  const startTime = Date.now();
  if (files.length > 0) { this.setState({ loaderComment: true })}

  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  this.setState({
    selectedPostId: postID
  })

  let commentData = new FormData();

  commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");
  commentData.append("comment[commentable_id]", `${commentId}`);
  commentData.append("comment[post_id]", `${postID}`);
  commentData.append("comment[comment]", `${comment}`);
  files.forEach((file) => { commentData.append(`comment[comment_images][]`, file) });

  const config: AxiosRequestConfig = {
    headers: { token: authToken ?? '' },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => 
    {
      
      const { loaded, total } = progressEvent;

      if (total) {
        const percentCompleted = Math.round((loaded * 100) / total);
        const currentTime = Date.now();
        const elapsedTime = (currentTime - startTime) / 1000;

        const uploadSpeed = loaded / elapsedTime;
        const remainingTime = (total - loaded) / uploadSpeed;

        this.setState({ 
          progressComment: percentCompleted, 
          remainingTimeComment: remainingTime 
        })
      }
    }
  };

  axios.post(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}`, commentData, config)
    .then(response => { 
      this.setState({
        getAllSearchResults: {
          ...this.state.getAllSearchResults!,
          posts: {
            data: this.state.getAllSearchResults!.posts.data.map((post: PostDetails) => {
              return post.attributes.id == this.state.postShowID ? { ...post, attributes: { ...post.attributes, comment_count: post.attributes.comment_count + 1 }, } : post
            }),
          },
        },
      })
      this.getCommentsOfPost(this.state.postShowID) 
    })
    .catch(error => { })
    .finally(() => {
      this.setState({
        loaderComment: false, 
        remainingTimeComment: 0,
        progressComment: 0, 
      })

    });

}


getCommentsOfPost = async (commentId: number | string) => {
  const authToken = await getStorageData("authToken")

  const header = {
      'Content-Type': configJSON.ApiContentType,
      token: authToken
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllCommentsForApiId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentsEndPoint}?commentable_id=${commentId}&commentable_type=BxBlockPosts::Post`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

downvotePost = async (postId: number | string, type: string, activityType: string) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    this.setState({
      selectedPostId: postId 
    })

    const authToken = await getStorageData("authToken")

    const header = {
        token: authToken,
    };

    let formData = new FormData();
    formData.append("dislikeable_id", `${postId}`);
    formData.append("dislikeable_type", type);

    const downvotePostMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downVotePostApiCallId = downvotePostMessage.messageId;

    downvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );

    downvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    downvotePostMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.downvoteEndpoint
    );

    downvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
    );

    runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
}

upvotePostComment = async (commentId: number | string, type: string, postId?: string | number) => {
    if (!isLoggedIn()) {
      this.goToLoginPage();
      return;
    }
    if(postId) {
      this.setState({
        selectedPostId: postId 
      })
    }

    const authToken = await getStorageData("authToken")

    const header = {
        token: authToken,
    };


    let formData = new FormData();
    formData.append("likeable_id", `${commentId}`);
    formData.append("likeable_type", type);

    const upvotePostMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upVotePostCommentApiCallId = upvotePostMessage.messageId;

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.upvoteEndpoint
    );

    upvotePostMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
    );

    runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
}


downvotePostComment = async (commentId: number | string, type: string,  postId?: string | number) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  if (postId) {
    this.setState({
      selectedPostId: postId
    })
  }
  const authToken = await getStorageData("authToken")

  const header = {
    token: authToken,
  };

  let formData = new FormData();
  formData.append("dislikeable_id", `${commentId}`);
  formData.append("dislikeable_type", type);

  const downvotePostMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.downVotePostCommentApiCallId = downvotePostMessage.messageId;

  downvotePostMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  downvotePostMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  downvotePostMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.downvoteEndpoint
  );

  downvotePostMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );

  runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
}

handleHidePostApi = async (postId: number | string) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  this.setState({
    selectedPostId: postId 
  })

  const authToken = await getStorageData("authToken")

  const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.hidePostApiId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savePostEndpoint}/${postId}/hide_post`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleSavePostApi = async (postId: number | string, isSaved?: boolean) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  this.setState({
    selectedPostId: postId 
  })

  const authToken = await getStorageData("authToken")

  const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": authToken
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.savePostApiId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isSaved ? `${configJSON.savePostEndpoint}/${postId}/unsave_post` : `${configJSON.savePostEndpoint}/${postId}/save_post`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isSaved ? configJSON.httpDeleteMethod : configJSON.httpPostMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

unSaveJobsHandler = async (jobId: number) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  this.setState({
    selectedJobId: jobId
  })
  const authToken = await getStorageData("authToken")

  const headers = {
    "Content-Type": configJSON.advancedsearchApiContentType,
    "token": authToken
  };

  const saveJobDetails = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.unsaveJobApiCallId = saveJobDetails.messageId;

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.savedJobApiEndPoint}/${jobId}/unsave_job`
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpDeleteMethod
  );
  runEngine.sendMessage(saveJobDetails.id, saveJobDetails);
}

saveJobsHandler = async (jobId: number) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  this.setState({
    selectedJobId: jobId
  })

  const authToken = await getStorageData("authToken")

  const headers = {
    "Content-Type": configJSON.advancedsearchApiContentType,
    "token": authToken
  };

  const saveJobDetails = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.saveJobApiCallId = saveJobDetails.messageId;

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.savedJobApiEndPoint}/${jobId}/save_job`
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );
  runEngine.sendMessage(saveJobDetails.id, saveJobDetails);
}

handleJobUnlike = async (jobId: number) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  this.setState({
    selectedJobId: jobId
  })

  const body = {
    "dislikeable_id":jobId,
    "dislikeable_type":"BxBlockJoblisting::Job"
  }

  const authToken = await getStorageData("authToken")

  const headers = {
    "Content-Type": configJSON.advancedsearchApiContentType,
    "token": authToken
  };

  const saveJobDetails = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.jobUnlikeApiCallId = saveJobDetails.messageId;

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.savedJobApiEndPoint}/${jobId}/dislike_job`
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );
  runEngine.sendMessage(saveJobDetails.id, saveJobDetails);
}  

handleJobUnlikeRemove = async (jobId: number) => {
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  this.setState({
    selectedJobId: jobId
  })

  const body = {
    "dislikeable_id":jobId,
    "dislikeable_type":"BxBlockJoblisting::Job"
  }

  const authToken = await getStorageData("authToken")

  const headers = {
    "Content-Type": configJSON.advancedsearchApiContentType,
    "token": authToken
  };

  const saveJobDetails = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.jobUnlikeApiCallId = saveJobDetails.messageId;

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.savedJobApiEndPoint}/${jobId}/remove_dislike_job`
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  saveJobDetails.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpDeleteMethod
  );
  runEngine.sendMessage(saveJobDetails.id, saveJobDetails);
}  

removeInvitation = async (connectionId: string | number, userId?: string | number) => {    
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  if(userId) {
    this.setState({
      openInvitationDialog: {
        open: false,
        receiverId: userId
      }
    })
  }
  const authToken = await getStorageData("authToken")

  const header = {
    "Content-Type": configJSON.advancedsearchApiContentType,
      token: authToken,
  };

  const sendInvitationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.removeInvitationPostApiCallId = sendInvitationMessage.messageId;

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeInvitationEndpoint}/${connectionId}/withdraw_connection_request`
  );

  sendInvitationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
  );

  runEngine.sendMessage(sendInvitationMessage.id, sendInvitationMessage);
}

updateComment = async(comment: string = "", files: (File | { url: string })[] = [], nested: boolean = false, commentId: number | string = "", postID: number | string = "") => {
  if (files.length > 0) {
    this.setState({ loaderComment: true })
  }
  
  if (!isLoggedIn()) {
    this.goToLoginPage();
    return;
  }
  
  const startTime = Date.now();
  const authToken = await getStorageData("authToken")

  let commentData = new FormData();

  commentData.append("comment[comment]", `${comment}`);
  commentData.append("comment[commentable_type]", !nested ? "BxBlockPosts::Post" : "BxBlockComments::Comment");

  files.forEach((file: File | { url: string }, _index: number) => { 
    commentData.append(`comment[comment_images][]`, 
    (file as { url: string })?.url ? (file as { url: string }).url : file as File);
  });

  const config: AxiosRequestConfig = {
    headers: { token: authToken ?? '' },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const { loaded, total } = progressEvent;
      if (total) {
        const currentTime = Date.now();
        const percentCompleted = Math.round((loaded * 100) / total)
        const elapsedTime = (currentTime - startTime) / 1000
        const uploadSpeed = loaded / elapsedTime

        const remainingTime = (total - loaded) / uploadSpeed;
        this.setState({
          progressComment: percentCompleted, 
          remainingTimeComment: remainingTime,
        })
      }
    }
  };

  axios.patch(`${configJSON.baseURL.baseURL}/${configJSON.getCommentsEndPoint}/${commentId}`, commentData, config)
    .then(response => {
      this.getCommentsOfPost(this.state.postShowID);
    })
    .catch(() => {
    })
    .finally(() => {
      this.setState({
        loaderComment: false, 
        remainingTimeComment: 0,
        progressComment: 0, 
      })
    });
}

handleDeleteComment = async(commentId: number | string) => {
  const authToken = await getStorageData("authToken");
  const headers = { "token": authToken };

  const downvotePostMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.deleteCommentApiId = downvotePostMessage.messageId;

  downvotePostMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

  downvotePostMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getCommentsEndPoint}/${commentId}`);

  downvotePostMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpDeleteMethod );

  runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
}
  // Customizable Area End
}
