import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import Filteroptions from "../../filteritems/src/Filteroptions.web";
import { debounce } from 'lodash';
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { buySideIcon, careerDevelopmentIcon, sellSideIcon } from "./assets";
import { toast } from "react-toastify";

export interface Root {
  recruiter_job_listing: RecruiterJobListing
  total_page: number
  current_page: number
}

export interface RecruiterJobListing {
  data: Daum[]
}

export interface Daum {
  id: string
  type: string
  attributes: Attributes
}

export interface Attributes {
  job_title: string
  company_name: string
  city: string
  country: string
  disclose: boolean
  status: string
  created_at: string
}

export interface Categorieslist {
  id: number | string,
  type: "string",
  attributes: {
    id: number | string,
    name: string,
    icon: string | null,
    menuItems: {
      id: string;
      type: string;
      attributes: {
        id: number;
        name: string;
      };
    }[]
  },
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  openModal?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loaderImage:boolean;
  progressImage:number;
  remainingTimeImage:number;
  currentPage: number;
  isInfiniteLoading: boolean;
  selectedTopic: string;
  postContent: string;
  open: boolean;
  selectTopicsList: { value: string, name: string }[];
  loading: boolean;
  openPopover: boolean;
  images: any[];
  imageList: any[];
  postDescription: string;
  createdPostId: string,
  jobFilterData: Daum[]
  totalPagesJobFilter: number
  currentPageJobFilter: number
  userProfileName: string,
  isPostCreated: boolean,
  selectedCountry: string
  selectedCity: string
  selectedDate: string
  selectedSkill: string[]
  selectedPostedBy: string
  selectedExperiance: string[]
  selectedExperianceCheck: string[]
  disableButton: boolean
  openFilter: boolean
  deletedPostSuccess: boolean,
  isLoggedInUser: any,
  isPostImageUploaded:boolean,
  userRole: string,
  categoriesList: {
    [category: string]: {
      icon: string,
      topics: string[]
    };
  },
  selectedSubCategory: string | number,
  openedCategory: string | number;
  selectedSubCatTitle: string;
  profileImage: string;
  postType: "home" | "popular" | "following" | "saved";
  coords : {
    latitude: number,
    longitude: number,
  },
  filterHeight: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loaderImage: false,
      progressImage:0,
      remainingTimeImage:0,
      currentPage: 1,
      isInfiniteLoading: false,
      openPopover: false,
      selectedTopic: '',
      postContent: '',
      open: false,
      selectTopicsList: [],
      loading: false,
      images: [],
      imageList: [],
      postDescription: '',
      createdPostId: '',
      jobFilterData: [],
      currentPageJobFilter: 0,
      totalPagesJobFilter: 0,
      userProfileName: '',
      isPostCreated: false,
      deletedPostSuccess: false,
      selectedCountry: '',
      selectedDate: '',
      selectedExperiance: [],
      selectedExperianceCheck: [],
      selectedPostedBy: '',
      selectedSkill: [],
      selectedCity: '',
      disableButton: true,
      openFilter: false,
      isPostImageUploaded: false,
      isLoggedInUser: !!localStorage.getItem("authToken"),
      userRole: "",
      categoriesList: this.topicsList ,
      selectedSubCategory: "",
      openedCategory: "",
      selectedSubCatTitle: "",
      profileImage: "",
      postType: "home",
      coords : {
        latitude: 0,
        longitude: 0,
      },
      filterHeight: 380
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.handleTokenError(responseJson, webApiRequestCallId)
      if (responseJson) {
        if (webApiRequestCallId === this.selectTopicsId) {
          const selectTopics = await responseJson?.sub_categories?.map((item: any) => ({
            value: item.id || '',
            name: item.name || '',
          }));
          this.setState({ selectTopicsList: selectTopics })
        } else if(webApiRequestCallId === this.getCreatedPostId) {
          window.dispatchEvent(new CustomEvent("CREATED_POST", { detail: responseJson.data }))
        } else if (webApiRequestCallId === this.jobListApiCallId) {
          this.setjobListData(responseJson)
          if(responseJson?.data){
            this.setState({isPostCreated: true})
            window.dispatchEvent(new CustomEvent("CREATED_POST", { detail: responseJson?.data }))
          }
        } else if(webApiRequestCallId === this.getUserProfileApiId) {
          this.handleUserProfileData(responseJson.data)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAllPostsApiId: string | Message = "";
  AllPostsApiId: string | Message = "";
  createFeedPostApiId: string | Message = "";
  selectTopicsId: string | Message = "";
  getCreatedPostId: string | Message = "";
  jobListApiCallId: string = '';
  childRef: React.RefObject<Filteroptions> = React.createRef()    
  storeGroupContextApiId: string | Message = "";
  getUserProfileApiId: string | Message = "";
  getCategoriesListApiID: string | Message = "";
  getSubCategoriesListApiID: string | Message = "";

  topicsList = {
    "Buy-Side": { icon: buySideIcon, topics: ["Private Equity", "Growth Equity", "Venture Capital", "Infrastructure Investment", "Real Estate Investment", "Investor Relations and Capital Raising", "Corporate Development"] },
    "Sell-Side": { icon: sellSideIcon, topics: ["Investment Banking", "Equity Research", "Sales and Trading", "Market Analysis"] },
    "Career Development": { icon: careerDevelopmentIcon, topics: ["Breaking into Buy-side", "Breaking into Sell Side", "Career Advancement", "Work- Life Balance", "Networking and Mentorship"] }
  } 

  handleSelect = (event: CustomEvent<any>) => {
    this.storeGroupContextApi(event.detail)
    this.setState({userProfileName: event.detail || ''})
  }

  editPostHandler = (event: CustomEvent<any>) => {
    this.setState({open: event.detail})
    document.body.style.overflow = 'auto'
  }

  deletePostHandler = (event: CustomEvent<any>) => {
    this.setState({deletedPostSuccess: event.detail})
  }

  onSelectHandler = () => {
    alert('66666')
    this.props.navigation.navigate("/EmailAccountLoginBlock", '')
  }

  handleUserProfileData = (responseData : {attributes: {photo: string, selected_group_context: string}}) => {
    if(responseData){
      this.setState({
        profileImage: responseData.attributes.photo,
        userProfileName: responseData.attributes.selected_group_context
      })

      window.dispatchEvent(new CustomEvent("USER_PROFILE_DETAILS", { detail: responseData }))
      window.dispatchEvent(new CustomEvent("SET_USER_PROFILE_NAME", { detail: responseData.attributes?.selected_group_context }))
      window.dispatchEvent(new CustomEvent("GET_USER_DETAILS", { detail: responseData }))
    }
  }

  handleTokenError = (responseJson : {errors: {token: string}[]}, webApiRequestCallId : string) => {
    if(responseJson.errors && webApiRequestCallId === this.getUserProfileApiId) {
      toast.error(responseJson.errors[0].token, { toastId: 'apiError'})
    }
  }

  navigateToJob = (jobId: number) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), `Catalogue`);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      
      const payloadMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage))
      payloadMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), { query: jobId })
      message.addData(getName(MessageEnum.NavigationRaiseMessage), payloadMessage);
      this.send(message);
  }

  handleLogOut = (event: CustomEvent<any>) => {
    this.setState({ isLoggedInUser: event.detail })
    setTimeout(() => { window.location.reload() }, 100)
  }
  
  isloggedIn = () => {
    return !!localStorage.getItem("authToken")
  }
  
  async componentDidMount(): Promise<void> {
    this.setState({
      userRole: await getStorageData("userRole")
    })
    this.getUserProfile()
    window.addEventListener("USER_PROFILE_NAME", this.handleSelect as EventListener);
    window.addEventListener("EDIT_POST", this.editPostHandler as EventListener);
    window.addEventListener("DELETE_POST", this.deletePostHandler as EventListener);
    window.addEventListener("LOGOUT", this.handleLogOut as EventListener);
    window.addEventListener("RENDER_MY_FEED", this.handleMyFeed as EventListener);
    window.addEventListener("JOB_LIST", this.getCurrentLocation as EventListener);
  }

  handleMyFeed = () => {
    this.setState({ 
      selectedSubCategory: "", 
      selectedSubCatTitle: "", 
      postType: "home",
    })
  }

  goToHome() {
    const msg: Message = new Message(getName(MessageEnum.NavigationHomeScreenMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleNavigationToJobs () {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Catalogue');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  constHandleShowComment = (callback: () => void) => {
    callback()
  }

  handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      this.setState({isPostImageUploaded:true});
      const files: any = [...this.state.images, ...Array.from(e.target.files)];
      const imageList: any = [...this.state.imageList, ...Array.from(e.target.files)];
      this.setState({ images: files, imageList: imageList });
    }
  };

  handleStateUpdate = () => {
    this.setState({isPostImageUploaded:false});
  }

  handleDeleteImage = (index: number) => {
    const updatedImages: any = this.state.images.filter((_, i) => i !== index);
    this.setState({ images: updatedImages });
  };

  setjobListData = (responseJson: Root) => {
    if (responseJson.recruiter_job_listing && responseJson.recruiter_job_listing.data) {
      this.setState({ jobFilterData: responseJson.recruiter_job_listing.data })
    }
    this.setState({ currentPageJobFilter: responseJson.current_page, totalPagesJobFilter: responseJson.total_page })
  }

  handlePostList = (postType: "home" | "popular" | "following" | "saved") => {
    this.setState({ 
      postType,
      selectedSubCategory: "",
      selectedSubCatTitle: "",
     });
  }

  renderHeading = () =>{
    if(this.state.postType === "saved"){
      return `Showing all the Saved Articles`
    } else if(this.state.selectedSubCategory) {
      return `Showing all posts related to ${this.state.selectedSubCatTitle}`
    } else {
      return "IN CASE YOU MISSED IT"
    }
  }

  createFeedPostApi = async () => {
    this.setState({loading: true});
    const authToken = localStorage.getItem("authToken");
    const startTime = Date.now();
    let URL = ''

    if(this.state.coords.latitude && this.state.coords.longitude) {
      URL = `?latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`;;
    }

    if(this.state.images.length > 0){
      this.setState({ loaderImage: true })
    }
    const formData1 = new FormData();
    formData1.append("data[attributes][name]", '');
    formData1.append("data[attributes][description]", "");
    formData1.append("data[attributes][body]", this.state.postDescription);
    formData1.append("data[attributes][sub_topic]", this.state.selectedTopic);
    formData1.append("data[attributes][location]", "");
    formData1.append("data[attributes][name]", "");
    this.state.images.forEach((file: File) => {
      formData1.append(`data[attributes][images][]`, file);
    });

    const config1: AxiosRequestConfig = {
      headers: { token: authToken ?? '' },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const { loaded, total } = progressEvent;
        if (total) {
          const percentCompleted1 = Math.round((loaded * 100) / total);

          const currentTime1 = Date.now();
          const elapsedTime = (currentTime1 - startTime) / 1000;

          const uploadSpeed1 = loaded / elapsedTime;

          const remainingTime1= (total - loaded) / uploadSpeed1;

          this.setState({ progressImage: percentCompleted1, remainingTimeImage: remainingTime1 })
        }
      }
    };

    if (this.state.createdPostId) {
      axios.put(`${configJSON.baseURL.baseURL}/${configJSON.createPosts}/${this.state.createdPostId}${URL}`, formData1, config1)
        .then(response => {
          if (response.data?.data) {
            window.dispatchEvent(new CustomEvent("DISPLAY_ALL_POST", { detail: response.data.data }))
            this.setState({createdPostId: response.data.data.id})
            this.viewCreatedPostApi()
          }
        })
        .catch(() => {})
        .finally(() => {
          this.setState({ loading: false, open: false, loaderImage: false })
        });
    } else {
      axios.post(`${configJSON.baseURL.baseURL}/${configJSON.createPosts}${URL}`, formData1, config1)
        .then(response => {
          this.setState({ loading: false, open: false, loaderImage: false })
          if (response.data?.data) {
            window.dispatchEvent(new CustomEvent("DISPLAY_ALL_POST", { detail: response.data.data }))
            this.setState({ openPopover: true, createdPostId: response.data?.data?.id })
            setTimeout(() => {
              this.setState({ openPopover: false });
            }, 3000);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.setState({ loading: false, open: false, loaderImage: false })
        });
;
    }    
  }

  storeGroupContextApi = (groupContext: string) => {
    const bodyData = {
        profile: {
          "group_context": groupContext
        }
      }

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const storeGroupContext = new Message(getName(MessageEnum.RestAPIRequestMessage));

    storeGroupContext.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
    this.storeGroupContextApiId = storeGroupContext.messageId;
    storeGroupContext.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.selectedGroupContextApi}?token=${localStorage.getItem("authToken")}`);
    storeGroupContext.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    storeGroupContext.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPatchMethod);

    runEngine.sendMessage(storeGroupContext.id, storeGroupContext);
  }

  getUserProfile = () => {
    if(!this.state.isLoggedInUser) {
      return;
    }
   
    const headers = {
      "token": localStorage.getItem("authToken"),
    };

    const getUserProfile = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getUserProfileApiId = getUserProfile.messageId;
    getUserProfile.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getProfile}`);
    getUserProfile.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    getUserProfile.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);

    runEngine.sendMessage(getUserProfile.id, getUserProfile);
  }

  selectTopicsHandler = () => {
    let token = localStorage.getItem("authToken");

    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const getTopics = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.selectTopicsId = getTopics.messageId;
    getTopics.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.selectTopicsApi);
    getTopics.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getTopics.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);

    runEngine.sendMessage(getTopics.id, getTopics);
  }

  viewCreatedPostApi = () => {
    let token = localStorage.getItem("authToken");

    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const createdPost = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getCreatedPostId = createdPost.messageId;
    createdPost.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getCreatedPost}/${this.state.createdPostId}`);
    createdPost.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    createdPost.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);

    runEngine.sendMessage(createdPost.id, createdPost);
  }

  selectTopicsValue = (e: any) => {
      const { value } = e.target
      this.setState({ selectedTopic: value })
  }

  openModal = async () => {
    let isLoggedIn = await getStorageData("authToken");
    if(!isLoggedIn) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
      this.setState({ open: true })
      this.setState({ selectedTopic: '', postDescription: '', images: [], createdPostId: '' }) 
  }

  closeModal = () => {
    this.setState({ open: false, selectedTopic: '', postDescription: '', images: [] })
  }

  postContentHandler = (event: {target: {value: string}}) => {
    const { value } = event.target;
    if (value.length <= configJSON.postDescriptionMaxLimit)
    this.setState({ postDescription: value })
  }

  viewHandler = () => {
    this.viewCreatedPostApi()
    this.setState({openPopover: false})
  }

  setJobFieldData = (val: any) => {
    if (val.total_page === 1 || val.total_page === 0) {
      this.setState({ totalPagesJobFilter: val.total_page })
    }
    this.setState({ 
      jobFilterData: val.recruiter_job_listing.data,
      currentPage: val.current_page
    })
  }

  setCountryvalue = (val: string) => {
    this.setState({ selectedCountry: val })
  }

  setCityValue = (val: string) => {
    this.setState({ selectedCity: val })
  }

  setDatevalue = (val: string) => {
    this.setState({ selectedDate: val })
  }

  setPostedByValue = (val: string) => {
    this.setState({ selectedPostedBy: val })
  }

  setSkillValue = (val: string[]) => {
    this.setState({ selectedSkill: val })
  }

  setExperienceValue = (val: string[]) => {
    this.setState({ selectedExperiance: val })
  }

  setButtonState = (val: boolean) => {
    this.setState({ disableButton: val })
  }

  setOpenFilter = (val:boolean) =>{    
    this.setState({ 
      openFilter: val,
      filterHeight: val ? 1150 : 380
    })
  }

  setExperienceCheckValue = (val: string[]) => {
    this.setState({ selectedExperianceCheck: val })
  }

  passData = () => {
    if (this.childRef.current) {
      this.childRef.current.getPropsDataFromLandingPage(this.state.selectedCountry, this.state.selectedCity, this.state.selectedDate, this.state.selectedExperiance, this.state.selectedPostedBy, this.state.selectedSkill, this.state.disableButton, this.state.selectedExperianceCheck)
    }
  }

  handleToggle = () => {
    if (this.childRef.current) {
      this.childRef.current.handleToggle(!this.state.openFilter);
      this.setState({openFilter: !this.state.openFilter})
    }
    this.passData()
  };

  getCurrentLocation = async () => {
    let coords = await getStorageData("coords")
    if(coords) {
      coords = JSON.parse(coords)
      this.setState({
        coords: {
          latitude: coords.latitude,
          longitude: coords.longitude
        },
      });
    } 
    this.jobListData()
  };

  jobListData = () => {    
    let coordsUrl = ''
    if(this.state.coords.latitude && this.state.coords.longitude ) {
      coordsUrl += `?latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobListApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.aplyFiltersAPiEndPoint}${coordsUrl}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  setOpenedCategory = (value: string) => {
    this.setState({
      selectedSubCategory: value,
      selectedSubCatTitle: value,
      postType: this.state.postType === "saved" ? "home" : this.state.postType,
    })
  }

  ScrollDown = () => {
    this.setState({ currentPageJobFilter: this.state.currentPageJobFilter + 1 }, () => {
      if (this.childRef.current) {
        this.childRef.current.applyfilters(this.state.currentPageJobFilter);
      }
     })
  }

  searchFilterData = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (this.childRef.current) {
        this.childRef.current.applyfilters(this.state.currentPageJobFilter, event.target.value);
      }
  }, 1000);
  // Customizable Area End
}
