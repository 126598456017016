import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Box, Grid, Typography } from "@material-ui/core";
import FormLabel from '@material-ui/core/FormLabel';
//@ts-ignore
import fileICon from "./fileicon.svg";
//@ts-ignore
import downloadIcon from "./downloadicon.svg";

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "white",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto #64748B",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,#1F497D,#1F497D)",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#64748B",
        },

    },
    selectEmpty: {
        border: "1px solid #EEECE1",
        borderRadius: "8px",
        margin: "0px !important",
        padding: "2px 5px",
        background: "white",
        "& .MuiInput-input": {
            background: "white",
        },
        "& .MuiInput-input:focus": {
            backgroundColor: "white",
        },
        "& .MuiInput-underline": {
            display: "none"
        },
        "& .MuiInput-formControl": {
            marginTop: "2px"
        }
    },
    filename: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
    },
    filesize: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
    },
    container: {
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "padding": "6px 0px 6px 6px",
        "borderRadius": "8px",
        "border": "1px solid rgba(0, 0, 0, 0.23)",
        "marginTop": "5px",
        "marginBottom": "7px"
    },
    formCont: {
        marginRight: "0px !important",
        "& .MuiFormControlLabel-root": {
            marginRight: "0px !important"
        }
    }
});


interface StyledRadioProps extends RadioProps {
    index: number;
    resumeList: { url: string | File, name: string, size: number, selectId: string | number }[];
    onChange:()=>void;
}

function
    StyledRadio(props: StyledRadioProps) {
    const classes = useStyles();
    const { checked, onChange } = props
    return (
        <>
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                checked={checked}
                {...props}
            />
        </>
    );
}

type Props = {
    value: any,
    index: number,
    resumeList: { url: string | File, name: string, size: number, selectId: string | number }[],
    isPreview?: boolean,
    selectedId? : number | string,
    handleInputChange?: (value: { url: string | File, name: string, size: number, selectId: string | number }) => void
    handleRemoveFile?: (value: string | number) => void;
}

export default function RadioSelectComponent(props: Props) {
    const [checked, setChecked] = useState(false)
    const { value, index, resumeList, isPreview = false, selectedId="", handleInputChange, handleRemoveFile= () => {} } = props
    const classes = useStyles();

    const formatBytesToKB = () => {
        return (value?.size / 1024).toFixed(2); // Convert bytes to KB and round to 2 decimal places
    };

    const downloadFile = (data: any, fileName: string, urlLink: string | File) => {
        // creates a url that points to the file in the browsers memory
        const url = typeof urlLink !== typeof "" ? URL.createObjectURL(data.url) : "";

        // using an 'a' tag to trigger a download
        // browsers don't have a nice api for this
        const anchor = document.createElement("a");
        anchor.setAttribute("href", typeof urlLink === typeof "" ? urlLink as string : url);
        anchor.setAttribute("download", fileName);
        anchor.setAttribute("target", "_blank");
        anchor.click();
        // revoke url after using it
        URL.revokeObjectURL(typeof urlLink === typeof "" ? urlLink as string : url);
    }

    const handleSelect = () => {
        setChecked(true)
    }

    return (
        <FormControl component="fieldset" style={{ width: "100%" }}>
            <RadioGroup
                // defaultValue={value} 
                name="customized-radios"
                onChange={handleSelect}>
                <Grid container className={classes.container}>
                    <Grid item>
                        <img src={fileICon} alt="file" />
                    </Grid>
                    &nbsp;&nbsp;&nbsp;
                    <Grid item style={{ flex: 1 }}>
                        <Typography className={classes.filename}>{value?.name}</Typography>
                        <Typography className={classes.filesize}>{formatBytesToKB()} KB</Typography>
                    </Grid>
                    {
                        <>
                            <Grid item style={{cursor: "pointer"}}>
                                <img src={downloadIcon} alt="file" onClick={() => downloadFile(value, value?.name, value?.url)} />
                            </Grid>
                            &nbsp;&nbsp;
                            {!isPreview && <Grid item>
                                <FormControlLabel
                                    className={classes.formCont}
                                    value={value}
                                    control={<StyledRadio index={index} resumeList={resumeList} checked={selectedId === resumeList?.[index].selectId} onChange={() => handleInputChange && handleInputChange(resumeList[index])}/>}
                                    label={''}
                                />
                            </Grid>}
                            {
                            <Grid item style={{cursor: "pointer"}} onClick={() => handleRemoveFile(resumeList?.[index].selectId)}>
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.72504 0.281552C9.58492 0.141112 9.39468 0.0621875 9.19629 0.0621875C8.9979 0.0621875 8.80766 0.141112 8.66754 0.281552L5.00004 3.94155L1.33254 0.274052C1.19242 0.133612 1.00218 0.0546875 0.803789 0.0546875C0.605401 0.0546875 0.415163 0.133612 0.275039 0.274052C-0.0174609 0.566552 -0.0174609 1.03905 0.275039 1.33155L3.94254 4.99905L0.275039 8.66655C-0.0174609 8.95905 -0.0174609 9.43155 0.275039 9.72405C0.567539 10.0166 1.04004 10.0166 1.33254 9.72405L5.00004 6.05655L8.66754 9.72405C8.96004 10.0166 9.43254 10.0166 9.72504 9.72405C10.0175 9.43155 10.0175 8.95905 9.72504 8.66655L6.05754 4.99905L9.72504 1.33155C10.01 1.04655 10.01 0.566552 9.72504 0.281552Z" fill="#9C9C9C"/>
                                </svg>
                            </Grid>
                            }
                            &nbsp;&nbsp;
                        </>
                    }
                </Grid>
            </RadioGroup>
        </FormControl>
    );
}
