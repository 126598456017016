import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { LeaderboardItem, LeaderboardItemResponse } from "./types";
import { createRequestMessage } from "./Helpers/create-request-message";
import { handleResponseMessage } from "./Helpers/handle-response-message";
import * as React from "react";

// Customizable Area Start
export  interface RowType {
  id:string
  rank: string;
  name: string;
  image: string;
  percentileScore: string;
  potentialScore: string;
  jobTitle: string;
  city: string;
  skills?: string[];
  badges: string;
  personalCredentials?: number;
  dealExperience?: number;
  marketKnowledge?: number;
  networking?: number;
  professionalDevelopment?: number;
  communityEngagement?: number;
  date?:string
}
import { getStorageData } from "framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  visibleRows: number,
  data: RowType[],
  remainingData:RowType[],
  anchorEl: HTMLElement | null;
  isLoadingWeb: boolean;
  leaderboardWeb: LeaderboardItem[];
  tokenWeb: string;
  totalCountWeb: number;
  totalPageWeb: number;
  pageIndexWeb: number;
  moreLoadingWeb: boolean;
  showRewards:boolean;
  userRole:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LeaderboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginLeaderboardWebApi: string;
  getLeaderboardWebApi: string;
  getMoreLeaderboardWebApi: string;
  pageSizeWeb: number;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showRewards:true,
      visibleRows: 10,
      isLoadingWeb: false,
      anchorEl: null,
      leaderboardWeb: [],
      tokenWeb: "",
      pageIndexWeb: 1,
      totalCountWeb: 1,
      totalPageWeb: 1,
      moreLoadingWeb: false,
      remainingData:[ 
    ],
      data: [
        { id:'1',rank: '1st',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/men/1.jpg', percentileScore: '99%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge1', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'2',rank: '2nd',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/2.jpg', percentileScore: '97%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge2', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'3',rank: '3rd',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '95%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge3', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'4',rank: '4th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/men/1.jpg', percentileScore: '93%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge4', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'5',rank: '5th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '91%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge5', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'6',rank: '6th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'7',rank: '7th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'8',rank: '8th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'9',rank: '9th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'10',rank: '10th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'11',rank: '11th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'12',rank: '12th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'13',rank: '13th',date:'29 Nov', name: 'Suzanne', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
        { id:'14',rank: '14th',date:'29 Nov', name: 'YOU', image: 'https://randomuser.me/api/portraits/women/4.jpg', percentileScore: '61%', potentialScore: '1000', jobTitle: 'Investment Professor', city: 'McAllen', badges: 'Badge6', personalCredentials: 1000, dealExperience: 1000, marketKnowledge: 1000, networking: 1000, professionalDevelopment: 1000, communityEngagement: 1000 },
  
      
      ],

      userRole:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleGetLeaderboardWeb = this.handleGetLeaderboardWeb.bind(this);
    this.handleGoToPage = this.handleGoToPage.bind(this);
    this.loginLeaderboardWebApi = "";
    this.getLeaderboardWebApi = "";
    this.getMoreLeaderboardWebApi = "";
    this.pageSizeWeb = 10;
    // Customizable Area End
  }

  async receive(from: string, receiveMessage: Message) {
    // Customizable Area Start
    if (receiveMessage.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallDataId = receiveMessage.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseDataJson = receiveMessage.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorDataJson = receiveMessage.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.setState({
        moreLoadingWeb: false,
      });

      switch (apiRequestCallDataId) {
        case this.getLeaderboardWebApi: {
          this.setState({
            isLoadingWeb: false,
          });
          handleResponseMessage({
            responseJson: responseDataJson,
            errorJson: errorDataJson,
            onSuccess: () => {
              const { leader_boards, message } = responseDataJson;
              if (leader_boards) {
                const { data, meta } = leader_boards;
                this.handleUpdatePaginationWeb(meta);
                this.handleUpdatePageData(data, false);
              } else {
                this.showAlert("", message);
              }
            },
            onFail: () => {
              this.showAlert("Error", "Get Leaderboard Failed! Please retry");
            },
          });
          break;
        }

        case this.loginLeaderboardWebApi: {
          handleResponseMessage({
            responseJson: responseDataJson,
            errorJson: errorDataJson,
            onSuccess: () => {
              this.setState(
                {
                  tokenWeb: responseDataJson.meta.token,
                },
                () => {
                  this.handleGetLeaderboardWeb();
                }
              );
            },
            onFail: () => {
              this.showAlert("Error", "Get Token Failed. Please retry!");
            },
          });
          break;
        }

        case this.getMoreLeaderboardWebApi: {
          handleResponseMessage({
            responseJson: responseDataJson,
            errorJson: errorDataJson,
            onSuccess: () => {
              const { leader_boards } = responseDataJson;
              if (leader_boards) {
                const { data, meta } = leader_boards;
                this.handleUpdatePaginationWeb(meta);
                this.handleUpdatePageData(data, true);
              }
            },
            onFail: () => {
              this.showAlert("Error", "Get Leaderboard Failed! Please retry");
            },
          });
          break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
 async componentDidMount() {
  const userRole = await getStorageData("userRole")
  this.setState({
    userRole
  })
}

  handleConvertDataWeb(data: LeaderboardItemResponse[]): LeaderboardItem[] {
    return data.map((item: LeaderboardItemResponse, index) => ({
      ...item.attributes,
      position: (this.state.pageIndexWeb - 1) * 10 + index + 1,
    }));
  }
  handleUpdatePaginationWeb(meta: {
    pagination: {
      current_page: number;
      next_page: number | null;
      prev_page: number | null;
      total_pages: number;
      total_count: number;
      current_count: number;
      per_page: number;
    };
  }) {
    if (meta && meta.pagination) {
      const { total_pages, total_count, current_page } = meta.pagination;

      this.setState({
        totalPageWeb: total_pages,
        totalCountWeb: total_count,
        pageIndexWeb: current_page,
      });
    }
  }
  handleUpdatePageData(data: LeaderboardItemResponse[], loadMore: boolean) {
    if (data) {
      const mapping: LeaderboardItem[] = this.handleConvertDataWeb(data);

      if (loadMore) {
        this.setState(() => ({
          moreLoadingWeb: false,
          leaderboardWeb: [...mapping],
        }));
      } else {
        this.setState({
          leaderboardWeb: mapping,
        });
      }
    }
  }
  
  handleGetLeaderboardWeb() {
    this.setState({
      isLoadingWeb: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLeaderboardWebApi = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.leaderboardURLEndPoint}?per_page=${
        this.pageSizeWeb
      }&page=${1}`,
      method: configJSON.getApiMethodType,
      token: this.state.tokenWeb,
    });
  }

  handleGoToPage(event?: React.ChangeEvent<unknown>, page?: number) {
    if (page) {
      this.setState({
        pageIndexWeb: page,
        moreLoadingWeb: true,
      });

      const requestMoreMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getMoreLeaderboardWebApi = requestMoreMessage.messageId;

      createRequestMessage({
        requestMessage: requestMoreMessage,
        endPoint: `${configJSON.leaderboardURLEndPoint}?per_page=${this.pageSizeWeb}&page=${page}`,
        method: configJSON.getApiMethodType,
        token: this.state.tokenWeb,
      });
      
    }
    
  }

  handleCondition = (condition: boolean, truePart: JSX.Element, falsePart: JSX.Element): JSX.Element  => {
    return condition ? truePart : falsePart
  }

  handleRewards=()=>{
    this.setState({showRewards:false})
  }
  handlePrevious=()=>{
    this.setState({showRewards:true})
  }
  handleFuntion =()=>{}
  // Customizable Area End
}
