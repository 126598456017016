import React from "react";

import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import LeaderboardController, {
  Props,
} from "./LeaderboardController.web";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {  styled,  withStyles } from "@material-ui/core/styles";
import { profileImage, filterIcon, textLabel, searchIcon, badgeIcon } from "./assets";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BootstrapTable , { ExpandRowProps }  from 'react-bootstrap-table-next';
import filterFactory  from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import CustomPopper from "../../../components/src/CustomPopper";
import CustomPopperRecruiter from "../../../components/src/CustomPopperRecruiter";
import CustomViewPopper from '../../../components/src/CustomViewPopper'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FilterToggel from '../../../components/src/FilterButton'
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PercentileGraph from "../../../components/src/PercentileGraph"


interface CustomSearchBarProps {
  searchText: string;
  onSearch: (value: string) => void;
  onClear: () => void;
}
export  interface RowType {
  id:string
  rank: string;
  name: string;
  image: string;
  percentileScore: string;
  potentialScore: string;
  jobTitle: string;
  city: string;
  skills?: string[];
  badges: string;
  personalCredentials?: number;
  dealExperience?: number;
  marketKnowledge?: number;
  networking?: number;
  professionalDevelopment?: number;
  communityEngagement?: number;
}



// Customizable Area End

export default class Leaderboard extends LeaderboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   searchFunction = (  searchText: any,
    onSearch= (e: any) => {},
    onClear= () => {}) => {
    return (
      <CustomInput
      data-test-id='search-field'
      type="text"
      placeholder="Search"
      value={searchText}
      onChange={e => onSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={searchIcon} alt="search-icon" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <img src={textLabel} alt="clear-icon" />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      fullWidth
    />

    );
   }
   expandRowFunctionality=(row: object, rowIndex: number) => {
    const typedRow = row as RowType;
   
    return (
      <ExpandedRowContainer>
        <ExpandedRowContent>
          <Box style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}>
            <CustomTooltip 
             placement="top"
            title={
              <ToolTipOuterBox >
                <ToolTipHeadingContainer>
                <ToolTipHeading >Personal Credentials</ToolTipHeading>
                </ToolTipHeadingContainer>
                <Grid container style={{padding:'12px 10px'}}>
                  <GridBox item md={9} sm={9} >
                  <AmountStyle >Education</AmountStyle>
                  <AmountStyle >Skills</AmountStyle>
                  <AmountStyle >Profile completeness</AmountStyle>

                  </GridBox>
                  <GridBox item md={3} sm={3} >  
                  <Quantity>40</Quantity>
                  <Quantity>23</Quantity>
                  <Quantity>53</Quantity>
                  </GridBox>
                </Grid>

                  
              </ToolTipOuterBox>}
              >
              <LinkStyle>Personal Credentials</LinkStyle>
              </CustomTooltip>
              <CustomTooltip 
             placement="top"
            title={
              <ToolTipOuterBox style={{width:'247px'}}>
                <ToolTipHeadingContainer>
                <ToolTipHeading >Deal Experience & Complexity</ToolTipHeading>
                </ToolTipHeadingContainer>
                <Grid container style={{padding:'12px 10px'}}>
                  <GridBox item md={9} sm={9} >
                  <AmountStyle >Deal size</AmountStyle>
                  <AmountStyle >Industry sectors</AmountStyle>
                  <AmountStyle>Experience adjustment factors</AmountStyle>
                  </GridBox>
                  <GridBox item md={3} sm={3} >  
                  <Quantity>35</Quantity>
                  <Quantity>65</Quantity>
                  <Quantity>95</Quantity>
                  </GridBox>
                </Grid>

                  
              </ToolTipOuterBox>}
              >
             <LinkStyle>Deal Experience & Complexity</LinkStyle>
              </CustomTooltip>
              <CustomTooltip 
             placement="top"
            title={
              <ToolTipOuterBox>
                <ToolTipHeadingContainer>
                <ToolTipHeading >Market Knowledge</ToolTipHeading>
                </ToolTipHeadingContainer>
                <Grid container style={{padding:'12px 10px'}}>
                  <GridBox item md={9} sm={9} >
                  <AmountStyle >Data contributions</AmountStyle>
                  <AmountStyle >Platform interactions</AmountStyle>

                  </GridBox>
                  <GridBox item md={3} sm={3} >  
                  <Quantity>45</Quantity>
                  <Quantity>55</Quantity>
                  </GridBox>
                </Grid>

                  
              </ToolTipOuterBox>}
              >            
                <LinkStyle>Market Knowledge</LinkStyle>
              </CustomTooltip>
            
            <CustomTooltip 
             placement="top"
            title={
              <ToolTipOuterBox>
                <ToolTipHeadingContainer>
                <ToolTipHeading >Networking & Endorsement</ToolTipHeading>
                </ToolTipHeadingContainer>
                <Grid container style={{padding:'12px 10px'}}>
                  <GridBox item md={8} sm={8} >
                  <AmountStyle >Connections</AmountStyle>
                  <AmountStyle >Endorsements</AmountStyle>

                  </GridBox>
                  <GridBox item md={4} sm={4} >  
                  <Quantity>15</Quantity>
                  <Quantity>15</Quantity>
                  </GridBox>
                </Grid>

                  
              </ToolTipOuterBox>}
              >
            <LinkStyle>Networking & Endorsement</LinkStyle>
            </CustomTooltip>
            <CustomTooltip 
             placement="top"
            title={
              <ToolTipOuterBox style={{width:'196px'}}>
                <ToolTipHeadingContainer>
                <ToolTipHeading >Professional Development</ToolTipHeading>
                </ToolTipHeadingContainer>
                <Grid container style={{padding:'12px 10px'}}>
                  <GridBox item md={9} sm={9} >
                  <AmountStyle >Courses and webinars</AmountStyle>
                  <AmountStyle >Continuous learning </AmountStyle>

                  </GridBox>
                  <GridBox item md={3} sm={3} >  
                  <Quantity>54</Quantity>
                  <Quantity>33</Quantity>
                  </GridBox>
                </Grid>

                  
              </ToolTipOuterBox>}
              >
             <LinkStyle>Professional Development</LinkStyle>
              </CustomTooltip>
              <CustomTooltip 
             placement="top"
            title={
              <ToolTipOuterBox style={{width:'247px'}}>
                <ToolTipHeadingContainer>
                <ToolTipHeading >Community Engagement</ToolTipHeading>
                </ToolTipHeadingContainer>
                <Grid container style={{padding:'12px 10px'}}>
                  <GridBox item md={9} sm={9} >
                  <AmountStyle >Questions and answers</AmountStyle>
                  <AmountStyle >Upvotes & accepted answers</AmountStyle>

                  </GridBox>
                  <GridBox item md={3} sm={3} >  
                  <Quantity>75</Quantity>
                  <Quantity>49</Quantity>
                  </GridBox>
                </Grid>

                  
              </ToolTipOuterBox>}
              >  
              <LinkStyle>Community Engagement</LinkStyle>


              </CustomTooltip>
          </Box>
          <OuterBox>
            <AmountStyle>{typedRow.personalCredentials}</AmountStyle>
            <AmountStyle>{typedRow.dealExperience}</AmountStyle>
            <AmountStyle>{typedRow.marketKnowledge}</AmountStyle>
            <AmountStyle>{typedRow.networking}</AmountStyle>
            <AmountStyle>{typedRow.professionalDevelopment}</AmountStyle>
            <AmountStyle>{typedRow.communityEngagement}</AmountStyle>
          </OuterBox>
        </ExpandedRowContent>
      </ExpandedRowContainer>
    );
  }
  expandHeader = () => {
    return <div></div>;
  }
  expandRow: ExpandRowProps<object, unknown> = {
    className: 'custom-expanded-row',
    renderer: this.expandRowFunctionality,
    expandHeaderColumnRenderer: this.expandHeader,
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded }) => {

return <ExpandIcon>
{expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
</ExpandIcon>
    },
  };
nameColumn = (cell: any, row: RowType) => (

  <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
    <Avatar data-test-id='avatar' src={row.image} style={{ width: '32px', height: '32px' }} />
    {row.name}
  </div>

)
skillColumn =  (cell: any, row: RowType) => (
        
  <CustomViewPopper
  rowId={row.id}
  onSendMessage={this.handleFuntion}
  onSendInvite={this.handleFuntion}
/>

  )
  actionColumn = (cell: any, row: RowType, rowIndex: number) => (
    <CustomPopper
    testId="Popper"
    rowId={row.id}
    onSendMessage={this.handleFuntion}
    onSendInvite={this.handleFuntion}
  />

)
actionColumnRecruiter = (cell: any, row: RowType, rowIndex: number) => (
  <CustomPopperRecruiter
  testId="Popper2"
  rowId={row.id}
  onSendMessage={this.handleFuntion}
  onSendInvite={this.handleFuntion}
/>

)
badgeColumn = (cell: any, row: RowType) => (
      
  <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
    <Avatar src={badgeIcon} style={{ width: '34px', height: '24px' }} />
  </div>
)
percentileColumn = (cell: any, row: RowType) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <PercentileGraph data={[0, 80, 35, 40, 0,90]} percentage={row.percentileScore} />
  </div>
);
columns = [
  { dataField: 'rank', text: 'Rank', sort: true ,headerStyle: { width: '40px' }  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    headerStyle: { width: '110px' },
    formatter: this.nameColumn
  },
  { dataField: 'percentileScore', text: 'Percentile Score', sort: true , headerStyle: { width: '105px' }, formatter:this.percentileColumn  },
  { dataField: 'potentialScore', text: 'Potential Score', sort: true, headerStyle: { width: '100px' }  },
  { dataField: 'jobTitle', text: 'Job Title', sort: true, headerStyle: { width: '160px' }  },
  { dataField: 'city', text: 'City', sort: true, headerStyle: { width: '80px' }  },
  {
    dataField: 'skills',
    text: 'Skills',
    sort: true,
    headerStyle: { width: '90px' },
    formatter: this.skillColumn
    
  },
  { dataField: 'badges', text: 'Badges', sort: true , headerStyle: { width: '60px' } ,
  formatter:this.badgeColumn
  
},
  {
    dataField: 'actions',
    text: '',
    headerStyle: { width: '30px' },
    formatter: this.actionColumn
  
  },

];
  columnsRecruiter = [
    {headerStyle: { width: '40px' } , dataField: 'rank', text: 'Rank', sort: true , },
    {
      text: 'Name',
      sort: true,
      headerStyle: { width: '110px' },
      dataField: 'name',
      formatter: this.nameColumn
    },
    { sort: true , headerStyle: { width: '105px' }, formatter:this.percentileColumn ,dataField: 'percentileScore', text: 'Percentile Score',  },
    {  sort: true, headerStyle: { width: '100px' } ,dataField: 'potentialScore', text: 'Potential Score', },
    { sort: true, headerStyle: { width: '160px' },dataField: 'jobTitle', text: 'Job Title',  },
    {  sort: true, headerStyle: { width: '80px' } ,dataField: 'city', text: 'City', },
    {
      dataField: 'skills',

      formatter: this.skillColumn,
      text: 'Skills',
      sort: true,
      headerStyle: { width: '90px' },
      
    },
    { dataField: 'badges', text: 'Badges', sort: true , headerStyle: { width: '60px' } ,
    formatter:this.badgeColumn
    
  },
    {
      headerStyle: { width: '30px' },
      dataField: 'actions',
      text: '',
      formatter: this.actionColumnRecruiter
    
    },

  ];
  myRankingColumns = [
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      headerStyle: { width: '120px' },
    },
    { dataField: 'rank', text: 'Ranking', sort: true ,headerStyle: { width: '130px' }  },
   
    { dataField: 'potentialScore',  sort: true, text: 'Potential Score',headerStyle: { width: '100px' }  },
    { dataField: 'jobTitle', sort: true,text: 'Job Title',  headerStyle: { width: '160px' }  },
    { dataField: 'city', sort: true, text: 'City', headerStyle: { width: '100px' }  },
    {
      dataField: 'skills',
      sort: true,
      text: 'Skills',
      headerStyle: { width: '100px' },
      formatter: this.skillColumn
      
    },
    { dataField: 'badges', headerStyle: { width: '60px' } , text: 'Badges', sort: true ,
    formatter:this.badgeColumn
    
  },
    {
      dataField: 'actions',
      text: '',
      headerStyle: { width: '30px' },
      formatter: this.actionColumn
    
    },

  ];
  rowStyle = (row: RowType, rowIndex: number) => {
    if (row.name.includes('YOU')) {
      return { backgroundColor: '#FEF3C7', position: 'sticky', top: '48px', zIndex: 2 };
    }
    return {};
  };

  getView=(props:any) => (
    <>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box style={{
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'Arial',
          
        }}>Leaderboard</Box>
        <Box style={{  alignItems: 'center' , gap:'20px',display: 'flex',}}>
          <Box style={{display: 'flex', alignItems: 'center', cursor: 'pointer',  }}>
            <FilterToggel/>
          </Box>
         
         {this.searchFunction(props.searchProps.searchText,
         props.searchProps.onClear,
                          props.searchProps.onSearch,
                          
)}

        </Box>
      </Box>
      <Box style={{display:'flex',marginBottom:'10px', gap:'10px', }}>
        <SubHeading>
        Average Score: 500
        </SubHeading>
        <SubHeading>
        Highest Score: 500
        </SubHeading>
      </Box>
      <Box style={{
          borderRadius: '8px',
          border: '1px Solid #F4F4F4',
        boxShadow: '0px 2px 8px 0px #00000014',
      
      }}>
        <style>
          {`
            .custom-expanded-row {
              padding: 10px;

              background: #f5f3ef;
            }
          `}
        </style>
        <StyledTable>
          <BootstrapTable
            {...props.baseProps}
            filter={filterFactory()}
            rowStyle={this.rowStyle}
            noDataIndication="No Records Found"
            expandRow={this.expandRow}
          />
        </StyledTable>
      </Box>
    </>
  )

   youRow = this.state.data.find((row: RowType) => row.name === 'YOU');
   otherRows = this.state.data.filter((row: RowType) => row.name !== 'YOU');

   tableData = this.youRow ? [this.youRow, ...this.otherRows] : this.otherRows;

  // Customizable Area End

  render() {
    const {
      leaderboardWeb,
      pageIndexWeb,
      totalPageWeb,
      isLoadingWeb,
      moreLoadingWeb,
    } = this.state;

    return (
      // Customizable Area Start
      <div>
        {this.state.userRole ==='candidate' ?
        (      
             <Grid container >
           {this.handleCondition(this.state.showRewards,
          <Grid container style={{padding:'20px' }}>
         <Grid item md={3} sm={12} style={{  paddingLeft:'10px'}} >
          <SideBox>
          <Box style={{ display:'flex',flexDirection:'column',gap:'10px'}}>

          <Box style={{ display: "flex", alignItems: "center" }} >
          <Avatar style={{ background: '#6D97C1', color: "#FFFFFF", width:'50px', height:'50px', }}>
            <div style={{marginTop:'4px'}}>M</div>

          </Avatar>
          <AvatarName>
            MJ
          </AvatarName>
        </Box>
           <ProfileTag>
           <img src={profileImage} alt={'profileImage'} />
           <Box
           style={{
            fontFamily: 'Arial',
            fontSize: '10px',
            fontWeight: 400,
            marginLeft:'5px',
            color:'#767373'
           }}
           >Posting as: 
           </Box>
           <Box 
           style={{
            fontFamily: 'Arial',
            fontSize: '12px',
            fontWeight: 400,
            color: '#1F497D',
            marginLeft:'5px'

           }}
           >an Investment Professor</Box>
           </ProfileTag>
           <Box
          sx={{
            width: 'inherit',
            height: 'auto',
            borderRadius: '16px',
            padding: '13px',
          }}
          style={{ backgroundColor: '#F3F4F9' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }} className="progressPercentageWrapper" >
            <Box sx={{ position: 'relative', display: 'inline-flex' }} className="percentageBar" >
              <CircularProgress
                variant="determinate"
                value={100}
                style={{
                  color: '#EEECE1', width: '60px',
                  height: '60px'
                }}
              />
              <CircularProgress
                variant="determinate"
                value={85}
                style={{
                  color: "#6D97C1", position: 'absolute', top: 0, left: 0, width: '60px',
                  height: '60px'
                }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  style={{ color: "#17365D", fontSize: '14px', fontFamily: "Arial" }}>
                  {`85%`}
                </Typography>
              </Box>
            </Box>
            <Box ml={1.5}>
              <ProgressHeading >My Progress</ProgressHeading>
              <ProgressSubheading >Congratulations! You ranked 2nd in the list </ProgressSubheading>
            </Box>
          </Box>
        </Box>
       <Box >
        <RankingButton data-test-id="reward-button" onClick={this.handleRewards}>
        Go to my ranking
        <StyledIconStyle />
        </RankingButton>

       </Box>
       <Box>
        <RewardButton >
          Your Rewards
          <StyledIconStyle />
        </RewardButton>
       </Box>
         </Box>

          </SideBox>
         </Grid>
         <Grid item  md={9} sm={12} style={{}}
             >
         <Box style={{
           marginLeft:'20px',
           background: "#ffffff",
           height:'100vh',
           borderRadius: '16px',
           overflow: 'hidden'  }}>
         <Grid style={{ padding: '20px' }}>
              <ToolkitProvider
                keyField="rank"
                data={this.tableData}
                columns={this.columns}
                search
              >
                {this.getView}
              </ToolkitProvider>
            </Grid>
         </Box>
           
         </Grid>
          </Grid>,
          <Grid container style={{padding:'20px'}} >
         <Grid item  md={12} sm={12} style={{marginLeft:'45px',  marginRight:'50px'}}
             >
         <Box style={{
           background: "#ffffff",
           height:'100vh',
           borderRadius: '16px', }}>
         <Grid style={{ padding: '20px' }}>
              <ToolkitProvider
                keyField="rank"
                data={this.state.data}
                columns={this.myRankingColumns}
                search
              >
                {props => (
                  <>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'20px' }}>
                      <Box style={{
                        fontFamily: 'Arial',
                        fontSize: '14px',
                        fontWeight: 700,
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        gap:'5px'
                      }}>
                        <ArrowBackIosIcon data-test-id="back-button" style={{ width: '15px', height: '15px', cursor:'pointer' }} onClick={this.handlePrevious} />
                        My Rankings</Box>
                      <Box style={{ display: 'flex', alignItems: 'center' , gap:'20px'}}>
                        <Box style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                        <FilterToggel/>                        </Box>
                       
                        {this.searchFunction(props.searchProps.searchText,
                          props.searchProps.onSearch,
                          props.searchProps.onClear
)}
<MoreVertIcon/>

                      </Box>
                    </Box>
                    <Box style={{
                      boxShadow: '0px 2px 8px 0px #00000014',
                      borderRadius: '8px',
                      border: '1px Solid #F4F4F4'
                    }}>
                      <style>
                        {`
                         .load-more-row {
                          text-align: center;
                          font-weight: bold;
                        }
                          .custom-expanded-row {
                            background: #f5f3ef;
                            padding: 10px;
                          }
                         
                        `}
                      </style>
                      <StyledTable>
                        <BootstrapTable
                        noDataIndication="No Records Found"
                        expandRow={this.expandRow}
                          {...props.baseProps}
                          filter={filterFactory()}
                          
                        />
                      </StyledTable>
                    </Box>
                  </>
                )}
              </ToolkitProvider>
            </Grid>
         </Box>
           
         </Grid>     
       </Grid>
           )}
         </Grid>
         ) :(
              <Grid container style={{padding:'20px'}} >
         <Grid item  md={12} sm={12} style={{marginLeft:'45px',  marginRight:'50px'}}
             >
         <Box style={{
           background: "#ffffff",
           height:'100vh',
           borderRadius: '16px', }}>
         <Grid style={{ padding: '20px' }}>
              <ToolkitProvider
                keyField="rank"
                data={this.state.data}
                columns={this.columnsRecruiter}
                search
              >
                {props => (
                  <>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'20px' }}>
        <Box style={{
          fontFamily: 'Arial',
          fontSize: '14px',
          fontWeight: 700
        }}>Leaderboard</Box>
        <Box style={{ display: 'flex', alignItems: 'center' , gap:'20px'}}>
          <Box style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <FilterToggel/>
          </Box>
         
         {this.searchFunction(props.searchProps.searchText,
                          props.searchProps.onSearch,
                          props.searchProps.onClear
)}

        </Box>
      </Box>
      <Box style={{display:'flex', gap:'10px', marginBottom:'10px'}}>
        <SubHeading>
        Average Score: 500
        </SubHeading>
        <SubHeading>
        Highest Score: 500
        </SubHeading>
      </Box>                   
                    <Box style={{
                      boxShadow: '0px 2px 8px 0px #00000014',
                      borderRadius: '8px',
                      border: '1px Solid #F4F4F4'
                    }}>
                      <style>
                        {`
                         .load-more-row {
                          text-align: center;
                          font-weight: bold;
                        }
                          .custom-expanded-row {
                            background: #f5f3ef;
                            padding: 10px;
                          }
                         
                        `}
                      </style>
                      <StyledTable>
                        <BootstrapTable
                        noDataIndication="No Records Found"
                        expandRow={this.expandRow}
                          {...props.baseProps}
                          filter={filterFactory()}
                          
                        />
                      </StyledTable>
                    </Box>
                  </>
                )}
              </ToolkitProvider>
            </Grid>
         </Box>
           
         </Grid>     
       </Grid>
         )

         } 
         </div> 
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledTable = styled('div')({
  height: '80%',
  overflowY: 'auto',
  
  scrollbarWidth: 'thin', 
  scrollbarColor: "#1F497D #D9D9D9", 
  
  '& .react-bootstrap-table': {
    border: 'none',
  },
  
  '& .react-bootstrap-table table': {
    width: '100%',
    borderCollapse: 'collapse',
  },
  
  '& .react-bootstrap-table th': {
    height: '49px',
    textAlign: 'start',
    paddingLeft: '10px',
    backgroundColor: '#ffffff',
    color: '#1F497D',
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: 700,
    borderBottom: '1px solid #F4F4F4',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  
  '& .react-bootstrap-table td': {
    height: '58px',
    padding: '10px',
    color: '#1F497D',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: '1px solid #F4F4F4',
  },
  
  '& .react-bootstrap-table tr': {
    border: 'none',
  },
  
  '&::-webkit-scrollbar': {
    width: '3px',
  },
  
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#D9D9D9',
  },
  
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#1F497D',
    borderRadius: '5px',
    width: '3px',  },
  
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#1F497D', 
    width: '3px',
  },
  
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: '#1F497D',
    width: '3px',
  },
  
  '&::-webkit-scrollbar-corner': {
    backgroundColor: '#D9D9D9', 
  }
});


const ExpandedRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: 'calc(100% / 7)', 
  background: '#f5f3ef',
  padding: '10px',
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    padding: '0px',
    borderRadius:'8px',
  },
  arrow: {
    color: '#FFFFFF',
  },
}))(Tooltip);

 const ToolTipHeadingContainer = styled(Box)(({ theme }) => ({
  background:' #F0F5F9',
   padding:'12px 10px',
   borderTopLeftRadius:'8px',
   borderTopRightRadius:'8px'
}));
const ToolTipOuterBox = styled(Box)(({ theme }) => ({
  background:'#FFFFFF', borderRadius:'8px', minWidth:'200px'

}));
const ToolTipHeading = styled(Typography)(({ theme }) => ({
  color: "#1F497D",
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Arial'
}));

const Quantity = styled(Typography)(({ theme }) => ({
  color: "#D97706",
  fontSize: '10px',
  fontWeight: 400,
  fontFamily: 'Arial',
  marginTop:'3px'
}));
const ExpandedRowContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '65px',
}));

const GridBox = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  flexDirection:'column'
}));

const OuterBox = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  gap:'16px',
}));
const ExpandIcon = styled(IconButton)(({ theme }) => ({
 padding:'0px',  
 '&:hover': {
  background: "transparent",
},

}));
const SideBox = styled(Box)(({ theme }) => ({
  background: "#ffffff",
    borderRadius: '16px',
    padding: '20px',
    marginBottom:'10px',
}));
const AvatarName = styled(Typography)(({ theme }) => ({
  marginLeft: '15px',
  color: "#17365D",
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Arial'
}));
const SubHeading = styled(Typography)(({ theme }) => ({

  color: "#1F497D",
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Arial'
}));

const ProgressHeading = styled(Typography)(({ theme }) => ({
  color: "#17365D",
  fontSize: '12px',
  fontWeight: 700,
  fontFamily: 'Arial'
}));
const AmountStyle = styled(Typography)(({ theme }) => ({
  color: "#6D97C1",
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Arial'
}));
const LinkStyle = styled(Typography)(({ theme }) => ({
  color: "#1F497D",
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Arial',
  textDecoration:'underline',
  cursor:'pointer'
}));
const ProgressSubheading = styled(Typography)(({ theme }) => ({
  maxWidth:'110px',
  color: "#17365D",
  fontSize: '10px',
  fontWeight: 400,
  fontFamily: 'Arial'
}));
const ProfileTag = styled(Box)(({ theme }) => ({
  display:'flex',
height: '26px',
padding: '3px 12px 3px 12px',
borderRadius:' 7px',
background: '#F3F4F9',
alignItems:'center'

}));
const RankingButton = styled(Button)(({ theme }) => ({
  width: '100%',
height: '44px',
padding: '10px 16px 10px 16px',
background: '#6D97C1',
color:"#FFFFFF",
borderRadius:' 8px',
fontSize: '16px',
fontWeight: 700,
fontFamily: 'Arial',
display:'flex',
textTransform: 'none',
justifyContent:"space-between",
'&:hover': {
  background: "#6D97C1",
},

}));
const RewardButton = styled(Button)(({ theme }) => ({
  width: '100%',
height: '44px',
padding: '10px 16px 10px 16px',
background: '#FFFFFF',
color:"#6D97C1",
borderRadius:' 8px',
fontSize: '16px',
fontWeight: 700,
fontFamily: 'Arial',
display:'flex',
textTransform:'none',
justifyContent:"space-between",
border: '1px solid #EEECE1',
'&:hover': {
  background: "#FFFFFF",
},

}));
const StyledIconStyle = styled(ArrowForwardIosIcon)(({ theme }) => ({
  width: '24px',
  height: '24px',
}));
const CustomInput = styled(TextField)(({ theme }) => ({
  background:'#f4f4f4', 
  borderRadius:'8px',
  padding: '3px 4px',
  height:'29.4px',
  width:'208px',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  '& .MuiOutlinedInput-root': {
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    paddingTop:'23px'

  },
  '& .MuiInputAdornment-root': {
    margin: '6px',
  },
  '& .MuiOutlinedInput-adornedStart':{
    padding:'0px',
  },
  '& .MuiOutlinedInput-adornedEnd':{
    padding:'0px'
  }
}));


// Customizable Area End
