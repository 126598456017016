import React, { useState, useRef, useEffect } from "react";
import { ClickAwayListener, Grow, Popper, MenuItem, MenuList, Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      
      
        },
   
    btn: {
      minWidth: "auto",
      padding: 0,
    },
    menuitem: {
      fontSize: '12px',
      fontWeight: 400,

      color: '#1F497D',
      fontFamily: 'Arial',

      '&:hover': {
          backgroundColor: 'transparent', 
        }    
  },
  })
);

type CustomPopperProps = {
  rowId: string;
  onSendInvite: () => void;
  onSendMessage: () => void;
  testId?: string
};

const CustomPopper: React.FC<CustomPopperProps> = ({ rowId, onSendMessage, onSendInvite, testId }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
  

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

 

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }


  return (
    <div data-test-id={testId}>
      <Button ref={anchorRef} onClick={handleToggle} className={classes.btn}>
        <MoreVertIcon />
      </Button>
      <div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal 
      style={{
        zIndex:10,
        background: "#FFF",
        border: '1px solid #F4F4F4',
        marginTop:'10px',
        borderRadius:'16px',
        width: '207px',
        height: '78px',
        padding:' 2px 15px',
        
}}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: "center top", background: "#FFF", }} 
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id={`menu-list-grow-${rowId}`}
                onKeyDown={handleListKeyDown}
                className={classes.popover}
              >
                <MenuItem className={classes.menuitem} onClick={onSendMessage}>Send message</MenuItem>
                <MenuItem className={classes.menuitem} onClick={onSendInvite}>Send invite</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
      </div>
    </div>
  );
};

export default CustomPopper;
