import React, { useState, useRef, useEffect } from "react";
import { ClickAwayListener, Grow, Popper, MenuItem, MenuList, Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      
      
        },
    menuitem: {
        color: '#1F497D',
        fontFamily: 'Arial',
        fontSize: '12px',
        fontWeight: 400,
        '&:hover': {
            backgroundColor: 'transparent', 
          }    
    },
    btn: {
            height: '24px',
            backgroundColor: '#6D97C1',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            padding: '2px 8px 2px 8px',
            cursor: 'pointer',
            fontFamily: 'Arial',
            fontSize: '12px',
            fontWeight: 400,
            textTransform:'none',
            '&:hover': {
                backgroundColor: '#6D97C1', 
              } 
    },
  })
);

type CustomPopperProps = {
  rowId: string;
  onSendMessage: () => void;
  onSendInvite: () => void;
};

const CustomViewPopper: React.FC<CustomPopperProps> = ({ rowId, onSendMessage, onSendInvite }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button ref={anchorRef} onClick={handleToggle} className={classes.btn}>
      view...
      </Button>
      <div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal 
      placement="bottom-end"
      style={{
        zIndex:10,
        background: "#FFF",
        borderRadius:'16px',
        width: '181px',
        height: '165px',
        padding:' 2px 15px',
        border: '1px solid #F4F4F4',
        marginTop:'10px'
        
}}>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: "center top", background: "#FFF", }} 
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id={`menu-list-grow-${rowId}`}
                onKeyDown={handleListKeyDown}
                className={classes.popover}
              >
                <MenuItem className={classes.menuitem} onClick={onSendMessage}>Private Equity</MenuItem>
                <MenuItem className={classes.menuitem} onClick={onSendInvite}>Growth Equity</MenuItem>
                <MenuItem className={classes.menuitem} onClick={onSendInvite}>Venture Capital</MenuItem>
                <MenuItem className={classes.menuitem} onClick={onSendInvite}>Infrastructure Investment</MenuItem>
                <MenuItem className={classes.menuitem} onClick={onSendInvite}>Real Estate Investment</MenuItem>

             
             
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
      </div>
    </div>
  );
};

export default CustomViewPopper;
