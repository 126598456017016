import React, { CSSProperties } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ProgressCircle from "../../../../packages/components/src/ProgressCircle.web";
// Customizable Area End

import CfscorecalculationsController, {
  Props,
  configJSON,
} from "./CfscorecalculationsController";

export default class Cfscorecalculations extends CfscorecalculationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container style={webStyle.page}>
        <Grid item sm={12} style={webStyle.header}>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" id="back-icon" style={webStyle.backIcon as CSSProperties} onClick={this.handleBackNavigation}>
            <path d="M6.49953 9.87655L2.61953 5.99655L6.49953 2.11655C6.88953 1.72655 6.88953 1.09655 6.49953 0.706548C6.3127 0.519295 6.05905 0.414062 5.79453 0.414062C5.53001 0.414062 5.27636 0.519295 5.08953 0.706548L0.499531 5.29655C0.109531 5.68655 0.109531 6.31655 0.499531 6.70655L5.08953 11.2965C5.47953 11.6865 6.10953 11.6865 6.49953 11.2965C6.87953 10.9065 6.88953 10.2665 6.49953 9.87655Z" fill="#9C9C9C" />
          </svg>
          &nbsp;
          &nbsp;
          <Typography style={webStyle.progressText}>Progress</Typography>
        </Grid>
        <Box style={webStyle.subHeader}>
          <Box style={webStyle.subHeaderLeft as CSSProperties}>
            <Typography style={webStyle.mainTitle}>Your progress</Typography>
           {this.state.rank && <Typography style={webStyle.mainSubTitle}>You ranked {this.state.rank}.</Typography>}
          </Box>
          <Box style={webStyle.subHeaderRight}>
            <ProgressCircle progress={parseFloat(this.state.progress)} />
          </Box>
        </Box>
        <Grid item container sm={12} style={webStyle.container} spacing={5}>
          <Grid item sm={12} xs={12} md={6}>
            {
              this.state.cardList.map((card) => <Box id="card-cat" style={this.state.cardDetails.id === card.id ?
                webStyle.cardSelect
                : (card.id === 7 || card.id === 5) ?
                  { ...webStyle.card, background: "#F4F4F4", cursor: "none" }
                  : webStyle.card} key={card.id} onClick={() => this.handleCardClick(card)}>
                <Box style={webStyle.cardLeft}>
                  <img src={card.image} height={"55px"} width={"58px"} />
                  &nbsp;
                  &nbsp;
                  <Box>
                    <Typography style={webStyle.cardTitle}>
                      {card.title}
                    </Typography>
                    <Typography style={webStyle.cardDescription}>
                      Completing the following categories will determine your score.
                    </Typography>
                  </Box>
                </Box>
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.999356 13.0385L6.17269 7.86521L0.999356 2.69187C0.749686 2.44277 0.609375 2.10457 0.609375 1.75188C0.609375 1.39918 0.749686 1.06098 0.999356 0.811875C1.51936 0.291875 2.35936 0.291875 2.87936 0.811875L8.99936 6.93188C9.51936 7.45188 9.51936 8.29188 8.99936 8.81188L2.87936 14.9319C2.35936 15.4519 1.51936 15.4519 0.999356 14.9319C0.492689 14.4119 0.479356 13.5585 0.999356 13.0385Z" fill="#9C9C9C" />
                </svg>

              </Box>
              )
            }
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <Box style={webStyle.cardDetailSelect as CSSProperties}>
              <Box style={webStyle.cardDetailHeader}>
                <Typography style={webStyle.titleCardDetailHeader}>
                  Category
                </Typography>
                <Typography style={webStyle.titleCardDetailHeader}>
                  Points
                </Typography>
              </Box>
              <Divider style={webStyle.divider} />
              {
                this.state.cardDetails.categoriesWithPrice.map((detail: {
                  category: string;
                  point: string;
                  subPoint?: string | undefined;
                  description: string;
                  subCategorylist: {
                    subcategory: string;
                    subcategoryDetails: string;
                  }[];
                }) => <Box style={webStyle.infoContainer as CSSProperties}>
                    <Box style={webStyle.flexRowSpaceBetween}>
                      <Typography style={{ ...webStyle.cardFooterTitleOne, ...webStyle.textAlignLeft } as CSSProperties}>
                        {detail.category}
                      </Typography>
                      <Typography style={webStyle.cardFooterTitleOne as CSSProperties}>
                        {detail.point}<span style={{ fontSize: "12px", color: "#9C9C9C" }}> {detail.subPoint}</span>
                      </Typography>
                    </Box>
                    <Box style={webStyle.flexRowSpaceBetween}>
                      <div style={{ background: "#6D97C1", height: "3px", width: "50%", borderRadius: "16px", alignSelf: "center" }}>
                      </div>
                      <Typography style={webStyle.titleCardDetailHeader}>
                        {detail.description}
                      </Typography>
                    </Box>
                    {
                      detail.subCategorylist.map((subDetails: { subcategory: string, subcategoryDetails: string }) => <Box style={webStyle.flexRowSpaceBetween}>
                        <Typography style={webStyle.titleCardDetailHeader}>
                          {subDetails.subcategory}
                        </Typography>
                        <Typography style={webStyle.titleCardDetailHeader}>
                          {subDetails.subcategoryDetails}
                        </Typography>
                      </Box>)
                    }
                  </Box>)
              }
              <Box style={webStyle.cardDetailFooter as CSSProperties}>
                <Typography style={{ ...webStyle.cardFooterTitleOne, ...webStyle.textAlignLeft } as CSSProperties}>
                  Total
                </Typography>
                <Typography style={webStyle.cardFooterTitle as CSSProperties}>
                  {Math.round(+this.getTotalScore(this.state.cardDetails.title))}
                </Typography>
              </Box>
              <Box style={webStyle.cardDetailFooterOne as CSSProperties}>
                <Typography style={{ ...webStyle.cardFooterTitleOne, ...webStyle.textAlignLeft } as CSSProperties}>
                  Absolute Score
                </Typography>
                <Typography style={webStyle.cardFooterTitleOne as CSSProperties}>
                  {Math.round(+this.getAbsoluteScore(this.state.cardDetails.title))}
                </Typography>
              </Box>
              <Box style={webStyle.cardDetailFooterOne as CSSProperties}>
                <Typography style={{ ...webStyle.cardFooterTitleOne, ...webStyle.textAlignLeft } as CSSProperties}>
                  Percentile Score
                </Typography>
                <Typography style={webStyle.cardFooterTitleOne as CSSProperties}>
                  {Math.round(+this.getPercentileScore(this.state.cardDetails.title))}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  page: {
    background: "#ffffff",
    padding: "15px 30px",
    maxWidth: "1125px",
    borderRadius: "16px",
    margin: "20px 0px",
  },
  progressText: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Arial"
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  backIcon: {
    cursor: "pointer"
  },
  headerTitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Arial"
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px",
    padding: "14px 32px",
    width: "100%",
    border: "2px solid rgba(0, 0, 0, 0.03)",
    margin: "32px 24px",
    height: "87.18px",
    boxShadow: "0px 8px 32px 0px #0000000F"
  },
  subHeaderLeft: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px"
  },
  mainTitle: {
    color: "#1F497D",
    fontWeight: "bold",
    fontSize: "24px",
    fontFamily: "Arial"
  },
  mainSubTitle: {
    color: "#1F497D",
    fontWeight: "400",
    fontSize: "12px",
    fontFamily: "Arial"
  },
  subHeaderRight: {
    borderRadius: "16px"
  },
  container: {
    padding: "0px 32px",
  },
  card: {
    width: "100%",
    border: "2px solid rgba(0, 0, 0, 0.03)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px",
    borderRadius: "8px",
    cursor: "pointer",
    marginBottom: "15px"
  },
  cardSelect: {
    width: "100%",
    border: "1px solid #6D97C1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 12px",
    borderRadius: "8px",
    cursor: "pointer",
    marginBottom: "15px",
    boxShadow: "0px 8px 32px 0px #0000000F"
  },
  cardLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start"
  },
  cardTitle: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#17365D"
  },
  cardDescription: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "14px",
    color: "#9C9C9C"
  },
  cardDetailSelect: {
    width: "100%",
    border: "1px solid #6D97C1",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px #0000000F"
  },
  cardDetailHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  titleCardDetailHeader: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    color: "#9C9C9C"
  },
  divider: {
    height: "1px",
    background: "#E2E8F0",
    width: "100%",
    marginTop: "10px",
    marginBottom: "15px"
  },
  cardDetailFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    background: "#6D97C1",
    borderRadius: "4px",
    padding: "8px",
    marginBottom: "15px",
    marginTop: "20px",
    boxSizing: "border-box"
  },
  cardFooterTitle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: "Arial",
    fontWeight: "lighter",
    width: "50%",
    textAlign: "right"
  },
  cardDetailFooterOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    background: "#F4F4F4",
    borderRadius: "4px",
    padding: "8px",
    marginBottom: "15px",
    boxSizing: "border-box"
  },
  cardFooterTitleOne: {
    fontSize: "14px",
    color: "#17365D",
    fontFamily: "Arial",
    fontWeight: "lighter",
    width: "50%",
    textAlign: "right",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    paddingBottom: "12px"
  },
  flexRowSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100%",
    paddingBottom: "4px"
  }
};
// Customizable Area End
