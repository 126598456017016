import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { community, dealExperience, imgPasswordInVisible, imgPasswordVisible, marketknowledge, networking, personalCreds, potentialScore, professionalDev } from "./assets";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

interface CardDetails {
  id: number,
  title: string,
  image: string,
  categoriesWithPrice: {
    category: string,
    point: string,
    subPoint?: string,
    description: string,
    subCategorylist: {
      subcategory: string,
      subcategoryDetails: string
    }[] | []
  }[]
}

type Score = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    education_points: number;
    personal_details_points: number;
    skill_details_points: number;
    work_experience_points: number;
    deal_contribution_points: number;
    deal_size_complexity_points: number;
    market_knowledge_transaction_points: number;
    connections_points: number;
    feedposts_points: number;
    comments_points: number;
    upvotes_points: number;
    total_points: number;
    absolute_score: number;
    percentile_score: number;
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cardDetails: CardDetails;
  cardList: CardDetails[];
  scoreData: Score[];
  rank: null | number;
  progress: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfscorecalculationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScorecardsDetailApiid: string = ""
  getProfileDetailsApiId: string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      progress: "0",
      cardDetails: {
        id: 1,
        title: "Personal Credentials Score",
        image: personalCreds,
        categoriesWithPrice: [
          {
            point: "2 points per skill",
            category: "Skills",
            subCategorylist: [],
            description: "(up to a maximum of 30 points)",
          },
          {
            point: "5 points",
            category: "Work Experience",
            subCategorylist: [],
            description: "(up to a maximum of 50 points)",
          },
          {
            point: "20 points",
            category: "Bio and Profile Picture",
            subCategorylist: [],
            description: "",
          },
        ]
      },
      cardList: [
        {
          id: 1,
          title: "Personal Credentials Score",
          image: personalCreds,
          categoriesWithPrice: [
            {
              point: "20 points",
              category: "Bio and Profile Picture",
              subCategorylist: [],
              description: "",
            },
            {
              point: "5 points",
              category: "Work Experience",
              subCategorylist: [],
              description: "(up to a maximum of 50 points)",
            },
            {
              category: "Skills",
              point: "2 points per skill",
              description: "(up to a maximum of 30 points)",
              subCategorylist: []
            },
          ]
        },
        {
          id: 2,
          title: "Deal Experience & Complexity Score",
          image: dealExperience,
          categoriesWithPrice: [
            {
              category: "Point For Deal Contribution",
              point: "10 points per deal + (Deal value *0.0001)",
              description: "",
              subCategorylist: [
                { subcategory: "Full deal execution (end-to-end)", subcategoryDetails: "100% of points" },
                { subcategory: "Partial deal execution (e.g., financial modeling, investment memo, etc.)", subcategoryDetails: "50% of points" },
                { subcategory: "Minor involvement (e.g., due diligence, research, etc.)", subcategoryDetails: "25% of points" },
              ]
            },
            {
              category: "Deal Size Complexity Points",
              point: "Deal value *0.0002",
              description: "",
              subCategorylist: []
            },
            {
              category: "Minor Involvement (eg.,due diligence, research, etc.)",
              point: "25% of points",
              description: "(after validation)",
              subCategorylist: []
            },
          ]
        },
        {
          id: 3,
          title: "Market Knowledge Score",
          image: marketknowledge,
          categoriesWithPrice: [{
            category: "Anonymized Data Contribution (transaction details)",
            point: "50 points per contribution",
            description: "",
            subCategorylist: []
          },
          {
            category: "Anonymized Data Contribution (advisors, bidders, winners, multiples, valuation)",
            point: "20 points per contribution",
            description: "",
            subCategorylist: []
          },
          {
            category: "Platform Interactions",
            point: "1 point per contribution",
            description: "",
            subCategorylist: []
          },
          ]
        },
        {
          id: 4,
          title: "Networking & Endorsement Score",
          image: networking,
          categoriesWithPrice: [
            {
              category: "Connections",
              point: "2 points per connection",
              description: "",
              subCategorylist: []
            },
            {
              category: "Referrals",
              point: "10 points per referral",
              description: "",
              subCategorylist: []
            },
            {
              category: "Unofficial Referrers",
              point: "5 points per unofficial referrer",
              description: "",
              subCategorylist: []
            },
            {
              category: "Skill Endorsements",
              point: "2 points per endorsement",
              description: "(up to a maximum of 50 points)",
              subCategorylist: []
            },
          ]
        },
        {
          id: 6,
          title: "Community Engagement Score",
          image: community,
          categoriesWithPrice: [
            {
              category: "Asking Questions",
              point: "5 points per question",
              description: "",
              subCategorylist: []
            },
            {
              category: "Providing Answers",
              point: "5 points per answer + bonus points for detail and accuracy",
              subPoint: "(up to a maximum of 20 points per answer)",
              description: "",
              subCategorylist: []
            },
            {
              category: "Upvotes",
              point: "2 points per upvote received on questions or answers",
              description: "",
              subCategorylist: []
            },
            {
              category: "Accepted answers",
              point: "10 points per accepted answer",
              description: "",
              subCategorylist: []
            },
            {
              category: "Participation in Discussions",
              point: "1 point per comment or interaction",
              description: "",
              subCategorylist: []
            },
          ]
        },
        {
          id: 5,
          title: "Professional Development Score",
          image: professionalDev,
          categoriesWithPrice: [
            {
              category: "Course Completed",
              point: "10 points per course",
              description: "",
              subCategorylist: []
            },
            {
              category: "Webinars Attended",
              point: "Webinars Attended",
              description: "",
              subCategorylist: []
            },
            {
              category: "Relevant Certifications Obtained",
              point: "20 points per certification",
              description: "",
              subCategorylist: []
            },
            {
              category: "Verification",
              point: "Users must provide proof of completion, attendance, or certification",
              description: "",
              subCategorylist: []
            },
          ]
        },
        {
          id: 7,
          title: "Potential Score",
          image: potentialScore,
          categoriesWithPrice: [{
            category: "",
            point: "",
            description: "",
            subCategorylist: []
          }]
        },
      ],
      scoreData: [],
      rank: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    const webApiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (webApiRequestCallId === this.getScorecardsDetailApiid) {
      this.setState({
        scoreData: responseJson.data
      });
    }

    if (webApiRequestCallId === this.getProfileDetailsApiId) {
      this.setState({
        rank: responseJson.data.attributes.overall_rank,
        progress: responseJson.data.attributes.total_progress_percentage ?? "0"
      })
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getScoresDetails();
    this.getProfileDetails();
    this.setState({
      cardDetails: this.state.cardList[0]
    })
  }

  handleBackNavigation = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "JobApplicantProfile");
    msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(msg);
  }

  handleCardClick = (cardDetails: CardDetails) => {
    if (cardDetails.id === 7 || cardDetails.id === 5) {
      return
    }
    this.setState({
      cardDetails: cardDetails
    })
  }

  getScoresDetails = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const scoreCardsDetails = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getScorecardsDetailApiid = scoreCardsDetails.messageId;

    scoreCardsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfscorecalculations/scores/user_profile_progress`
    );

    scoreCardsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    scoreCardsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(scoreCardsDetails.id, scoreCardsDetails);
  }

  getProfileDetails = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("authToken")
    };

    const scoreCardsDetails = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileDetailsApiId = scoreCardsDetails.messageId;

    scoreCardsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles"
    );

    scoreCardsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    scoreCardsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(scoreCardsDetails.id, scoreCardsDetails);
  }

  getTotalScore = (name: string) => {
    return this.state.scoreData.find((obj) => obj.attributes.name.includes(name.substring(0, 5)))?.attributes.total_points ?? 0
  };

  getAbsoluteScore = (name: string) => {
    return this.state.scoreData.find((obj) => obj.attributes.name.includes(name.substring(0, 5)))?.attributes.absolute_score ?? 0
  };

  getPercentileScore = (name: string) => {
    return this.state.scoreData.find((obj) => obj.attributes.name.includes(name.substring(0, 5)))?.attributes.percentile_score ?? 0
  };
  
  // Customizable Area End
}
