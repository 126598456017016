export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const CardBackgroundImg = require("../assets/CardBackground.png");
export const userProfileImg = require("../assets/UserProofile.svg");
export const imageImg = require("../assets/imageIcon.svg");
export const openMenuicon = require("../assets/openMenuWhite.svg");
export const leftArrowicon = require("../assets/leftArrow.svg");
export const topRight = require("../assets/topRight.svg");
export const bottomLeft = require("../assets/bottomLeft.svg");
export const bottomRight = require("../assets/bottomRight.svg");
export const webPost = require("../assets/webPost.png");
export const Accenture = require("../assets/Accenture.png");
export const cardright =  require("../assets/cardright.png")
export const Up =  require("../assets/up.svg")
export const Down =  require("../assets/down.svg")
export const cardRightTop =  require("../assets/cardRightTop.png")
export const EditIcon =  require("../assets/editIcon.svg")
export const phoneIcon =  require("../assets/phoneIcon.svg")
export const locationIcon =  require("../assets/locationIcon.svg")
export const mailIcon =  require("../assets/mailIcon.svg")
export const birthDateIcon =  require("../assets/birthDateIcon.svg")
export const uploadIcon =  require("../assets/uploadIcon.svg")
export const fileIcon =  require("../assets/fileIcon.svg")
export const downloadIcon =  require("../assets/downloadIcon.svg")
export const crossIcon =  require("../assets/crossIcon.svg")
export const searchIcon =  require("../assets/searchIcon.svg")
export const tableClockIcon =  require("../assets/tableClockIcon.svg")
export const menuIcon =  require("../assets/menuIcon.svg")
export const columnArrow =  require("../assets/columnArrow.svg")
export const deleteIcon =  require("../assets/deleteIcon.svg")
export const tickIcon =  require("../assets/tickIcon.svg")
export const commentIcon = require("../assets/comment.svg");
export const dotIcon = require("../assets/dot.svg");
export const more = require("../assets/more.svg");
export const upIcon = require("../assets/up.svg");
export const save = require("../assets/save.svg");
export const saveBlue = require("../assets/saveblue.svg");
export const likedUpvote = require("../assets/likedUpvote.svg");
export const downreIcon = require("../assets/downred.svg");
export const down = require("../assets/down.svg");
export const minus = require("../assets/minus.svg");
export const plus = require("../assets/plus.svg");
export const gallary = require("../assets/gallary.svg");
export const remove = require("../assets/remove.svg");
export const showAll = require("../assets/showAll.svg");
export const backIcon = require("../assets/backIcon.svg");
export const buySideIcon = require("../assets/buySideIcon.svg");
export const sellSideIcon = require("../assets/sellSideIcon.svg");
export const careerDevelopmentIcon = require("../assets/careerDevelopmentIcon.svg");
export const add = require("../assets/add.svg");
export const report = require("../assets/report.svg");
export const message = require("../assets/message.svg");
export const connect = require("../assets/connect.svg");
export const personImage = require("../assets/personImage.svg");
export const acc = require("../assets/acc.svg");
export const tcs = require("../assets/tcs.svg");
export const cong = require("../assets/cong.svg");
export const err = require("../assets/err.svg");
export const cross = require("../assets/cross.svg");
export const downIcon = require("../assets/menuIcon.svg")
export const iconClose = require("../assets/cross.svg")
export const logoModal = require("../assets/logo.png")
export const chipCloseIcon = require("../assets/chip_cross.svg")
export const tick = require("../assets/tick.svg")
export const arrow = require("../assets/arrow.svg")
export const arrowDown = require("../assets/arrowDown.svg")
export const view_value = require("../assets/view_value.svg")
export const saveBlueOutlined = require("../assets/saveBlueOutlined.svg")
