import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        decline: {
            justifyContent: 'center',
            background: "#EEECE1",
            borderRadius: "4px",
            display: "flex",
            height: "24px",
            padding: "6px 8px",
            alignItems: "center",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        approve: {
            "display": "flex",
            "height": "28px",
            "padding": "6px 4px",
            "alignItems": "center",
            "borderRadius": "8px",
            "background": "#E6FAEB",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        open: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#C2FFD3",
            "fontSize": "12px",
            "padding": "2px 4px",
            "color": "#0D4F1C",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        close: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#FEE2E2",
            "fontSize": "12px",
            "padding": "2px 4px",
            "color": "#DC2626",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        applied: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#6D97C1",
            "fontSize": "12px",
            "padding": "2px 8px 2px 8px",
            "color": "#FFFFFF",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        draft: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#F4F4F4",
            "fontSize": "12px",
            "padding": "2px 4px",
            "color": "#9C9C9C",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        invited: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#6D97C133",
            "fontSize": "12px",
            "padding": "2px 4px",
            "fontWeight": "bold",
            "color": "#1F497D",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        applied2: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#FEF3C7",
            "fontSize": "12px",
            "padding": "2px 4px",
            "fontWeight": "bold",
            "color": "#D97706",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        interviewing: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#C2FFD3",
            "fontSize": "12px",
            "padding": "2px 4px",
            "fontWeight": "bold",
            "color": "#0D4F1C",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        rejected: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#FEE2E2",
            "fontSize": "12px",
            "padding": "2px 4px",
            "fontWeight": "bold",
            "color": "#DC2626",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        },
        review: {
            "display": "flex",
            "alignItems": "center",
            "borderRadius": "48px",
            "background": "#EEECE1",
            "fontSize": "12px",
            "padding": "2px 4px",
            "fontWeight": "bold",
            "color": "#948B59",
            "& .MuiChip-label": {
                textTransform: "none"
            }
        }
    }),
);

type ChipTyppe = "approve" | "decline" | "open" | "close" | "applied" | "draft" | "applied2" | "invited" | "interviewing" | "rejected" | "review" | string;

type Props = {
    title: string | React.ReactNode,
    icon?: string,
    type: ChipTyppe,
    showDeleicon?: boolean,
    style?: object
}

const CustomChipComponent = (props: Props) => {
    const classes = useStyles();
    const { title, icon = "", type, showDeleicon = true, style = {} } = props;
    const getClassname = (type: ChipTyppe) => {
        switch (type) {
            case "approve":
                return classes.approve;
            case "decline":
                return classes.decline;
            case "close":
                return classes.close;
            case "open":
                return classes.open;
            case "applied":
                return classes.applied;
            case "draft":
                return classes.draft;
            case "applied2":
                return classes.applied2;
            case "invited":
                return classes.invited;
            case "interviewing":
                return classes.interviewing;
            case "rejected":
                return classes.rejected;
            case "review":
                return classes.review;
            default:
                return classes.approve;

        }
    }
    return (
        <Chip
            className={getClassname(type)}
            size="small"
            label={title}
            deleteIcon={showDeleicon ? <img src={icon} alt="icon" /> : <></>}
            style={style}
        />
    )
}

export default CustomChipComponent