import React, { useState, useRef, useEffect } from "react";
import {
  ClickAwayListener,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  Button,
  Modal,
  Box,
  TextField,
  Divider
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {},
    btn: {
      minWidth: "auto",
      padding: 0,
    },
    menuitem: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#1F497D",
      fontFamily: "Arial",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    modalStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '546px',
      backgroundColor: '#FFF',
      border: '1px solid #F4F4F4',
      boxShadow: theme.shadows[5],
      borderRadius: '8px',
    },
        textField: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            width: '100%',
            border: '1px solid #CBD5E1',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '#CBD5E1',
              },
              '&:hover fieldset': {
                border: '#CBD5E1',
              },
              '&.Mui-focused fieldset': {
                border: '#CBD5E1',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#CBD5E1',
              },
            },
            '& .MuiOutlinedInput-input': {
              padding: '5px', 
              fontSize: '14px',
              color: '#64748B', 
            },
            '& .MuiInputBase-input::placeholder': {
              color: '#64748B',
              opacity: 1, 
              fontSize: '14px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#CBD5E1',
          },
    },
    
    modalActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: theme.spacing(3),
      gap:'20px'
      
    },
    sendButton :{
        fontFamily:'Arial',
        fontSize:'16px',
        padding: "10px 18px",
        width:'120px',
        background: "rgba(31, 73, 125, 1)",
        borderRadius: "8px",
        color: "#FFFFFF",
        fontWeight: "bold",
        alignSelf: "flex-end",
        textTransform: "none",
        '&:hover': {
          background: "rgba(31, 73, 125, 1)",
        },
    },
    cancelButton :{
        fontFamily:'Arial',
        background: "rgba(244, 244, 244, 1)",
        borderRadius: "8px",
        color: "rgba(31, 73, 125, 1)",
        width:'120px',
        fontSize:'16px',
        padding: "10px 18px",

        fontWeight: "bold",
        alignSelf: "flex-end",
        textTransform: "none",
        '&:hover': {
          background: "rgba(244, 244, 244, 1)",
        },
    }
  })
);

type CustomPopperProps = {
  
  testId?: string;
  rowId: string;
  onSendInvite: () => void;
  onSendMessage: () => void;
};

const CustomPopperRecruiter: React.FC<CustomPopperProps> = ({ rowId, onSendMessage, onSendInvite, testId }) => {
    const [modalOpen, setModalOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const classes = useStyles();
    const [open, setOpen] = useState(false);

    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }
      prevOpen.current = open;
    }, [open]);
  
    const handleClose = (event: React.MouseEvent<EventTarget>) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }
      setOpen(false);
    };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

 

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleSendInviteClick = () => {
    setModalOpen(true);
    setOpen(false);
  };

 

  return (
    <div data-test-id={testId}>
      <Button ref={anchorRef} onClick={handleToggle} className={classes.btn}>
        <MoreVertIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 10,
          background: "#FFF",
          border: "1px solid #F4F4F4",
          marginTop: "10px",
          borderRadius: "16px",
          width: "207px",
          height: "auto",
          padding: "2px 15px",
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: "center top", background: "#FFF" }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id={`menu-list-grow-${rowId}`}
                onKeyDown={handleListKeyDown}
                className={classes.popover}
              >
                <MenuItem className={classes.menuitem} onClick={() => alert('Candidate Saved!')}>Save Candidate</MenuItem>
                <MenuItem className={classes.menuitem} onClick={onSendMessage}>Send Message</MenuItem>
                <MenuItem className={classes.menuitem} onClick={handleSendInviteClick}>Send Invite</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>

      {/* Modal for Sending Invite */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="send-invite-modal-title"
        aria-describedby="send-invite-modal-description"
      >
        <Box className={classes.modalStyle}>
<Box style={{display:'flex', justifyContent:'flex-end', paddingTop:'15px', paddingRight:'15px', paddingLeft:'15px'}}>
<CloseIcon data-test-id="close-modal" onClick={handleModalClose} />
</Box>
<Box style={{paddingLeft:'40px', paddingRight:'40px'}}>
<TextField
  autoFocus
  margin="dense"
  id="note"
  placeholder="Add a note here..."
  type="text"
  fullWidth
  variant="outlined"
  className={classes.textField}
  multiline
  minRows={3} 
  maxRows={4}
  />
          </Box>
          <Divider style={{marginTop:'10px', marginBottom:"10px"}}/>
          <div className={classes.modalActions}>
            <Button onClick={handleModalClose} className={classes.cancelButton}>
              Cancel
            </Button>
            <Button onClick={() => { handleModalClose(); onSendInvite(); }} className={classes.sendButton}>
              Send
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CustomPopperRecruiter;
