import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";
import { debounce } from "@material-ui/core";
import Filteroptions from "../../filteritems/src/Filteroptions.web";
export const configJSON = require("./config");

export interface Root {
    candidates: CandidateDetails[]
    total_page: number
    current_page: number
}

export interface WorkExperience {
    id: number,
    type: string,
    attributes: {
        company_icon_url: string,
        position: string,
        specialization: string,
        started_time: string,
        ended_time: string,
        company_name: string,
        currently_working: boolean,
        end_current_position: boolean,
        created_at: string,
    }
}

export interface CandidateDetails {
    attributes: {
        account_details: {
            id: number
        },
        resume: {
            name: string,
            url: string
        },
        cover_letter: {
            name: string,
            url: string
        }
        id: number,
        pipeline_id: number,
        added_to_pipeline: boolean,
        photo: string,
        full_name: string,
        current_title: string,
        city: string,
        country: string,
        potential_score: string,
        relevance_score: string,
        percentile_score: string,
        overall_rank: string,
        skills: string[],
        work_experiences: {
            data: WorkExperience[]
        },
        applied_job_details: {
            applied_time_ago: string
        }
    }
}

export interface Daum {
    id: string
    type: string
    attributes: Attributes
}

export interface Attributes {
    job_title: string
    company_name: string
    city: string
    country: string
    disclose: boolean
    status: string
    created_at: string
}


// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface State {
    // Customizable Area Start
    candidateList: CandidateDetails[];
    totalCandidatesPage: number;
    currentPage: number;
    userRole: string;
    totalPagesJobFilter: number;
    searchField: string
    jobFilterData: Daum[]
    selectedCountry: string
    selectedCity: string
    selectedDate: string
    selectedSkill: string[]
    selectedExperiance: string[]
    selectedExperianceCheck: string[]
    selectedPostedBy: string
    disableButton: boolean
    openFilter: boolean
    coords : {
        latitude: number,
        longitude: number,
    },
    filterHeight: number

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class LandingPageTalentPoolController extends BlockComponent<Props, State, SS> {

    // Customizable Area Start
    candidatesProfileGetApiCallId: string = ""
    candidatesProfileNewPageGetApiCallId: string = ""
    addCandidateToPipelineApiCallId: string = ""
    removePipelineCandidateApiCallId: string = ""
    searchCandidateApiCallId: string = ""
    childRef: React.RefObject<Filteroptions> = React.createRef()

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            candidateList: [],
            totalCandidatesPage: 0,
            currentPage: 1,
            userRole: "",
            searchField: "",
            totalPagesJobFilter: 0,
            jobFilterData: [],
            selectedCountry: '',
            selectedDate: '',
            selectedExperiance: [],
            selectedExperianceCheck: [],
            selectedPostedBy: '',
            selectedSkill: [],
            selectedCity: '',
            disableButton: true,
            openFilter: false,
            coords : {
                latitude: 0,
                longitude: 0,
            },
            filterHeight: 380


            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
            switch (apiRequestCallId) {
                case this.candidatesProfileGetApiCallId:
                    return this.handelCandidateProfileData(responseJson);
                case this.addCandidateToPipelineApiCallId:
                    return this.handelAddCandidateResponse(responseJson);
                case this.removePipelineCandidateApiCallId:
                    return this.handelRemoveCandidateResponse(responseJson);

                default:
                    return null
            }
        }
    }

    async componentDidMount() {
        const userRole = await getStorageData("userRole")
        this.setState({ userRole })
        window.addEventListener("TALENT_POOL_LIST", this.getCurrentLocation as EventListener);
    }

    renderAppliedTime = (appliedJobDetails : {applied_time_ago : string}) => {
        if(appliedJobDetails) {
            return appliedJobDetails.applied_time_ago
        } else {
            return ""
        }
    }

    getCurrentLocation = async () => {
        let coords = await getStorageData("coords")
        if(coords) {
          coords = JSON.parse(coords)
          this.setState({
            coords: {
              latitude: coords.latitude,
              longitude: coords.longitude
            },
          });
        } 
        this.getCandidatesProfiles()
      }

    handelCandidateProfileData = (response: any) => {
        if (!response.errors && response?.candidates?.data) {
            this.setState({
                candidateList: response.current_page > 1 ? [...this.state.candidateList, ...response?.candidates?.data] : response?.candidates?.data,
                totalCandidatesPage: response.total_page || 1
            });

        } else if (response.error) {
            toast.error(response.error);
        }
    };

    handelAddCandidateResponse = (response: any) => {
        if (!response.errors && !response.error) {
            this.getCandidatesProfiles()
            toast.success(response.message)
        } else {
            toast.error(response.error || response.errors[0].message);
        }
    }

    ScrollDown = () => {
        if (this.state.totalCandidatesPage > this.state.currentPage) {
            this.getCandidatesProfiles()
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }

    handelRemoveCandidateResponse = (response: any) => {
        if (!response.errors && !response.error) {
            this.getCandidatesProfiles()
            toast.success(response.message)
        } else {
            toast.error(response.error || response.errors[0].message);
        }
    }

    handleSetSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchField: event.target.value, currentPage: 1 })
        this.getCandidatesProfiles()
    }

    setCityValue = (val: string) => {
        this.setState({ selectedCity: val })
    }

    setCountryvalue = (val: string) => {
        this.setState({ selectedCountry: val })
    }


    setDatevalue = (val: string) => {
        this.setState({ selectedDate: val })
    }

    setSkillValue = (val: string[]) => {
        this.setState({ selectedSkill: val })
    }

    setPostedByValue = (val: string) => {
        this.setState({ selectedPostedBy: val })
    }


    setButtonState = (val: boolean) => {
        this.setState({ disableButton: val })
    }

    setExperienceValue = (val: string[]) => {
        this.setState({ selectedExperiance: val })
    }


    setExperienceCheckValue = (val: string[]) => {
        this.setState({ selectedExperianceCheck: val })
    }

    setOpenFilter = (val: boolean) => {
        this.setState({ 
            openFilter: val,
            filterHeight: val ? 850 : 380
        })
    }

    handleToggle = () => {
        if (this.childRef.current) {
            this.childRef.current.handleToggle(!this.state.openFilter);
            this.setState({ openFilter: !this.state.openFilter })
        }
        this.passData()
    };

    passData = () => {
        if (this.childRef.current) {
            this.childRef.current.getPropsDataFromLandingPage(this.state.selectedCountry, this.state.selectedCity, this.state.selectedDate, this.state.selectedExperiance, this.state.selectedPostedBy, this.state.selectedSkill, this.state.disableButton, this.state.selectedExperianceCheck)
        }
    }

    handleThreeCondition = (condition: boolean, firstPart: string, secondPart: string) => {
        return condition ? firstPart : secondPart
    }

    handleAddClick = (candidate: CandidateDetails) => {
        return candidate.attributes.added_to_pipeline ? this.hidePipelineCandidate(candidate.attributes.pipeline_id) : this.addCandidateToPipeline(candidate.attributes.id)
    }

    handleTalentPoolNavigation = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'Talent');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    handleRecruiterNavigation = (recruiterId: number) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), recruiterId);
        this.send(message);
    }

    handleFilter = (type: "reset" | "apply") => {
        if (type === "apply") {
            this.getCandidatesProfiles()
        }
        this.setOpenFilter(false)
    }

    getCandidatesProfiles = debounce(async () => {
        const params = `country=${this.state.selectedCountry ?? ""}&city=${this.state.selectedCity ?? ""}&${this.state.selectedSkill.map(skill => `skills[]=${skill}`).join('&')}&${this.state.selectedExperiance.map(experience => `experience[]=${experience}`).join('&')}`
        let coordsUrl = ''
        if(this.state.coords.latitude && this.state.coords.longitude) {
            coordsUrl += `&latitude=${this.state.coords.latitude}&longitude=${this.state.coords.latitude}`
        }
        const authToken = await getStorageData("authToken")

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.candidatesProfileGetApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCandidateProfilesAPIEndPoint}?${params}&search_key=${this.state.searchField}&page=${this.state.currentPage}${coordsUrl}`
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    })

    async addCandidateToPipeline(candidateId: number) {
        const authToken = await getStorageData("authToken")

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addCandidateToPipelineApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.talentPipelineCandidateEndPoint}`
        );

        const header = {
            token: authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                candidate_id: candidateId
            })
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async hidePipelineCandidate(candidateId: number) {
        const authToken = await getStorageData("authToken")

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removePipelineCandidateApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.talentPipelineCandidateEndPoint}/${candidateId}`
        );

        const header = {
            token: authToken,
            "Content-Type": configJSON.validationApiContentType,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
