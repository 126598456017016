import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Platform
} from "react-native";
import { ToastContainer } from "react-toastify";
import { listIcon, topRight, bottomLeft, DoneStep, ActiveStep, DisabledStep, ConfirmationIcon, faliIcon } from "./assets";
import { Card, CardHeader, Typography, List, ListItem, ListItemIcon, ListItemText, Button, Grid, Stepper, Step, StepLabel, Tooltip } from '@material-ui/core';
import { makeStyles, styled } from "@material-ui/core/styles";
import PaymentDetails from "../../../../packages/components/src/PaymentDetails.web";

interface ListItemData {
  id: number;
  name: string;
  description: string;
  subscription_id: number;
}

interface MembershipCardProps {
  allPlansUnsubscribed: boolean;
  header:string;
  title: string;
  logo: string;
  price: string;
  details: string;
  listItems: ListItemData[];
  buttonText: string;
  role: string;
  onClickSubscribe: any;
  subId: string;
  subscribed: boolean;
  planDetails:{buttonTitle: string, isActive: boolean, tooltipTitle: string, background: string, isUpgrading: boolean};
}

interface PaymentConfirmationProps {
  title: string;
  details: string;
  price:string;
  onClickBack:any;
  success: boolean;
  navigateToHome: () => void;
  newPrice: string
}
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON
} from "./CustomisableusersubscriptionsController";

import { SubscriptionListView } from "./SubscriptionList";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={styles.mainContainer}>
        {this.state.stepActive === 'plans' && (
          <Grid style={{ background: '#F3F4F8', marginTop: '53px', maxWidth: "1180px", padding: "0px 20px" }} spacing={3} container>
            {this.state.subscriptions.slice(0, 3).map((plan, index) => (
              <Grid key={plan.id} item xs={12} sm={6} md={4} lg={4} container justifyContent="center">
                <MembershipCard
                  allPlansUnsubscribed={this.allPlansUnsubscribed()}
                  header={plan.attributes.subscription_type}
                  title={plan.attributes.name}
                  logo={plan.attributes.image_link}
                  price={plan.attributes.price}
                  details={plan.attributes.description}
                  listItems={plan.attributes.features}
                  buttonText={this.getButtonTitle(plan).buttonTitle}
                  role={this.state.role}
                  onClickSubscribe={this.onClickSubscribe}
                  subId={plan.id}
                  subscribed={plan.attributes.subscribed}
                  planDetails={this.getButtonTitle(plan)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        {this.state.stepActive === 'paymentDetails' &&
          <PaymentDetails
            title={this.state.membershipTitle}
            price={this.state.membershipPrice}
            details={this.state.membershipDetails}
            onClickProceed={this.onClickDetailsProceed}
            onClickBack={this.onClickDetailsBack}
            handleSubmit={this.handleSubmit}
            handleChangeCardField={this.handleChangeCardField}
            errorsPayment={this.state.errorsPayment}
            handleChangeHolderName={this.handleChangeHolderName}
            cardHolder={this.state.cardHolder}
            newPrice={this.state.newPrice}
          />}
        {this.state.stepActive === 'paymentConfirmation' &&
          <PaymentConfirmation
            title={this.state.membershipTitle}
            price={this.state.membershipPrice}
            details={this.state.membershipDetails}
            onClickBack={this.onClickConfirmationBack}
            success={this.state.success}
            navigateToHome={this.navigateToHome}
            newPrice={this.state.newPrice}
          />}
          <ToastContainer/>
      </div>
      // Customizable Area End
    );
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  mainContainer: { 
    display: 'flex', 
    justifyContent: 'center', 
    paddingBottom: "80px"
  },
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  myAdsText: {
    fontSize: 22,
    fontWeight: "600",
    marginLeft: 10
  },
  description: {
    fontSize: 14,
    fontWeight: "500",
    marginLeft: 15
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 20
  },
  leftHeaderView: {
    flexDirection: "row",
    alignItems: "center"
  },
  iconView: {
    paddingHorizontal: 5
  },
  backIcon: {
    width: 7,
    height: 14
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  }
  // Customizable Area End
});

// Customizable Area Start
export const MembershipCard: React.FC<MembershipCardProps> = ({ allPlansUnsubscribed, header,title, logo, price, details, listItems, buttonText, role, onClickSubscribe, subId, subscribed, planDetails }) => {
  const classes = PaymentDetailsStyles();

  const styles = {
      midContainer: {
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          flex: 1
        },
      card: {
          width: '100%',
          borderRadius:"8px",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        },
        cardHeader: {
          height: 44,
          padding:"0px",
          textAlign: 'center' as 'center',
          backgroundColor: '#6D97C1',
          color: "#fff",
          fontFamily: "Arial",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: 24,
        },
        membershipTitleDiv: {
          margin: "36px",
        },
        membershipTitle: {
          marginTop: "6px",
          fontFamily: "Arial",
          fontSize: 24,
          fontWeight: '400',
          letterSpacing: -0.005,
          color: "#1F497D"
        },
        priceStyle : { 
          fontSize: "48px", 
          fontWeight: 700, 
          fontFamily: "Arial" 
        },
        monthStyle: {
          fontSize: "18px", 
          fontWeight: 400, 
          fontFamily: "Arial"
        },
        contentDiv: {
          margin: "0px 36px",
          color: "#1F497D"
        },
        detailsDiv: {
          marginTop:"20px"
        },
        listDiv: {
          marginTop: "4px",
        },
        listItem: {
          padding: "1.5px",
          alignItems: "flex-start"
        },
        ListItemIcon: {
          minWidth: "36px",
          paddingTop: "7px"
        },
        listDescription: {
          '& .MuiTypography-displayBlock': {
            fontFamily: "Arial",
            fontWeight: "400",
            fontSize: "15px",
          }
        },      
        subscribeButton: {
          height: "44px",
          width: "100%",
          borderRadius: 8,
          padding: "10px 16px 10px 16px",
          backgroundColor: "#6D97C1",
          color: "#FFF",
          fontFamily: "Arial",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "24px",
          textTransform: 'none' as 'none',
        },
        subscribeButtonSelected: {
          height: "44px",
          width: "100%",
          borderRadius: 8,
          padding: "10px 16px 10px 16px",
          backgroundColor: "#34D399",
          color: "#FFF",
          fontFamily: "Arial",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "24px",
          textTransform: 'none' as 'none',
        },
        detailsText: {
          fontFamily: "Arial",
          fontWeight: "400",
          fontSize: 16,
          paragraph:"8px",
        },
        buttonSubtitle: {
          display: role === "recruiter" ? "none" : "block",
          color:"#1F497D",
          fontFamily: "Arial",
          fontWeight: "400",
          fontSize: 16,
          paragraph:"8px",
          marginTop:"22px"
        }
  };

  return (
      <Card id='membershipCard' style={styles.card as React.CSSProperties}>
          <CardHeader
              title={header}
              style={styles.cardHeader}
              disableTypography={true}
          />
          <div style={styles.membershipTitleDiv}>
              <img src={logo} height={56} width={56} />
              <Typography style={styles.membershipTitle}>{title}</Typography>
          </div>
        <div style={styles.midContainer as React.CSSProperties}>
        <div style={styles.contentDiv}>
              <Typography data-test-id="price-tag"><span style={styles.priceStyle}>${Math.floor(+price)}</span>
              <span style={styles.monthStyle}>
                /month
              </span>
              </Typography>
              <div style={styles.detailsDiv}>
                  <Typography style={styles.detailsText}>{details}</Typography>
              </div>
              <div style={styles.listDiv}>
                  <List>
                      {listItems.map((item, index) => (
                          <ListItem style={styles.listItem} key={index} disableGutters={true}>
                              <ListItemIcon style={styles.ListItemIcon}>
                                  <img src={listIcon} height={18} width={18} alt={`Icon ${item.id}`} />
                              </ListItemIcon>
                              <ListItemText className={classes.listDescription} primary={item.description} />
                          </ListItem>
                      ))}
                  </List>
              </div>
          </div>
          <div style={{ textAlign: "center", margin: "0px 36px 36px 36px" }}>
          <Tooltip title={planDetails.tooltipTitle} style={{ marginTop: "20px" }} placement="bottom" arrow>
            <span>
              <Button id='subscribeButton'
                disabled={planDetails.isActive}
                style={subscribed ? { ...styles.subscribeButtonSelected, background: planDetails.background } : { ...styles.subscribeButton, background: planDetails.background }}
                size="medium"
                onClick={() => { onClickSubscribe(title, details, price, subId, planDetails.buttonTitle === "Upgrade now") }}>
                {
                  subscribed && <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM8.00047 12.1701L13.8805 6.29006C14.2705 5.90006 14.9105 5.90006 15.3005 6.29006C15.6905 6.68006 15.6905 7.31006 15.3005 7.70006L8.71047 14.2901C8.32047 14.6801 7.69047 14.6801 7.30047 14.2901L4.71047 11.7001C4.32047 11.3101 4.32047 10.6801 4.71047 10.2901C4.8973 10.1028 5.15095 9.99757 5.41547 9.99757C5.67999 9.99757 5.93364 10.1028 6.12047 10.2901L8.00047 12.1701Z" fill="white" />
                  </svg>
                    &nbsp;
                    &nbsp;
                  </>
                }
                {buttonText}
              </Button>
            </span>
          </Tooltip>
              {allPlansUnsubscribed && <Typography style={styles.buttonSubtitle}>Cancel or Switch anytime</Typography>}
          </div>
        </div>
      </Card>
  );
};

const PaymentDetailsStyles = makeStyles((theme) => ({
  inputElement: {
    border: "1px solid #9C9C9C",
    marginTop: "5px",
    color: "#17365D",
    padding: "10px 8px",
    borderRadius: "8px",
    width: "100%",
    "&:focus": {
      border: "1px solid #9C9C9C",
    }
  },
  inputElementError: {
    border: "1px solid #DC2626",
    marginTop: "5px",
    color: "#17365D",
    padding: "10px 8px",
    borderRadius: "8px",
    width: "100%",
    "&:focus": {
      border: "1px solid #9C9C9C",
    }
  },
  errortext: {
    color: "#DC2626",
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px"
  },
  inputElementOne: {
    border: "1px solid #9C9C9C",
    marginTop: "5px",
    color: "#17365D",
    borderRadius: "8px",
    padding: "12.5px 8px",
    width: "105%",
    '&::placeholder': {
      color: '#9C9C9C',
      fontSize: '18px',
      fontFamily: "Arial",
      fontWeight: "lighter",
      lineHeight: "22px"
    },
    "&:focus": {
      border: "1px solid #9C9C9C",
      outline: "0px"
    },
    "@media (min-width: 960px) and (max-width: 1060px)": {
      width: "105%",
    },
    "@media (min-width: 1063px)": {
      width: "103.5%"
    },
    "@media (max-width: 960px)": {
      width: "102.5%"
    }
  },
  inputElementOneError: {
    border: "1px solid #DC2626",
    marginTop: "5px",
    color: "#17365D",
    borderRadius: "8px",
    padding: "12.5px 8px",
    width: "105%",
    '&::placeholder': {
      color: '#9C9C9C',
      fontSize: '18px',
      fontFamily: "Arial",
      fontWeight: "lighter",
      lineHeight: "22px"
    },
    "&:focus": {
      border: "1px solid #9C9C9C",
      outline: "0px"
    },
    "@media (min-width: 960px) and (max-width: 1060px)": {
      width: "105%",
    },
    "@media (min-width: 1063px)": {
      width: "103.5%"
    },
    "@media (max-width: 960px)": {
      width: "102.5%"
    }
  },
  labelText: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#1F497D"
  },
  page: {
    background: "#F3F4F8",
    padding: "50px 100px",
    maxWidth: "1280px"
  },
  form: {
    background: "#FFFFFF",
    width: "auto",
    margin: "0px",
    padding: "24px 40px 10px 40px",
    borderRadius: "16px",
    position: "relative" as "relative"
  },
  cardTitle: {
    "color": "#1F497D",
    "fontFamily": "Arial",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": 700,
    "lineHeight": "24px",
    "marginBottom": "20px"
  },
  membershipTitle: {
    "fontFamily": "Arial",
    "fontSize": "18px",
    "fontWeight": 700,
    "lineHeight": "26px",
    "textAlign": "left" as "left",
    "color": "#1F497D"
  },
  membershipDetailsDiv: {
    borderRadius: "8px",
    padding: "12px 16px 12px 16px",
    display: "flex",
    "boxShadow": "0px 2px 8px 0px #00000014",
    gap: '20px',
  },
  membershipInfo: {
    "fontFamily": "Arial",
    'fontSize': '14px',
    'fontWeight': 400,
    'lineHeight': '22px',
    'textAlign': 'left',
    'color': '#9C9C9C',
    'marginTop': '10px'
  },
  membershipPriceDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  membershipPrice: {
    fontFamily: 'Arial',
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: '44px',
    letterSpacing: '-0.01em',
    textAlign: 'right',
    color: '#1F497D',
  },
  formfieldCont: {
    width: "100%",
    fontFamily: "Arial",
    padding: "20px 0px 20px 0px",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "#D9D9D9",
      width: "6px",
      borderRadius: 16,
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 16,
      backgroundColor: "#6C96C1",
      border: "0px solid #6C96C1",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#6C96C1",

    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
      borderRadius: 16,
    },
  },
  bottomLeft: {
    position: "absolute",
    bottom: "0px",
    left: "0px"
  },
  topRight: {
    position: "absolute",
    top: "0px",
    right: "0px"
  },
  heading: {
    fontFamily: 'Arial',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    textAlign: 'left',
    color: '#1F497D',
    marginTop: '10px'
  },
  info: {
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#1F497D',
  },
  noBtn: {
    "color": "#17365D",
    "fontFamily": "Arial",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "lineHeight": "22px",
    "marginLeft": "20px",
    "&:hover": {
      background: "white"
    },
    "borderRadius": "8px",
    "border": "1px solid  #1F497D",
    "background": "#FFF",
    "width": "120px",
    "height": "56px",
    "padding": "10px 16px",
    "textTransform": "none"
  },
  yesBtn: {
    "border": "1px solid #C7D9F0",
    "color": "#FFFFFF",
    "fontFamily": "Arial",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "lineHeight": "22px",
    "marginLeft": "20px",
    "&:hover": {
      background: "#C7D9F0"
    },
    "borderRadius": "8px",
    "background": "#6D97C1",
    "display": "flex",
    "width": "120px",
    "height": "56px",
    "padding": "16px",
    "justifyContent": "center",
    "alignItems": "center",
    "gap": "8px",
    "textTransform": "none",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "5px",
    paddingTop: "25px !important",
    borderTop: "1px solid #F4F4F4"
  },
  listDescription: {
    '& .MuiTypography-displayBlock': {
      fontFamily: "Arial",
      fontWeight: "400",
      fontSize: "15px",
    }
  },
  }));

const PaymentConfirmationStyles = makeStyles((theme) => ({
  page: {
    background: "#F3F4F8",
    padding: "50px 100px",
    maxWidth: "1280px"
  },
  form: {
    background: "#FFFFFF",
    width: "auto",
    margin: "0px",
    padding: "24px 40px 10px 40px",
    borderRadius: "16px",
    position: "relative" as "relative"
  },
  membershipTitle: {
    "fontFamily": "Arial",
    "fontSize": "18px",
    "fontWeight": 700,
    "lineHeight": "26px",
    "textAlign": "left" as "left",
    "color": "#1F497D"
  },
  membershipDetailsDiv: {
    borderRadius: "8px",
    padding: "12px 16px 12px 16px",
    margin: '12px 16px 12px 16px',
    display: "flex",
    "boxShadow": "0px 2px 8px 0px #00000014",
    gap: '20px'
  },
  membershipInfo: {
    "fontFamily": "Arial",
    'fontSize': '14px',
    'fontWeight': 400,
    'lineHeight': '22px',
    'textAlign': 'left',
    'color': '#9C9C9C',
    'marginTop': '10px'
  },
  membershipPriceDiv: {
    display: 'flex',
    alignItems: 'center',
    
  },
  membershipPrice: {
    fontFamily: 'Arial',
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: '44px',
    letterSpacing: '-0.01em',
    textAlign: 'right',
    color: '#1F497D',
  },
  bottomLeft: {
    position: "absolute",
    bottom: "0px",
    left: "0px"
  },
  topRight: {
    position: "absolute",
    top: "0px",
    right: "0px"
  },
  heading: {
    fontFamily: 'Arial',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    textAlign: 'left',
    color: '#1F497D',
    marginTop: '10px'
  },
  noBtn1: {
    "color": "#1F497D",
    "fontFamily": "Arial",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "lineHeight": "22px",
    "marginLeft": "20px",
    "&:hover": {
      background: "white"
    },
    "borderRadius": "8px",
    "border": "1px solid  #1F497D",
    "background": "#FFF",
    "width": "120px",
    "height": "56px",
    "padding": "10px 16px",
    "textTransform": "none"
  },
  yesBtn1: {
    "border": "1px solid #C7D9F0",
    "color": "#FFFFFF",
    "fontFamily": "Arial",
    "fontSize": "16px",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "lineHeight": "22px",
    "marginLeft": "20px",
    "&:hover": {
      background: "#C7D9F0"
    },
    "borderRadius": "8px",
    "background": "#6D97C1",
    "display": "flex",
    "minWidth": "120px",
    "height": "56px",
    "padding": "16px",
    "justifyContent": "center",
    "alignItems": "center",
    "gap": "8px",
    "textTransform": "none",
  },
  btnContainer1: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "5px",
    paddingTop: "25px !important",
    borderTop: "1px solid #F4F4F4"
  },
  container1: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px'
  },
  text1: {
  fontFamily:'Arial',
  fontSize:'16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#17365D',
  textAlign: 'center'
  },
  confirmationDiv: {
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
    marginTop: '40px',
    maxWidth: '713px'
  }
}));

export const PaymentConfirmation: React.FC<PaymentConfirmationProps> = ({ title, price, details, onClickBack, success, navigateToHome, newPrice }) => {
  const classes = PaymentConfirmationStyles();

  return (
    <Grid container className={classes.page}>
      <Grid container className={classes.form}>
        <img src={topRight} alt="icons" className={classes.topRight} />
        <img src={bottomLeft} alt="icons" className={classes.bottomLeft} />
        <Grid item xs={12}>
          <Typography className={classes.heading}>Confirmation</Typography>
        </Grid>
        <Grid style={{ overflow: 'hidden' }} item xs={12}>
          <PaymentStepsConfirmation activeStep={2} />
        </Grid>
        <Grid style={{display:'flex',justifyContent:'center',marginBottom:'30px'}}  item xs={12}>
          <div className={classes.confirmationDiv}>
            <div className={classes.container1}>
              <img src={success ? ConfirmationIcon : faliIcon} alt="icons" height={34.4} width={34.4} />
            </div>
            <div className={classes.container1}>
              {
                success ? <Typography className={classes.text1}>Your payment has been successfully <br /> done for the below plan.</Typography>
                  : <Typography className={classes.text1}>Your payment has been failed for the below  <br /> plan. Please try again.</Typography> 
             }
            </div>
            <div className={classes.membershipDetailsDiv}>
              <div >
                <Typography className={classes.membershipTitle}>{title}</Typography>
                <Typography className={classes.membershipInfo}>{details}</Typography>
              </div>
              <div className={classes.membershipPriceDiv}>
                <Typography className={classes.membershipPrice}>${newPrice === "" ?  Math.floor(+price) : Math.floor(+newPrice)}<span style={{ fontSize: '18px', fontWeight: 400, lineHeight: '26px' }}>/month</span></Typography>
              </div>
            </div>
            <div className={classes.container1}>
              {
                success ? <Button className={classes.yesBtn1} style={{ marginBottom: '15px' }}  onClick={navigateToHome}>Explore the benefits now</Button>
              : <Button className={classes.yesBtn1} style={{ marginBottom: '15px' }}  onClick={onClickBack}>Try Again</Button>
              }
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
interface PaymentStepsProps {
  activeStep: number;
}

const StyledStepper = styled(Stepper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: '0 0 0 0'
  },
  margin:'0 -150px 0 -150px',
  '& .MuiStepConnector-alternativeLabel': {
    top: 11,
    left: 'calc(-50% + 7px)',
    right: 'calc(50% + 7px)',
  },
  '& .MuiStepConnector-line': {
  borderTopWidth: '4px',
  borderColor: '#D9D9D9',
  },
  '& .MuiStepConnector-active': {
  '& .MuiStepConnector-line': {
  borderColor: '#1F497D',
  },
  },
  '& .MuiStepConnector-completed': {
  '& .MuiStepConnector-line': {
  borderColor: '#1F497D',
  },
  },
  '& .MuiStepLabel-iconContainer': {
  marginTop: '4px'
  },
  '& .MuiStepLabel-completed': {
  color: '#17365D',
  fontFamily: 'Arial',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  textAlign: 'center'
  },
  '& .MuiStepLabel-active': {
  color: '#17365D',
  fontFamily: 'Arial',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  textAlign: 'center'
  },
  '& .MuiStepLabel-label': {
  fontFamily: 'Arial',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  textAlign: 'center'
  },
}));

export const StepDoneIcon: React.FC = () => {
  return (
    <img src={DoneStep} alt="Step Done" style={{zIndex:1}} height={16} width={16} />
  );
}

export const StepActiveIcon: React.FC = () => {
  return (
    <img src={ActiveStep} alt="Step Active" style={{zIndex:1}} height={16} width={16} />
  );
}

export const StepDisabledIcon: React.FC = () => {
  return (
    <img src={DisabledStep} alt="Step Disabled" style={{zIndex:1}} height={16} width={16} />
  );
}

export const PaymentSteps: React.FC<PaymentStepsProps> = ({ activeStep }) => {
  return (
    <StyledStepper alternativeLabel activeStep={activeStep}>
      <Step>
        <StepLabel StepIconComponent={StepDoneIcon}>Plan Selection</StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepActiveIcon}>Payment</StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepDisabledIcon}>Confirmation</StepLabel>
      </Step>
    </StyledStepper>
  );
}

export const PaymentStepsConfirmation: React.FC<PaymentStepsProps> = ({ activeStep }) => {
  return (
    <StyledStepper alternativeLabel activeStep={activeStep}>
      <Step>
        <StepLabel StepIconComponent={StepDoneIcon}>Plan Selection</StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepDoneIcon}>Payment</StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={StepActiveIcon}>Confirmation</StepLabel>
      </Step>
    </StyledStepper>
  );
}

// Customizable Area End
