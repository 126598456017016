import { Box, Chip, IconButton, Popper, TextField, Typography, styled } from "@material-ui/core";
import React, { Component } from "react";
import { UserDetails } from "../RecruiterProfileController.web";
import { bottomRight, searchIcon, chipCloseIcon } from "../assets";
import { Autocomplete } from "@material-ui/lab";

interface SkillsProps {
    userDetails: UserDetails;
    skillsList: string[];
    selectedSkillToRemove: string;
    onInputChange: any;
    onChange: any;
    handleDeleteSkill: (skill: string[]) => void
    handleCondition: (condition: boolean, truePart: number, falsePart: number) => number;
  }

export default class Skills extends Component<SkillsProps> {
    constructor(props: SkillsProps) {
        super(props);
    }
    render() {
        return (
            <Box style={{ ...styles.item, position: 'relative', paddingBottom: '26px', minHeight: '100px' }}>
                <Typography >
                    <span style={styles.blockHeading}>
                        Skill Details
                    </span>
                    <span style={styles.personalDetailsPercentage}>
                        {' '}<span style={{ fontWeight: '400', marginLeft: '10px', color: 'grey' }}>
                            {this.props.handleCondition(this.props.userDetails.skills.length > 30, 30, this.props.userDetails.skills.length)}
                            </span>/30
                    </span>
                </Typography>
                <img src={bottomRight} alt="icons" style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "0px"
                }} />
                <Box
                    style={styles.skillsInput}
                >
                    <Autocomplete
                        data-test-id="skills-input"
                        clearOnBlur={true}
                        PopperComponent={CustomPopper}
                        freeSolo={true}
                        options={this.props.skillsList}
                        getOptionLabel={(option) => option}
                        value={''}
                        inputValue={this.props.selectedSkillToRemove}
                        onInputChange={this.props.onInputChange}
                        onChange={this.props.onChange}
                        renderInput={(params) => (
                            <CustomTextField
                                data-test-id="skills-input2"
                                {...params}
                                value={''}
                                placeholder="Enter here to add skills"
                                style={{
                                    color: '#6D97C1',
                                    fontWeight: "lighter",
                                    width: '165px',
                                    fontSize: '14px'
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    classes: { underline: 'underline' },
                                    endAdornment: null
                                }}
                            />
                        )}
                    />
                    <IconButton type="submit" aria-label="search">
                        <img src={searchIcon} alt="icon" />
                    </IconButton>
                </Box>
                {this.props.userDetails.skills?.length > 0 ?
                    this.props.userDetails.skills.map((skill, index) => {
                        return <Chip
                            data-test-id="skillChip"
                            key={index + 1}
                            style={styles.skillsChip}
                            label={skill}
                            onDelete={() => this.props.handleDeleteSkill(skill)}
                            deleteIcon={
                                <img
                                    src={chipCloseIcon}
                                    alt="cross chip"
                                    style={{ width: "16px", height: "16px" }}
                                />
                            }
                        />
                    }) : null
                }
            </Box>
        );
    }
}

const CustomTextField = styled(TextField)(() => ({
    '& .MuiInputBase-input::placeholder': {
      color: '#CBD5E1',
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Arial',
    },
    '& .MuiInputBase-root': {
      color: '#64748B',
      paddingRight: '0px',
      fontSize: '14px',
      fontWeight: '700',
      fontFamily: 'Arial',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    }
  }));

const CustomPopper = styled(Popper)({
    '& .MuiPaper-root': {
      borderRadius: '16px',
      color: '#17365D',
      fontSize: '12px',
      border: '1px solid #CBD5E1'
    },
  });

const styles = {
    root: {
        padding: "10px 40px",
        paddingBottom: "80px",
        backgroundColor: '#F3F4F8',
    },
    item: {
        background: "#ffffff",
        borderRadius: '20px',
        padding: '20px',
        minHeight: '200px'
    },
    blockHeading: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#17365D'
    },
    personalDetailsPercentage: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '18px',
        paragraph: '8px',
        color: '#17365D'
    },
    bottomRight: {
        position: "absolute",
        bottom: "0px",
        right: "0px"
    },
    skillsInput: {
        borderRadius: '8px',
        border: '1px solid #F4F4F4',
        padding: '6.5px 8px',
        paddingRight: '0',
        height: '18px',
        width: '198px',
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0'
    },
    skillsChip: {
        height: '24px',
        borderRadius: '4px',
        padding: '6px 12px 6px 8px',
        backgroundColor: '#EEECE1',
        color: '#1F497D',
        margin: '0 13px 13px 0',
        fontSize: '12px',
        fontFamily: 'Arial'
    },
}