// Customizable Area Start
import React from "react";
import ProfilePageForRecruiterController, {
  Props
} from "./ProfilePageForRecruiterController.web";
import { Box, Drawer, Grid, styled, Typography } from "@material-ui/core";
import ShowAllPosts from "./components/ShowAllPosts.web";
import { backIcon } from "./assets";
import ConfirmDialog from "../../../components/src/ConfirmDialog.web";
import { ToastContainer } from "react-toastify";
import ChatBotFormRecruiter from "./components/ChatBotFormRecruiter.web";
import CreateFeedPost from "../../../blocks/landingpage/src/components/CreateFeedPost.web";
import InvitationDialog from "../../../components/src/InvitationDialog.web";
import ProfileInfoCard from "./components/ProfileInforCard.web";
import PersonalDetails from "./components/PersonalDetail.web";
import CompanyDetails from "./components/CompanyDetails.web";
import AllActivity from "./components/AllActivity.web";
// Customizable Area End
export default class ProfilePageForRecruiter extends ProfilePageForRecruiterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ProfileContainer style={webstyle.mainContainer} id="pageContainer">
        <Grid container spacing={2} style={{ ...webstyle.root, margin: '0px -8px', maxWidth: '1280px' }} className="mainGrid">
          <Box style={{ display: 'flex', margin: '0px -8px', width: '100%' }} className="profileWrapper">
            <ProfileInfoCard data-test-id="profile_info-card" toggleDrawerRecruiter={this.toggleDrawerRecruiter} getInitials={this.getInitials} userDetails={this.state.userDetails} handleDirectApproachChange={this.handleDirectApproachChange}
              handleUpdateProfile={this.handleUpdateProfile} />
            {!this.state.showAllPosts ?
              <Box className="personalDetailWrapper" style={{ padding: '0 8px', width: "100%" }} >
                <Grid className="rightBlock" item container spacing={2}>
                  <Grid className="personalDetailsBlock" item sm={12} >
                    <PersonalDetails data-test-id="personal-details" userDetails={this.state.userDetails}
                      handleUpdateProfile={this.handleUpdateProfile} handleUpdateBio={this.handleUpdateBio}
                    />
                  </Grid>
                  <Grid item sm={12} className="allActivityWrapper">
                    <AllActivity data-test-id="all-activity" activityType={this.state.activityType}
                      handleUpdateActivityType={this.handleUpdateActivityType}
                      updateComment={this.updateComment}
                      handleDeleteComment={this.handleDeleteComment}
                      activity={this.state.activity}
                      upvotePost={this.upvotePost}
                      downvotePost={this.downvotePost}
                      toggleComments={this.toggleComments} postShowID={this.state.postShowID}
                      createComment={this.createComment} upvotePostComment={this.upvotePostComment}
                      downvotePostComment={this.downvotePostComment}
                      userDetails={this.state.userDetails} handleFeedModalOpen={this.handleFeedModalOpen}
                      handleSavePostApi={this.handleSavePostApi} getInitials={this.getInitials}
                      handleShowAllPosts={this.handleShowAllPosts}
                      openConfirmDialog={this.handlePostMenu}
                      progressComment={this.state.progressComment}
                      expandedPosts={this.state.expandedPosts}
                      remainingTimeComment={this.state.remainingTimeComment}
                      loaderComment={this.state.loaderComment}
                      toggleReadMore={this.toggleReadMore}
                      handleUserNavigation={this.handleUserNavigation}
                    />
                  </Grid>
                  <Grid item sm={12} className="fullWidth" >
                    <CompanyDetails handleCompanyProfile={this.handleCompanyProfile} companyDetails={this.state.companyDetails} />
                  </Grid>
                </Grid>
              </Box>
              :
              <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', width: '100%' }}>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginBottom: '20px' }}>
                  <img
                    src={backIcon} style={{ width: '6.59px', height: '11.17px', marginRight: '10px', cursor: 'pointer' }}
                    onClick={this.handleShowAllPosts} />
                  <Typography style={{ fontFamily: 'Arial', fontSize: '14px', fontWeight: '700', color: '#17365D' }}>
                    All {this.state.activityType}
                  </Typography>
                </Box>
                <ShowAllPosts
                  activity={this.state.activity}
                  upvotePost={this.upvotePost}
                  downvotePost={this.downvotePost}
                  activityType={this.state.activityType}
                  toggleComments={this.toggleComments}
                  postShowID={this.state.postShowID}
                  createComment={this.createComment}
                  upvotePostComment={this.upvotePostComment}
                  downvotePostComment={this.downvotePostComment}
                  openPostDeleteDialog={this.openPostDeleteDialog}
                  userDetails={this.state.userDetails}
                  handleFeedModalOpen={this.handleFeedModalOpen}
                  handleSavePostApi={this.handleSavePostApi}
                  getInitials={this.getInitials}
                  progressComment={this.state.progressComment}
                  remainingTimeComment={this.state.remainingTimeComment}
                  loaderComment={this.state.loaderComment}
                  handleDeleteComment={this.handleDeleteComment}
                  toggleReadMore={this.toggleReadMore}
                  updateComment={this.updateComment}
                  expandedPosts={this.state.expandedPosts}
                  handleUserNavigation={this.handleUserNavigation}
                />
              </Box>
            }
          </Box>
        </Grid >
        <ToastContainer />
        {
          this.state.openEditFeedPost &&
          <CreateFeedPost
            postDescription={this.state.currentPost.description}
            postContentHandler={this.postContentHandler}
            handleDeleteImage={this.handleDeleteImage}
            handleStateUpdate={this.handleStateUpdate}
            images={this.state.currentPost.images}
            handleImageChange={this.handleFeedImageChange}
            loading={this.state.loading}
            selectedTopics={this.state.currentPost.selectedTopicId}
            selectTopics={this.state.selectTopicsList}
            open={this.state.openEditFeedPost}
            selectTopicsValue={this.selectTopicsValue}
            onClose={this.handleFeedModalOpen}
            createFeedPostApi={this.createFeedPostApi}
            userProfileName={this.state.userDetails.full_name ?? ''}
            isPostImageUploaded={this.state.isPostImageUploaded} loader={this.state.loading} progress={0} remainingTime={0}
            profileImage={this.state.userDetails.photo}
          />
        }
        <InvitationDialog
          data-test-id="invitationDialog"
          open={this.state.openInvitationDialog.open}
          handleClose={this.handleInvitationClose}
          handleSendInvitation={this.handleInvite}
          questionOne={"Add a note to your invitation?"}
          questionTwo={`Personalize your invitation ${this.getUserName()} by adding a note. Members are more likely to accept invitations that include a note.`}
        />
        <ConfirmDialog
          data-test-id="confirmDialog"
          open={this.state.openConfirmDialogNew.open}
          handleClose={this.handleClose}
          hadndleDelete={this.state.openConfirmDialogNew.onConfirm}
          questionOne={this.state.openConfirmDialogNew.title1}
          questionTwo={this.state.openConfirmDialogNew.title2}
        />
          <Drawer style={{width: "50%"}} anchor="right" open={this.state.completeProfileModalRecruiter} onClose={() => this.toggleDrawerRecruiter(false)}>
            <div style={{maxWidth: "35vw", overflowX: "hidden"}}>
              <ChatBotFormRecruiter
                handleChangeChatBotField={this.handleChangeChatBotField}
                handleCitiesList={this.handleCitiesList}
                state={this.state}
                companyListHandler={this.companyListHandler}
                handleCloseModal={this.toggleDrawerRecruiter}
                updateUserDetailsFromChat={this.updateUserDetailsFromChat}
                validateOTP={this.validateOTP}
                handleKeyDown={this.handleKeyDownRecruiterBot}
                sendOTPonMail={this.sendOTPonMail}
              />
            </div>
        </Drawer>
        <ToastContainer/>
      </ProfileContainer >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
// Customizable Area Start
const ProfileContainer = styled(Box)({
  "&::-webkit-scrollbar": {
    width: "16px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#D9D9D9",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1F497D",
    width: "16px",
    borderRadius: "50px",
    height: "367px !important",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#1F497D",
  },
  '@media (max-width: 1024px)': {
    width: '100%',
  },
  '& .mainGrid': {
    '@media (max-width: 767px)': {
      padding: '10px 20px !important',
    },
  },
  '& .profileWrapper': {
    '@media (max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  '& .profileLeft': {
    '@media (max-width: 1200px)': {
      flex: '0 0 25% !important',
      maxWidth: '25% !important',
    },
    '@media (max-width: 767px)': {
      flex: '0 0 10% !important',
      width: '100% !important',
      maxWidth: '100% !important'
    },
    '& .profileBtn': {
      '& .MuiButtonBase-root': {
        '@media (min-width: 767px) and (max-width: 880px)': {
          height: '52px !important'
        },
        '@media (min-width: 1200px) and (max-width: 1260px)': {
          height: '52px !important'
        },
      }
    }
  },
  '& .resumeWrapper': {
    '@media (max-width: 1200px)': {
      width: '100%',
      maxWidth: '100%',
    }
  },
  '& .progressPercentageWrapper': {
    '@media (min-width: 767px) and (max-width: 960px)': {
      flexDirection: 'column',
      '& .percentageBar': {
        marginBottom: '15px'
      }
    }
  },
  '& .fileNameShort': {
    '@media (max-width: 1600px)': {
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '@media (min-width: 1200px)': {
      maxWidth: '135px'
    },
    '@media (max-width: 480px)': {
      maxWidth: '260px'
    },
    '@media (max-width: 320px)': {
      maxWidth: '130px'
    }
  },
  '& .downloadIcon': {
    '@media (max-width: 1200px)': {
      marginRight: '0px !important',
      margin: '5px'
    }
  },
  '& .rightBlock': {
    '@media (max-width : 1200px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  '& .personalDetailsBlock': {
    '@media (max-width : 1200px)': {
      width: '100% !important',
      maxWidth: '100% !important',
    }
  },

  '& .personalDetailWrapper': {
    '@media (max-width : 1200px)': {
      width: '100%',
      flex: ' 0 0 75%',
      maxWidth: '75%',
    },
    '@media (max-width: 767px)': {
      flex: '0 0 100% !important',
      width: '100% !important',
      maxWidth: '100% !important'
    },
  },

  '& .allActivityWrapper': {
    '@media (max-width: 620px)': {
      width: '100%',
      maxWidth: '100%',
      '& .allActivityInnerBox': {
        padding: '5px !important'
      }
    }
  },

  '& .fullWidth': {
    '@media (max-width : 600px)': {
      width: '100% !important',
      maxWidth: '100% !important',
    }
  },

  '& .personalIconsWrapper': {
    '@media (max-width: 620px)': {
      flexDirection: 'column'
    }
  }

})
const webstyle = {
  noStyle: { textAlign: "center" as "center", textTransform: "capitalize" as "capitalize", 
  marginTop: "4px" },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    padding: "10px 40px",
    backgroundColor: '#F3F4F8',
  },
  item: {
    background: "#ffffff",
    borderRadius: '20px',
    padding: '20px',
    minHeight: '160px'
  },
  progressHeading: {
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '12px',
    color: '#17365D',
    lineHeight: '18px',
  },
  progressCaption: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '11.5px',
    color: '#17365D',
  },
  personalDetailsTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'fit-content',
    marginBottom: '15px'
  },
  blockHeading1: {
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#17365D'
  },
  personalDetailsPercent: {
    fontFamily: 'Arial',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
    paragraph: '8px',
    color: '#17365D'
  },
  editIconStyle: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#1F497D'
  },
  personalDetailItem: {
    flex: "1 1 50%",
    marginBottom: '15px',
    cursor: 'default'
  },
  editIcon: {
    height: '20px',
    marginRight: '10px'
  },
  upcomingPlan: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '8px',
  },
  bottomRight: {
    position: "absolute",
    bottom: "0px",
    right: "0px"
  },
  topRight: {
    position: "absolute" as "absolute",
    top: "0px",
    right: "0px"
  },
  uploadButton: {
    borderRadius: '8px',
    border: '1px solid #FFFFFF',
    padding: '6px 10px',
    backgroundColor: '#EEECE1',
    textTransform: 'none',
    boxShadow: '0px 4px 4px 0px #0000001A',
  },
  supportedFileText: {
    fontFamily: 'Arial',
    fontWeight: 400,
    fontSize: '8px',
    lineHeight: '18px',
    color: '#9C9C9C',
    marginLeft: '8px'
  },
  uploadedFile: {
    borderRadius: '8px',
    border: '1px solid #9C9C9C',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  activityButton: {
    height: "24px",
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Arial',
    color: '#17365D',
    borderRadius: '20px',
    padding: '6px 10px 6px 10px',
    backgroundColor: '#C7D9F0',
    margin: '10px'
  },
  inactiveButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #6D97C1',
    fontSize: '14px',
    fontWeight: '400',
    color: "#9C9C9C"
  },
  icon: {
    display: 'none',
  },
  companyText: {
    marginRight: '10px',
    marginLeft: '4px',
    fontSize: "14px",
    fontFamily: "Arial",
    color: "#1F497D"
  },
  editIconImage: {
    width: '25px',
    height: "25px",
    cursor: 'pointer',
  },
  editIconSpan: {
    display: 'inline-flex',
    width: "80%",
    alignItems: 'center',
    justifyContent: 'center',
  },
  companyDetailsBox: {
    width: "100%",
    marginTop: "8px"
  },
  companyDetailsText: {
    color: "#9C9C9C",
    fontSize: "15px",
    fontWeight: 400,
    fontFamily: "Arial"
  },
  websiteText: {
    color: "#17365D",
    fontSize: "12px",
    fontFamily: "Arial",
    fontWeight: 700
  },
  websiteLink: {
    color: "#538DD3",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Arial"
  },
  industryText: {
    color: "#9C9C9C",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Arial"
  },
  workEmailBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "10px"
  },
  workEmailText: {
    color: "#9C9C9C",
    fontSize: "8px",
    fontWeight: 400,
    fontFamily: "Arial"
  },
  workEmailText1: {
    color: "#17365D",
    fontSize: "8px",
    fontWeight: 400,
    fontFamily: "Arial",
    marginTop: "2px"
  },
  verifiedText: {
    color: "#6D97C1",
    fontSize: "8px",
    fontWeight: 400,
    fontFamily: "Arial"
  },
  openBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: "10px",
    marginBottom: '10px'
  },
  openText: {
    fontFamily: "Arial",
    fontSize: '8px',
    fontWeight: '400',
    color: '#17365D',
  },
  completeProfile: {
    backgroundColor: '#1F497D',
    width: "100%",
    marginTop: '15px',
    height: '34px',
    boxShadow: "0px 0px 17px 0px #11000040",
    fontFamily: "Arial",

  },
  recruiterBox: {
    background: "#C7D9F0",
    borderRadius: "0px 50px 50px 0px",
    marginTop: "10px",
    padding: "10px 10px 10px 10px",
  },
  recruiterText: {
    color: "#1F497D",
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Arial",
    paddingLeft: "14px"
  },
  LeaderShipBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "4px"
  },
  LeadershipText: {
    color: "#1F497D",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Arial",
    paddingLeft: '14px'
  },
  arrowImg1: {
    height: "15px",
    width: "15px",
    paddingRight: "10px"
  },
  hr: {
    borderWidth: "1px 0px  1px  0px",
    borderStyle: "solid",
    borderColor: "#C7D9F0"


  },
  showAllPostActivityBox: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '10px'
  },
  showAllActivityText1: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  showAllActivityText: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: '14px',
    color: '#1F497D',
    paddingRight: '10px'
  },
}
// Customizable Area End