import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from "@material-ui/core";
import Select, { StylesConfig, ValueType } from 'react-select';

type OptionType = {
    label: string;
    value: string;
};

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        borderRadius: "50%",
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "white",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto #64748B",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#EEECE1",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#137cbd",
        backgroundImage: "linear-gradient(180deg,#1F497D,#1F497D)",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#9C9C9C",
        },
    },
    headerTitle: {
        color: "#1F497D",
        fontFamily: "Arial",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "18px",
        marginBottom: "8px",
    },
    titleOfRadio: {
        fontFamily: "Arial",
        fontWeight: "lighter",
        color: "#1F497D",
        fontSize: "12px",
    },
    selectContainer: {
        position: 'relative',
    },
    selectField: {
        borderRadius: "8px",
        padding: "5px 10px",
        fontFamily: "Arial",
        fontSize: "12px",
        borderColor:'#EEECE1',
        color: "#EEECE1",
        "&:focus": {
            borderColor: "#EEECE1",
            boxShadow: "0 0 5px rgba(31, 73, 125, 0.5)",
        },
    },
    selectMenu: {
        position: 'absolute',
        zIndex: 9999,
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        marginTop: "2px",
    },
    placeholder: {
        color: "#1F497D",
        fontSize: "12px",
        fontWeight: "lighter",
    },
    menu: (provided) => ({
        ...provided,
        position: 'absolute', 
        zIndex: 9999, 
        marginTop: 0, 
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'visible', 
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        overflowY: 'visible', 
        overflowX: 'hidden',
    }),
});

const customStyles: StylesConfig<OptionType, false> = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        width:'200px',
        height:'29px',
        minHeight:'none',
        justifyContent:'center',
        alignContent:'center',
        boxShadow: 'none',
        border: '1px solid rgba(156, 156, 156, 1)',
     padding:'10px, 8px, 10px, 8px',
     
     '&:hover': {
      border: '1px solid rgba(156, 156, 156, 1)',
  
     }
      }),
      option: (provided, state) => ({
        ...provided,
        color :'#1F497D',
        fontFamily:'Arial',
        fontWeight:400,
        fontSize:'12px',
        borderRadius: '8px',

      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'rgba(23, 54, 93, 1)',
        fontSize: '14px'
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0px 12px',
  
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        border: 'none',
        
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '8px'
      }),
      indicatorSeparator: () => ({
        display: 'none' 
      }),
      placeholder: (provided)=>({
        ...provided,
        fontSize:'14px',
        color: '#64748B'

      })
      
};

function StyledRadio(props: RadioProps) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

type Props = {
    countries?: { label: string, value: string }[],
    city?: { label: string, value: string }[],
    cityListHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    countryListHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    locationRadioButtonHandler?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
    handleCountryChange: (value: string) => void,
    handleCityChange: (value: string) => void
}

export default function CustomizedRadios(props: Props) {
    const classes = useStyles();
    const { countries, handleCityChange, handleCountryChange, city, countryListHandler, cityListHandler, locationRadioButtonHandler } = props;

    return (
        <FormControl component="fieldset">
            <label className={classes.headerTitle}>Location Preferences</label>
            <RadioGroup
                defaultValue="Country"
                aria-label="Country"
                name="customized-radios"
                onChange={locationRadioButtonHandler}
            >
                <FormControlLabel
                    value="Country"
                    control={<StyledRadio />}
                    label={<Typography className={classes.titleOfRadio}>Country</Typography>}
                />
                <div className={classes.selectContainer}>
                    <Select
                        options={countries}
                        styles={customStyles}
                        classNamePrefix="react-select"
                        placeholder="Select Country"
                        onChange={(selectedOption) => {
                            if (selectedOption && 'value' in selectedOption) {
                                handleCountryChange(selectedOption.value);
                            }
                        }}
                    />
                </div>

                <FormControlLabel
                    value="City"
                    control={<StyledRadio />}
                    label={<Typography className={classes.titleOfRadio}>City</Typography>}
                />
                <div className={classes.selectContainer}>
                    <Select
                        options={city}
                        styles={customStyles}
                        classNamePrefix="react-select"
                        placeholder="Select City"
                        onChange={(selectedOption) => {
                            if (selectedOption && 'value' in selectedOption) {
                                handleCityChange(selectedOption.value);
                            }
                        }}
                    />
                </div>
            </RadioGroup>
        </FormControl>
    );
}
