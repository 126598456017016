/* eslint-disable prettier/prettier */
import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  Dimensions,
  Button,
} from "react-native";
const Height = Dimensions.get("window").height;
import { Avatar, Box, CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import {
  Post,
  Comment
} from "./CommentsController";
import SliderComponent from "./components/SliderComponent.web";
import CommentComponent from "./components/ComentComponent.web";
import { commentIcon, dot, more, saveBlue, saveBlueOutlineIcon} from "./assets";
import PostCommentTextFieldWeb from "./components/PostCommentTextField.web";
import Upvotedownvote from "../../../../packages/blocks/upvotedownvote/src/Upvotedownvote.web";
import ConfirmDialog from "../../../../packages/components/src/ConfirmDialog.web"
import CustomMenu from "../../../../packages/blocks/user-profile-basic/src/components/CustomMenu.web";
import InvitationDialog from "../../../../packages/components/src/InvitationDialog.web";
import { ToastContainer } from "react-toastify";

import LoadMoreButton from "../../../../packages/components/src/LoadMoreButton.web";
import { styled } from "@material-ui/styles";
import ChatReportDialog from "../../../../packages/components/src/ChatReportDialogue";
import { ReportedSuccessDialogue } from "../../../../packages/components/src/ReportedSuccessDialogue";
// Customizable Area End

import CommentController, {
  Props,
  configJSON
} from "./CommentsController";

export default class CreateComment extends CommentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <CustomScrollBox style={{
        ...webStyle.middle,
        width: '100%',
        boxShadow: !this.state.deletedPostSuccess ?
          'none' :
          '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
      }}>
        <Grid item sm={12} container id="create-comment" >
          {this.state.deletedPostSuccess ?
            <Box style={webStyle.deleteMessageContainer}>
              <Box style={webStyle.deletedMessageHead}>Post Removed</Box>
              <Box style={webStyle.deletedMessage}>Post successfully deleted</Box>
            </Box>
            :
            <>
              {
                this.state.postLoading ?
                <div style={{margin: "auto", textAlign: "center", display: "flex", justifyContent: "center", height: "100px", alignItems: "center"}}>
                <CircularProgress style={{ color: '#1F497D', width: "30px", height: "30px" }} />
              </div> :
                this.state.allPosts.map((post: Post) => {
                  return (
                    <Grid item container style={webStyle.root1}>
                      <ConfirmDialog open={this.state.deletePost[post.id]} handleClose={() => { this.setState({ deletePost: false }) }} hadndleDelete={() => this.handleDeletePostApi(post.id)} questionOne="Are you sure you want to delete this" questionTwo="Post?" />
                      <Grid style={webStyle.headerContainer}>
                        <Grid id="gotorecruiter" style={webStyle.profileleft} onClick={() => this.handleRecruiterNavigation(+post.attributes.account.account_id)}>
                          <Avatar style={{ height: "56px", width: "56px", border: "2px solid #6D97C1" }} src={post?.attributes?.account?.account_profile}></Avatar>
                          &nbsp;
                          &nbsp;
                          <Typography style={webStyle.profileTitle}>{post?.attributes?.account.account_name}</Typography>
                        </Grid>
                        <Grid style={webStyle.profileRight}>
                          <img src={dot} />
                          &nbsp;
                          <span style={webStyle.timeTitle}>{post?.attributes?.created_at}</span>
                          &nbsp;
                          &nbsp;
                          <img
                            id="saveIconTop"
                            onClick={() => this.handleSavePostApi(post.id, post.attributes.is_saved)}
                            src={this.renderSaveIcon(post.attributes.is_saved)}
                            style={{ cursor: "pointer" }} 
                          />
                          &nbsp;
                          &nbsp;
                          <Box style={{ position: 'relative' }}>
                            {
                              <Box>
                                {this.state.showMoreOptions ?
                                    <CustomMenu hideSelectedIcon buttonIcon={more} 
                                      menuItems={[
                                        { label: 'Delete', onClick: () => {this.deletePostHandler(post.id)}},
                                        { label: 'Edit', onClick: () => {this.editPostHandler(post.id)}},
                                      ]}
                                    />
                                  :
                                  <CustomMenu data-test-id="customMenu" hideSelectedIcon buttonIcon={more}
                                    menuWidth = "182px"
                                    menuPadding= "5px"
                                    menuItems={[
                                      { label: this.getConnectionStatus(post), onClick: () => { this.handleInvitation(post) }, hideItem: post.attributes.account.account_id === this.state.userDetails.attributes.account_details.id},
                                      { label: this.getFollowText(post.attributes.following_account), onClick: () => { this.handleFollowPost(post) }, showTickIcon: post.attributes.following_account , hideItem: post.attributes.account.account_id === this.state.userDetails.attributes.account_details.id},
                                      { label: 'Hide Post', onClick: () => { this.handleHidePostApi(post.id) }},
                                      { label: 'Report', onClick: () => { this.handleReportDialogue(post.id) }, hideItem: post.attributes.account.account_id === this.state.userDetails.attributes.account_details.id},
                                    ]}
                                  />
                                }                              
                              </Box>
                            }
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid style={{ padding: '0px 20px' }}>
                        <Typography style={webStyle.postDescription as React.CSSProperties}>
                          <span style={{ fontWeight: "bold" }}>{`[${post.attributes.sub_topic}] `}</span>
                          {this.handleCondition(this.state.expandedPosts.includes(post.id), post.attributes.body, this.truncateText(post.attributes.body, 250))}
                          <span data-test-id="moreText" className="moreText" style={{...webStyle.postDescription, cursor: "pointer", color: "rgba(0,0,0,.6)"} as React.CSSProperties} onClick={() => this.toggleReadMore(post.id)}>{!this.state.expandedPosts.includes(post.id) && post.attributes.body.length > 250 && `...more` }</span>
                        </Typography>
                      </Grid>
                      <Grid style={{ width: "100%", padding: "0px 15px", display: "flex", overflowY: "hidden", overflowX: "hidden" }}>
                        <SliderComponent items={post.attributes.images} />
                      </Grid>
                      <Grid item container style={{ padding: "10px 20px" }}>
                        <Upvotedownvote id="upvote-downvote" navigation={this.props.navigation} post={post} />
                        <Grid item id="toggle-comment" style={{ paddingLeft: "25px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => this.toggleComments(post)}>
                          <img src={commentIcon} />&nbsp;
                          <Typography style={webStyle.footerText}>&nbsp;{post.attributes.comment_count}&nbsp;Comments</Typography>
                        </Grid>
                        <Grid
                          id="saveIcon"
                          item
                          style={{ paddingLeft: "25px", display: "flex", alignItems: "center", cursor: "pointer" }}
                          onClick={() => this.handleSavePostApi(post.id, post.attributes.is_saved)}
                        >
                          {
                            this.renderImage(post.attributes.is_saved , <img src={saveBlue } />, <img src={saveBlueOutlineIcon } />)
                          }
                          &nbsp;
                          <Typography
                            style={webStyle.footerText}>
                            {this.getSaveText(post.attributes.is_saved)}
                          </Typography>
                        </Grid>
                      </Grid>
                      {this.renderDivider(this.state.postShowID !== post.id, <Divider style={{ height: "1px", width: "100%", marginBottom: "10px" }} />)}
                      {this.state.postShowID === post.id && <PostCommentTextFieldWeb setShowTextField={()=>{}} userDetails={this.state.userDetails} loader={this.state.loaderComment} progress={this.state.progressComment} remainingTime={this.state.remainingTimeComment} updateComment={this.updateComment} goToLoginPage={this.goToLoginPage} createComment={this.createComment} state={this.state} post={post} cmt={post.attributes.comment?.[0] || []} isNested={false} openNestedCommetns={() => { }} />}
                      {this.state.postShowID === post.id && <Grid style={{ padding: "15px 26px 0px 38px", display: "flex", flexDirection: "column", width: "100%" }}>
                        {
                          post.attributes.comment.slice(0, this.state.commentslength).map((comment: Comment, index: number) => <CommentComponent loader={this.state.loaderComment} progress={this.state.progressComment} remainingTime={this.state.remainingTimeComment} updateComment={this.updateComment} handleDeleteComment={this.handleDeleteComment} userDetails={this.state.userDetails} goToLoginPage={this.goToLoginPage} key={comment.id} comment={comment} index={index} isNested={false} parentlength={0} lastChild={false} createComment={this.createComment} state={this.state} post={post} upvotePostComment={this.upvotePostComment} downvotePostComment={this.downvotePostComment} />)
                        }
                        {
                          (post.attributes.comment.length > this.state.commentslength) && <Grid sm={12} style={{ margin: "-19px 0px 18px -19px", cursor: "pointer" }}>
                            <button style={webStyle.loadmoreBtn1} onClick={this.handleLoadmoreComment}>Load more comments</button>
                          </Grid>
                        }
                      </Grid>
                      }
                      {this.renderDivider(this.state.postShowID === post.id, <Divider style={{ height: "1px", width: "100%", marginBottom: "10px" }} />)}
                    </Grid>)
                })
              }
              {
               this.state.allPosts.length <= 0 && !this.state.postLoading &&
               <div style={{textAlign: "center", width: "100%"}}>
                <Typography style={{fontFamily: "Arial", fontSize: "15px"}}>
                    No records found
                </Typography>
               </div>
              }
              {
               <div ref={this.sentinelRef}>
                </div>
              }
             {
              <LoadMoreButton postLoading={this.state.postLoading} allPosts={this.state.allPosts} handleLoadMore={this.handleLoadMore} isLoadingPost={this.state.isLoadingPost} />
              }
            </>
          }
          <InvitationDialog
            data-test-id="invitationDialog"
            open={this.state.openInvitationDialog.open}
            handleClose={this.handleInvitationClose}
            handleSendInvitation={this.handleInvite}
            questionOne={"Add a note to your invitation?"}
            questionTwo={`Personalize your invitation ${this.state.userDetails.attributes.full_name} by adding a note. Members are more likely to accept invitations that include a note.`}
          />
          <ToastContainer />

        </Grid>
       {this.state.reportClick &&
        <ChatReportDialog
            data-test-id="ChatReportDialog"
            handleClose={this.handleReportDialogue}
            selectedReason={this.state.selectedReason}
            handleSelect={this.handleSelect}
            handleSubmit={this.addReasons1}
            list={this.state.userReasons}
            reportType="post"
          />}
        {this.state.reportedSuccessfully &&
          <ReportedSuccessDialogue
            handleClose={this.closeSuccessDialogue}
            open={this.state.reportedSuccessfully}
            type="post"
          />
        }
      </CustomScrollBox>
    );
    return (
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}

          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  loadmoreBtn1: {
    height: "32px",
    width: "137px",
    background: "#F1F5F9",
    borderRadius: "8px",
    color: "#17365D",
    fontSize: "12px",
    fontFamily: "Arial",
    border: "0px",
    cursor: "pointer"
  },
  root: { margin: "0px", width: "100%", padding: "0px", paddingBottom: "20px", height: "90vh", overflow: "hidden" },
  loadmoreBtn: {
    padding: "6px 10px",
    height: "32px",
    width: "137px",
    color: "#17365D",
    backgroundColor: "#F1F5F9",
    borderRadius: "8px",
    border: "0px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  middle: {
    background: "white",
    padding: "16px 0px 16px",
    borderRadius: "16px",
    paddingBottom: "16px"
  },
  someHeight: {
    height: '75vh',
    boxShadow: 'none',
  },
  fitContent: {
    height: 'fit-content',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
  root1: { padding: "0px" },
  headerContainer: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", padding: "0px 20px", cursor: "pointer" },
  profileleft: { display: "flex", alignItems: "center", },
  profileTitle: {
    color: "#17365D",
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "18px"
  },
  profileRight: { display: "flex", justifyContent: "space-between", alignItems: "center" },
  timeTitle: {
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: "lighter",
    color: "#1F497D"
  },
  footerText: {
    color: "#17365D",
    fontSize: "12px",
    fontFamily: "Arial"
  },
  postImage: {},
  postDescription: {
    color: "#17365D",
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "12px",
    padding: "8px 0px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-all"
  },
  boxContainer: {
    border: "1px solid #CBD5E1",
    borderRadius: 16,
    width: "169",
    padding: "4px",
    background: 'white',
    zIndex: '10000',
    right: 0,
    marginTop: "10px"
  },
  textButton: {
    color: "#17365D",
    fontSize: "12px",
    fontFamily: "Arial",
    fontWeight: 700,
    padding: '9px 20px 9px 16px'
  },
  popoverRoot: {
    borderRadius: 16,
    padding: "4px"
  },
  deletedMessageHead: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: '16px',
    color: "#1F497D"
  },
  deleteMessageContainer: {
    width: '100%',
    padding: '8px 16px 8px 16px',
    borderRadius: 8
  },
  deletedMessage: {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontSize: '14px',
    color: '#9C9C9C',
    marginTop: '10px'
  },
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: 19,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  images: {
    width: 90,
    height: 90,
    borderRadius: 10,
    marginBottom: '5%',
  },
  input: {
    width: '100%',
    paddingVertical: 10,
    borderBottomWidth: 1,
    color: '#252837',
    fontSize: 16,
    marginTop: 10
  },
  border: {
    borderBottomColor: '#eee',
  },
});

const CustomScrollBox = styled(Box)({
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '87px'
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '57px',
    background: 'var(--Base-Grey-5, #D9D9D9)'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#6C96C1', 
    borderRadius: '57px',
    '&:hover': {
      background: '#3489e0'
    },
  },
  '& .moreText': {
    marginLeft: "6px",
    '&:hover': {
      textDecoration: "underline",
      color: "#0a66c2",
    }
  }
});
// Customizable Area End
