import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ClickAwayListener, Grow, Popper, Button, Box } from "@material-ui/core";
import { openMenuicon } from "../../blocks/catalogue/src/assets";
import CustomCheckBoxComponent from "./CustomCheckBoxComponent"; 

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            height: '29px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "26px",
            background: "#EEECE1",
            padding: "6px, 8px, 6px, 12px",
            fontWeight: 400,
            '&:hover': {
                backgroundColor: '#EEECE1',
            },
            textTransform: "none",
            color: "rgba(31, 73, 125, 1)",
            textAlign: "right",
            fontFamily: "Arial",
            fontSize: "12px",
            fontStyle: "normal",
            lineHeight: "20px",
        },
        popover: {
            borderRadius: "16px",
            border: "2px solid #CBD5E1",
            background: "#FFF",
            padding: "15px 20px",
            maxHeight: "300px",
            overflowY: "scroll"
        },
        menuitem: {
            padding: "10px"
        }
    })
);

type Option = {
    id: number | string;
    title: string;
};

type Props = {
    fieldName: string;
    options: string[];
    handleMenuItemClick: (opt: Option) => void;
    values: string[];
};

export default function ExperienceSelect(props: Props) {
    const { fieldName, options, handleMenuItemClick, values } = props;

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div >
            <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classes.btn}
            >
                {fieldName} <img src={openMenuicon} alt="open" style={{ width: '16px', height: '16px' }} />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ marginTop: "10px", background: "white", zIndex: 1000000, borderRadius: "16px", maxHeight: "300px" }}
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "top right",
                        }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <div className={classes.popover}>
                                {options?.map((item, index) => (
                                    <Box key={index}>
                                        <CustomCheckBoxComponent 
                                            onClick={() => {
                                                handleMenuItemClick({ id: index, title: item })
                                            }} 
                                            title={`${item}`} 
                                            checked={values.includes(item)} 
                                        />
                                    </Box>
                                ))}
                            </div>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
