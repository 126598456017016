// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");

import {
  getStorageData,
  setStorageData
} from '../../../framework/src/Utilities';
import { debounce, getLoginToken, replaceNullWithEmptyString, setEditDataCity } from "../../../components/src/Utils.web";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { Country, ICountry, City, ICity } from 'country-state-city';
import CustomisableSubscriptionAdapter from "../../adapters/src/CustomisableUserSubscriptionAdapter";
export interface Props {
  navigation: any;
  id: string;
}

export interface JobListDataType {
  attributes: {
    address: string,
    addresses: [],
    company_headquarter: string,
    company_name: string,
    company_page_id: number,
    company_photo: string,
    company_photo_url: string,
    country: string,
    created_at: string,
    employment_type: null,
    employment_type_id: number,
    industry_id: number,
    industry_name: string,
    job_description: string,
    job_function: string,
    job_title: string,
    job_video: string,
    job_video_url: string,
    location: string,
    other_skills: Array<string>,
    preffered_location: Array<string>,
    profile_id: number,
    question_answer_id: Array<string>,
    question_answers: Array<object>,
    remote_job: boolean,
    salary: string,
    seniority_level: string,
    skill_id: Array<number>,
    skill_name: Array<string>,
    sub_emplotyment_type: null,
    total_inteview_rounds: number,
    image_url: string,
    followers_count: number
  },
  id: string,
  type: string
}

export interface JobListIOSDataType {
  item: {
    attributes: {
      address: string,
      addresses: [],
      company_headquarter: string,
      company_name: string,
      company_page_id: number,
      company_photo: string,
      company_photo_url: string,
      country: string,
      created_at: string,
      employment_type: null,
      employment_type_id: number,
      industry_id: number,
      industry_name: string,
      job_description: string,
      job_function: string,
      job_title: string,
      job_video: string,
      job_video_url: string,
      location: string,
      other_skills: Array<string>,
      preffered_location: Array<string>,
      profile_id: number,
      question_answer_id: Array<string>,
      question_answers: Array<object>,
      remote_job: boolean,
      salary: string,
      seniority_level: string,
      skill_id: Array<number>,
      skill_name: Array<string>,
      sub_emplotyment_type: null,
      total_inteview_rounds: number,
      image_url: string,
      followers_count: number
    },
    id: string,
    type: string
  }
}

export type Option = { title: string, id: string }
export interface QuestionFormate {
  question_text: string,
  question_type: string,
  options: Option[],
  required: boolean,
  id: string
}

export interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  first: boolean;
  searchText: string;
  jobArray: JobListDataType[];
  recent: Array<string>;
  lastSearch: string;
  loading: boolean
  followIndex: number,
  token: string,
  openModal: boolean,
  openConfirm: boolean,
  openAddJobModal: boolean,
  isModalOpen:boolean,
  deleteId: number | string,
  currentStep: number,
  formOne: {
    job_title: string,
    company_name: string,
    country: string,
    city: string,
    job_categories: string,
    job_description: string,
    post_expire_after: string
  },
  formTwo: {
    salary_from: string,
    salary_to: string,
    disclose: boolean | string,
    experience_required: string,
    industry_type: string,
    department: string,
    skills_required: string[],
    education: string,
    currency: string
  },
  formDataFinal: object,
  formThree: QuestionFormate[],
  focuseId: string,
  allCreatedJobs: Job[],
  JobDetails: JobDetailsType,
  totalPages: number,
  currentPage: number,
  formType: "edit" | "create" | "draft",
  isInfiniteLoading: boolean;
  countries: { value: string; label: string }[];
  companyList: { value: string, label: string }[];
  cities: { value: string, label: string }[];
  openJobDetailListing: boolean;
  selectedJobForDetails: Job;
  refreshPage: boolean
}

export interface Job {
  id: string;
  type: string;
  attributes: {
    job_description: string;
    job_title: string;
    location: string;
    remote_job: boolean;
    total_interview_rounds: number | null;
    employment_type_id: string | null;
    requirements: string | null;
    company_name: string;
    experience_required: string;
    salary: string | null;
    application_deadline: string | null;
    skills_required: string[];
    responsibilities: string[];
    company_benefits: string[];
    country: string;
    job_categories: string;
    post_expire_after: string;
    city: string;
    salary_from: string;
    disclose: boolean;
    salary_to: string;
    education: string;
    department: string;
    status: string;
    industry_type: string;
    draft_page: any; // Adjust the type if you have specific data for this
    created_at: string;
    candidatesCount: number;
    dislikes_count: number;
    job_applications_count: {
      invited: number;
      rejected: number;
      applied: number;
      'applied+': number;
      review: number;
      interviewing: number;
      messaged?: number;
    };
  };
}
interface JobDetailsType {
  id: string;
  type: string;
  attributes: {
    job_title: string;
    job_description: string;
    remote_job: boolean;
    location: string | null;
    employment_type_id: string | null;
    total_interview_rounds: number | null;
    company_name: string;
    requirements: string | null;
    salary: string | null;
    experience_required: string;
    skills_required: string[];
    application_deadline: string | null;
    company_benefits: string[];
    responsibilities: string[];
    job_categories: string;
    country: string;
    city: string;
    post_expire_after: string;
    salary_from: string;
    salary_to: string;
    disclose: boolean;
    education: string;
    industry_type: string;
    department: string;
    status: string;
    draft_page: any; // Define the type according to your requirements
    candidatesCount: number;
    questions: QuestionFormate[];
    currency: string
  };
}


interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingController extends BlockComponent<Props, S, SS> {
  searchJobApiCallId: string = ""
  searchCompanyApiCallId: string = "";
  searchPeopleApiCallId: string = "";
  followCompanyApiCallId: string = "";
  addConnectionApiCallId: string = "";
  lastVisitedJob: string = "";
  lastVisitedCompany: string = "";
  lastVisitedPeople: string = "";
  createJobapiCallId: string | Message = "";
  getAllCreatedJobsApiId: string | Message = "";
  getJobDetailsApiId: string | Message = "";
  deleteJobApiId: string | Message = "";
  companyListApiId: string | Message = "";
  observer: IntersectionObserver | null = null;
  jobStatusChangeApiId: string | Message = "";
  subscriptionCallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleScroll = debounce(this.handleScroll.bind(this), 1000);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: '',
      loading: false,
      txtSavedValue: 'A',
      enableField: false,
      first: true,
      searchText: '',
      jobArray: [],
      recent: [],
      lastSearch: '',
      followIndex: -1,
      token: '',
      openModal: false,
      openConfirm: false,
      openAddJobModal: false,
      isModalOpen:false,
      deleteId: "",
      currentStep: 1,
      formOne: {
        job_title: "",
        company_name: "",
        country: "",
        city: "",
        job_categories: "",
        job_description: "",
        post_expire_after: "4 Weeks"
      },
      formTwo: {
        salary_from: "",
        salary_to: "",
        disclose: false,
        experience_required: "",
        industry_type: "",
        department: "",
        skills_required: [],
        education: "",
        currency: "$"
      },
      formDataFinal: {

      },
      formThree: [],
      focuseId: "",
      allCreatedJobs: [],
      JobDetails: {
        id: "",
        type: "",
        attributes: {
          job_title: '',
          job_description: '',
          remote_job: false,
          location: null,
          employment_type_id: null,
          total_interview_rounds: null,
          company_name: '',
          requirements: null,
          salary: null,
          experience_required: '',
          skills_required: [],
          application_deadline: null,
          company_benefits: [],
          responsibilities: [],
          job_categories: '',
          country: '',
          city: '',
          post_expire_after: '',
          salary_from: '',
          salary_to: '',
          disclose: false,
          education: '',
          industry_type: '',
          department: '',
          status: '',
          draft_page: null,
          candidatesCount: 0,
          questions: [],
          currency: ''
        }
      },
      totalPages: 1,
      currentPage: 1,
      formType: "edit",
      isInfiniteLoading: false,
      countries: [],
      companyList: [],
      cities: [],
      openJobDetailListing: false,
      selectedJobForDetails: {} as Job,
      refreshPage: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getJobSearchSuccessCallBack = (responseJson: JobListDataType[], apiRequestCallId: string) => {
    if (apiRequestCallId === this.searchJobApiCallId) {
      try {
        this.lastVisitedJob = this.state.searchText?.trim();
        this.setState({ jobArray: responseJson || [], loading: false });
      }
      catch (error) {
        alert(error)
      }
    }
  }

  getJobSearchFailedCallBack = (message: string, apiRequestCallId: string) => {
    if (message.includes('Record not found')) {
      if (apiRequestCallId === this.searchJobApiCallId) {
        this.lastVisitedJob = this.state.searchText?.trim()
        this.setState({ jobArray: [], loading: false });
      }
    } else if (apiRequestCallId === this.addConnectionApiCallId && message.includes("Connection created successfully")) {
      alert('Request sent successfully')
    }
  }

  getJobSearchErrorCallBack = (errorReponse: object, apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.searchJobApiCallId ||
      apiRequestCallId === this.searchCompanyApiCallId ||
      apiRequestCallId === this.searchPeopleApiCallId
    ) {
      this.parseApiErrorResponse(errorReponse);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  getMessage = (key: string) => {
    switch (key) {
      case "edit":
        return "Job Updated Successfully"
      case "create":
        return "Job Created Successfully"

      case "draft":
        return "Job Saved as Draft Successfully"
    }
  }

  handleCreateJobApiResponse = (responseJson: {data?: object}) => {
    if (!!responseJson.data) {
      this.setState({
        openAddJobModal: false,
        currentPage: 1,
        formOne: {
          job_title: "",
          company_name: "",
          country: "",
          city: "",
          job_categories: "",
          job_description: "",
          post_expire_after: "4 Weeks"
        },
        formTwo: {
          salary_from: "",
          salary_to: "",
          disclose: false,
          experience_required: "",
          industry_type: "",
          department: "",
          skills_required: [],
          education: "",
          currency:"$"
        },
        formDataFinal: {},
        formThree: [],
      });
      toast.success(this.getMessage(this.state.formType));
      window.dispatchEvent(new CustomEvent("POST_UPDATE", { detail: true }));
      this.getAllJobs(1);
      this.setRefreshPage(true);
    }
  };

  handleGetAllCreatedJobsApiResponse = (responseJson: {total_page: number, current_page: number, recruiter_job_listing: {data: Job[]}}) => {
    if (!!responseJson.recruiter_job_listing.data) {
      this.setState({
        allCreatedJobs: responseJson.current_page === 1 ? responseJson.recruiter_job_listing.data : [...this.state.allCreatedJobs, ...responseJson.recruiter_job_listing.data] as Job[],
        totalPages: responseJson.total_page,
        formOne: {
          job_title: "",
          company_name: "",
          country: "",
          city: "",
          job_categories: "",
          job_description: "",
          post_expire_after: "4 Weeks"
        },
        formTwo: {
          salary_from: "",
          salary_to: "",
          disclose: false,
          experience_required: "",
          industry_type: "",
          department: "",
          skills_required: [],
          education: "",
          currency: "$"
        },
        formDataFinal: {},
        formThree: [],
        currentStep: 1,
        isInfiniteLoading: false
      });
    }
  };

  handleGetJobDetailsApiResponse = (responseJson: {data?: JobDetailsType}) => {
    if (!!responseJson.data) {
      this.setState({
        JobDetails: responseJson.data as JobDetailsType
      }, () => this.setEditData());
    }
  };
  handeleSubscription=(responseJson:{error:string, message:string})=>{
    if(responseJson.message==="You can post a job with your subscription plan"){
      this.handleOpenAddJobModal()
    }
    else if(responseJson.error ){
      this.setState({
        isModalOpen: true
      })
      toast.error(responseJson.error)
    }
  }
  handleModalClose=()=>{
    this.setState({
      isModalOpen:false
    })
  }
  handleShowPlans = () => {
    const hamburgerToFeedbackObj = new CustomisableSubscriptionAdapter()
    const msg = new Message(getName(MessageEnum.CustomSubscriptionDataMessage))
     msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
     hamburgerToFeedbackObj.send(msg)
  };


  handleDeleteJobApiResponse = (responseJson: { message?: string }) => {
    if (!!responseJson.message) {
      toast.success("Job Deleted Successfully");
      this.getAllJobs(1);
      this.setState({
        refreshPage: true,
        openJobDetailListing: false
      });
    }
  };

  handleCompanyListApiResponse = (responseJson: { company_listing?: { name: string }[] }) => {
    if (!!responseJson.company_listing) {
      const companyList: { value: string, label: string }[] = responseJson.company_listing.map(({ name }) => ({
        value: name,
        label: name,
      }));
      this.setState({ companyList });
    }
  };

  handleJobStatusChangeApiResponse = () => {
    this.getAllJobs(1);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        switch (webApiRequestCallId) {
          case this.createJobapiCallId:
            if(!!responseJson.error) {
              toast.error(responseJson.error, { autoClose: false, onClose : () => {
                const msg = new Message(getName(MessageEnum.NavigationMessage));
                msg.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
                msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
                this.send(msg);
              }});
            }
            this.handleCreateJobApiResponse(responseJson);
            break;

          case this.getAllCreatedJobsApiId:
            this.handleGetAllCreatedJobsApiResponse(responseJson);
            break;

          case this.getJobDetailsApiId:
            this.handleGetJobDetailsApiResponse(responseJson);
            break;

          case this.deleteJobApiId:
            this.handleDeleteJobApiResponse(responseJson);
            break;

          case this.companyListApiId:
            this.handleCompanyListApiResponse(responseJson);
            break;

          case this.jobStatusChangeApiId:
            this.handleJobStatusChangeApiResponse();
            break;
          case this.subscriptionCallId:
            this.handeleSubscription(responseJson);
            break;

          default:
            break;
        }

      }
    }
  }

  setRefreshPage = (value: boolean) => {
    this.setState({
      refreshPage: value
    })
  }

  getToken = () => {
    const getAuthToken: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(getAuthToken);
  };

  async componentDidMount() {
    this.getToken();
    this.getAllJobs();
    this.createObserver();
  }
  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  handleScroll() {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1
    }))
  }

  createObserver() {
    this.observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.handleScroll();
      }
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.currentPage !== this.state.currentPage && this.state.currentPage <= this.state.totalPages) {
      this.getAllJobs(this.state.currentPage);
    }
    if (this.observer) {
      const elements = document.querySelectorAll('.list-item-569');
      if (elements.length > 0) {
        const lastElement = elements[elements.length - 1];
        this.observer.observe(lastElement);
      }
    }
    if (prevState.openJobDetailListing !== this.state.openJobDetailListing) {
      this.createObserver();
    }
  }
  async getRecents() {
    const recentData = await getStorageData('recent');
    this.setState({ recent: recentData });
  }
  handler = (inputValue: string) => {
    if (inputValue.trim()) {
      this.setSearchText(inputValue)
    }
    this.setState({ lastSearch: inputValue.trim(), searchText: inputValue, loading: true });
  };

  setSearchText = (inputValue: string) => {
    this.searchJob(inputValue);
  };

  addDataToRecent() {
    let recent = this.state.recent;
    this.setState({ recent }, () => {
      setStorageData('recent', JSON.stringify(this.state.recent));
    });
  }


  goToJobDetailPage = (jobId: string, companyName: string, companyLocation: string, companyLogo: string) => {
    const jobMessage = new Message(getName(MessageEnum.NavigationMessage));
    jobMessage.addData(getName(MessageEnum.NavigationTargetMessage), "JobDetailsPage");

    jobMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      companyName,
      companyLocation,
      companyLogo,
      jobId
    });
    jobMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(jobMessage);
  };

  searchJob = async (inputValue: string) => {
    this.setState({ loading: true });
    let token = this.state.token;
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchJobApiEndPoint + '?search=' + inputValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUrl = (imageUrl: string) => {
    if (imageUrl.includes("https://") || imageUrl.includes("http://")) {
      return imageUrl;
    } else {
      return config.baseURL + imageUrl;
    }
  };

  handleJobDetailListing = (selectedJob: Job) => {
    this.setState({
      openJobDetailListing: true,
      selectedJobForDetails: selectedJob
    })
  }

  handleJobDetailListingClose = () => {
    this.getAllJobs(1)
    this.setState({
      openJobDetailListing: false
    })
  }

  createJob = (data: any, isDraft: boolean = false) => {
    let token = getLoginToken();
    isDraft && this.setState({ formType: "draft" });
    let httpBody = {
      save_as_draft: isDraft.toString(),
      data: {
        attributes: {
          ...data,
          questions: data.questions.map((item: QuestionFormate) => {
            return { ...item, question_text: item.question_text, question_type: item.question_type, options: item.options.map(item => item.title).filter(item => item !== ""), drop_downs: item.options.map(item => item.title).filter(item => item !== "") }
          }
          )
        }
      }
    }
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createJobapiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${this.state.formType === "edit" ? configJSON.createJobApiEndPoint + "/" + this.state.JobDetails.id : configJSON.createJobApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `${this.state.formType === "edit" ? configJSON.httpPutMethod : configJSON.httpPostMethod}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getAllJobs = async (page?: number) => {
    let token = await getStorageData("authToken");
    if (page) {
      this.setState({
        isInfiniteLoading: true
      })
    }
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCreatedJobsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllCreatedJobs}?page=${page ?? this.state.currentPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getJobdetailsById = async (id: string | number) => {
    let token = await getStorageData("authToken");

    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJobDetailsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createJobApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteJobdetailsById = (id: string | number) => {
    let token = getLoginToken();

    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteJobApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createJobApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleViewClick = (id: number | string) => {
    this.getJobdetailsById(id);
    this.handleModal();
  }

  handleModal = () => {
    this.setState({
      openModal: !this.state.openModal
    })
  }
  handleConfirm = (id: string | number = "") => {
    this.setState({
      openConfirm: !this.state.openConfirm,
      deleteId: id
    })
  }

  handleDeleteJob = () => {
    this.deleteJobdetailsById(this.state.deleteId);
    this.setState({
      openConfirm: !this.state.openConfirm,
    })
  }

  handleOpenAddJobModal = () => {
    this.setState({
      openAddJobModal: !this.state.openAddJobModal,
      formType: "create",
      currentStep: 1,
      formOne: {
        job_title: "",
        company_name: "",
        country: "",
        city: "",
        job_categories: "",
        job_description: "",
        post_expire_after: "4 Weeks"
      },
      formTwo: {
        salary_from: "",
        salary_to: "",
        disclose: false,
        experience_required: "",
        industry_type: "",
        department: "",
        skills_required: [],
        education: "",
        currency: "$"
      },
      formDataFinal: {

      },
      formThree: [],
    });
  }
  setStep = (step: number, values: object) => {
    const containerDiv = document.getElementById('dialogueContainer')
    if (containerDiv) { containerDiv.scrollIntoView() }
    this.setState({
      currentStep: step,
      formDataFinal: { ...this.state.formDataFinal, ...values }
    })
    if (step === 1){
      this.setState({
        formTwo: {...this.state.formTwo, ...values}
      })
    }
  }

  addNewQuestion = (item: QuestionFormate) => {
    this.setState({
      formThree: [...this.state.formThree, item],
      focuseId: item.id
    })
  }
  addOption = (newQuestion: QuestionFormate) => {
    this.setState({
      formThree: this.state.formThree.map(item => item.id === newQuestion.id ? newQuestion : item),
    }, () => {
      const inputElement = document.querySelector(`input[value="${newQuestion.options[newQuestion.options.length - 1].title}"]`) as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
        inputElement.select();
      }
    })
  }
  isDisabled = (): boolean => {
    let ans = false;
    this.state.formThree.map(item => {
        if(item.options.length === 0 && item.question_type !== "Short Answer") {
            ans = true;
        }
    })
    return ans
}

  handleFormOneSubmit = (values: {
    job_title: string,
    company_name: string,
    country: string,
    city: string,
    job_categories: string,
    job_description: string,
    post_expire_after: string
  }) => {
    this.setState({
      formOne: { ...values }
    })
  }

  handleFormTwoSubmit = (values: {
    salary_from: string,
    salary_to: string,
    disclose: boolean | string,
    experience_required: string,
    industry_type: string,
    department: string,
    skills_required: string[],
    education: string,
    currency: string
  }) => {
    this.setState({
      formTwo: { ...values }
    })
  }

  handleFormThreeSubmit = (values: QuestionFormate[]) => {
    this.setState({
      formThree: [ ...values ]
    })
  }

  handleQuestionChange = (e: any, newQuestion: QuestionFormate) => {
    this.setState({
      formThree: this.state.formThree.map(item => item.id === newQuestion.id ? { ...item, question_text: e.target.value } : item),
    })
  }
  handleTypeChange = (e: any, newQuestion: QuestionFormate) => {
    this.setState({
      formThree: this.state.formThree.map((item, index) => item.id === newQuestion.id ? { ...item, question_type: e.target.value, options: [{ title: `Option ${1}`, id: uuidv4() }] } : item),
    })
  }
  handleOptionChange = (e: any, newQuestion: QuestionFormate, qid: string) => {
    this.setState({
      formThree: this.state.formThree.map(item => item.id === newQuestion.id ? { ...item, options: item.options.map((opt: Option) => opt.id === qid ? { ...opt, title: e.target.value } : opt) } : item),
    })
  }
  handleRequiredChange = (e: any, newQuestion: QuestionFormate) => {
    this.setState({
      formThree: this.state.formThree.map(item => item.id === newQuestion.id ? { ...item, required: e.target.checked } : item),
    })
  }
  handleRemoveQuestion = (newQuestion: QuestionFormate) => {
    this.setState({
      formThree: this.state.formThree.filter(item => item.id !== newQuestion.id),
    })
  }
  handleRemoveOption = (newQuestion: QuestionFormate, qid: string) => {
    this.setState({
      formThree: this.state.formThree.map(item => item.id === newQuestion.id ? { ...item, options: item.options.filter((opt: Option) => opt.id !== qid) } : item),
    })
  }
  setFocusId = (id: string) => {
    this.setState({
      focuseId: id
    })
  }
  handleEditClick = (id: number | string) => {
    this.getJobdetailsById(id);
    this.setState({
      formType: "edit",
      openAddJobModal: !this.state.openAddJobModal,
    });
  }

  setCity = (cities: { value: string, label: string }[]) => {
    this.setState({
      cities: cities
    })
  }

  setEditData = () => {

    const { attributes } = this.state.JobDetails;

    const sanitizedAttributes = replaceNullWithEmptyString(JSON.parse(JSON.stringify(attributes)));
    setEditDataCity(this.setCity, sanitizedAttributes.country)
    this.setState({
      formOne: {
        job_title: sanitizedAttributes.job_title,
        company_name: sanitizedAttributes.company_name,
        country: sanitizedAttributes.country,
        city: sanitizedAttributes.city,
        job_categories: sanitizedAttributes.job_categories,
        job_description: sanitizedAttributes.job_description,
        post_expire_after: sanitizedAttributes.post_expire_after
      },
      formTwo: {
        salary_from: sanitizedAttributes.salary_from,
        salary_to: sanitizedAttributes.salary_to,
        disclose: sanitizedAttributes.disclose,
        experience_required: sanitizedAttributes.experience_required,
        industry_type: sanitizedAttributes.industry_type,
        department: sanitizedAttributes.department,
        skills_required: sanitizedAttributes.skills_required,
        education: sanitizedAttributes.education,
        currency: sanitizedAttributes.currency
      },
      formThree: sanitizedAttributes.questions.map((question: QuestionFormate) => ({ ...question, options: question.options.map((que: any) => ({ title: que, id: uuidv4() })) })),
    })
  }

  companyListHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      this.handleInputChangeDebounced(value);
    }
  };

  handleCountryChange = (value: { value: string; label: string; }[]) => {
    this.setState({
        cities: value
    })
}




  handleInputChangeDebounced = debounce((value: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": getLoginToken()
    };
    let userData = { search_name: value || '' };

    const updateUserDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.companyListApiId = updateUserDetails.messageId;

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/company_listing`
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(userData)
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
  }, 300)


  handleChangeJobStatus = async (jobId: string | number, status: string) => {
    const headers = {
      "token": await getStorageData("authToken")
    };

    const updateUserDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.jobStatusChangeApiId = updateUserDetails.messageId;

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_joblisting/jobs/${jobId}/manage_job_status?status=${status}`
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateUserDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(updateUserDetails.id, updateUserDetails);
  }
  subscriptionDetails  = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("authToken")

    };
  
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}

// Customizable Area End
