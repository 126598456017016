import React, { Component } from "react";
import {
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
const Height = Dimensions.get("window").height;
import { Avatar, Divider, Grid, Typography, Box } from "@material-ui/core";
import { down, downreIcon, likedUpvote, commentIcon, dotIcon, more, save, saveBlue, upIcon, saveBlueOutlined } from "../assets";
import { styled } from "@material-ui/styles";
import SliderComponent from "../../../comments/src/components/SliderComponent.web";
import { Comment, Post, UserDetails } from "../RecruiterProfileController.web";
import PostCommentTextFieldWeb from "./PostCommentTextField.web";
import CommentComponent from "./CommentComponent.web";
import CustomMenu from "./CustomMenu.web";
import { PostDetails } from "../../../../../packages/blocks/advancedsearch/src/AdvancedSearchController";
import { handleCondition } from "../../../../../packages/components/src/Utils.web";

interface Props {
  handleRecruiterNavigation: (recruiterId: number) => void,
  posts: Post[],
  upvotePost: (id: string, type: string, activityType: string) => void,
  downvotePost: (id: string, type: string, activityType: string) => void,
  activityType: string,
  toggleComments: (post: Post) => void,
  postShowID: number | string
  createComment: any,
  upvotePostComment: (id: number | string, type: string, postId?: string | number) => void,
  downvotePostComment: (id: number | string, type: string, postId?: string | number) => void,
  openConfirmDialog: (id: number | string, menuType?: 'connect' | 'following' | 'hidePost' | 'reportPost', invitationId?: number | string | boolean) => void,
  userDetails: UserDetails,
  expandedPosts?: string[],
  toggleReadMore: (postId: string) => void,
  showReadMore?: boolean,
  openEditFeedModal: (post: Post) => void,
  handleSavePost: (id: number | string, isSaved?: boolean) => void,
  getInitials: (value?: string) => string,
  isFullPage?: boolean,
  isAdvanceSearch?: boolean,
  progressComment:number,
  remainingTimeComment:number,
  loaderComment:boolean,
  handleDeleteComment: (id: string | number) => void;
  updateComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string) => void;
  handleNameClick: (accountId: string | number) => void
}

export default class CustomPost extends Component<Props> {
  static defaultProps: Partial<Props> = {
    posts: []
  };

  constructor(props: Props) {
    super(props);
  }

  renderCustomMenu = (post: Post) => <>
    <CustomMenu
      hideSelectedIcon
      buttonIcon={more}
      menuItems={[
        { label: 'Edit', onClick: () => { this.props.openEditFeedModal(post) } },
        { label: 'Delete', onClick: () => { this.props.openConfirmDialog(post.attributes.id) } },
      ]}
    />
  </>

  renderCustomMenuAdvanceSearch = (post: PostDetails) => {
    const connectionStatus : string = handleCondition(post?.attributes?.connection_request_details?.status === 'pending', "Pending Request",
    handleCondition(post?.attributes?.connection_request_details?.status === 'accepted', "Connected", "Connect")
    );
    
    const menuType = (connectionStatus === 'Connected' || connectionStatus === 'pending') ? undefined : 'connect';

    return (<CustomMenu
      hideSelectedIcon
      buttonIcon={more}
      menuItems={[
        { label: connectionStatus, onClick: () => { this.props.openConfirmDialog(post.attributes.account.account_id, menuType, post?.attributes?.connection_request_details?.id) } },
        { label: post.attributes.following_account ? 'Following' : 'Follow', onClick: () => { this.props.openConfirmDialog(post.attributes.account.account_id, 'following', post.attributes.following_account) }, showTickIcon: post.attributes.following_account },
        { label: 'Hide Post', onClick: () => { this.props.openConfirmDialog(post.attributes.id, 'hidePost') } },
        { label: 'Report', onClick: () => { this.props.openConfirmDialog(post.attributes.id, 'reportPost') } },
      ]}
    />)
  }

  renderDislikeIcon = (disliked : boolean) => {
    return <img style={{ width: '15.94px', height: '15.94px' }} src={disliked ? downreIcon : down} />
  }

  renderSaveIcon = (isSaved : boolean, postId: number) => {
    return <img onClick={() => this.props.handleSavePost(postId, isSaved)} src={isSaved ? saveBlue : save} style={{cursor: "pointer"}} />
  }

  renderMenuSearch = (isOwnPost: boolean, post: PostDetails) => {
    return !this.props.isAdvanceSearch ? (
      isOwnPost && this.renderCustomMenu(post)
    ) : (
      this.renderCustomMenuAdvanceSearch(post)
    )
  }

  truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength);
  };

  renderLikeIcon = (isLiked: boolean) => {
    return <img style={{ width: '15.94px', height: '15.94px' }} src={isLiked ? likedUpvote : upIcon} />
  }

  renderSaveText = (isSaved: boolean) => {
    return <Typography style={webStyle.footerText}>{isSaved ? `Unsave` :`Save`}</Typography>
  }
  renderImageOfIcon = (condition: boolean, element: JSX.Element, elementTwo: JSX.Element) => {
    return condition  ? element : elementTwo
  }

  render() {
    return (
      <CustomScrollBox
        style={{
          ...webStyle.middle,
          ...(this.props.isFullPage ? webStyle.fullPageStyle : webStyle.defaultStyle),
          overflowY: "scroll",
          overflowX: "hidden",
        }}
        >
        <Grid item sm={12} container>
          {this.props.posts.map((post: any, index) => {
            const { full_name = 'U'} = this.props.userDetails;
            const { images: postImages = [], is_saved: savedPost } = post.attributes;
            const { activityType, getInitials } = this.props;
            const isNotPost = activityType !== 'posts';
            const initials = getInitials(full_name);
            const isReacted = post.attributes?.isdisliked || post.attributes?.isliked || false
            const isCommented = post.attributes.comment.filter((item: any) => Number(item.account.account_id) === Number(this.props.userDetails?.account_details?.id)).length > 0;
            const commentText = activityType === 'comments' ? `${initials} commented on this` : `${initials} reacted to this post`;            
            const isOwnPost = post.attributes.account_id === this.props.userDetails.account_details?.id;
            const activeTab = activityType === 'comments' ? isCommented : isReacted

              return (
                <>
                  <Grid key={index} item container style={webStyle.root1}>
                    {
                      post.attributes.deleted ?
                        <Grid style={{ width: "100%" }} >
                          <ul style={{
                            listStyle: 'none',
                            overflow: 'auto',
                            marginBottom: '8px',
                            padding: '0 8px'
                          }} className="postWrapperScroll" >
                            <li>
                              <Typography style={{
                                fontFamily: 'Arial',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#64748B'
                              }}>Post removed</Typography>
                              <Typography style={{
                                fontFamily: 'Arial',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#9C9C9C'
                              }}>Post successfully deleted</Typography>
                            </li>
                          </ul>
                          <Divider
                            style={{ height: "1px", width: "100%", marginBottom: "10px" }}
                          />
                        </Grid>
                        :
                        <>
                          {isNotPost && activeTab &&
                            <Box style={webStyle.commentHeading}>
                              <Avatar
                                style={{ height: "28px", width: "28px" }}
                                src={this.props.userDetails.photo}>
                              </Avatar>
                              <Typography style={{
                                fontSize: '12px',
                                fontWeight: 400,
                                fontFamily: 'Arial',
                                marginLeft: '16px',
                                color: '#1F497D',
                              }}>
                                {commentText}
                              </Typography>
                            </Box>}
                          <Grid style={webStyle.headerContainer}>
                            <Grid style={webStyle.profileleft}>
                              <Avatar data-test-id="profileImage"
                                style={{ height: "52px", width: "52px", border: "2px solid #6D97C1" }}
                                src={post.attributes.account.account_profile}
                                onClick={() => this.props.handleNameClick(+post.attributes.account.account_id,)}>
                              </Avatar>
                              &nbsp;
                              &nbsp;
                              <Typography data-test-id="accountName" style={webStyle.profileTitle} onClick={() => this.props.handleNameClick(+post.attributes.account.account_id,)}>
                                {post.attributes.account.account_name}
                              </Typography>
                            </Grid>
                            <Grid style={webStyle.profileRight}>
                              <img src={dotIcon} />
                              &nbsp;
                              <span style={webStyle.timeTitle}>{post.attributes.created_at}</span>
                              &nbsp;
                              &nbsp;
                              {this.renderSaveIcon(savedPost, post.id)}
                              &nbsp;
                              &nbsp;
                              {this.renderMenuSearch(isOwnPost, post)}
                            </Grid>
                          </Grid>
                          {this.props.showReadMore ?
                          
                            <Grid style={{ padding: '0px 20px' }}>
                              <Typography style={webStyle.postDescription}>
                                <span style={{ fontWeight: "bold" }}>{`[${post.attributes.sub_topic}] `}</span>
                                {handleCondition(!!this.props.expandedPosts?.includes(post.id), post.attributes.body, this.truncateText(post.attributes.body, 250))}
                                <span data-test-id="moreText" className="moreText" style={{ ...webStyle.postDescription, cursor: "pointer", color: "rgba(0,0,0,.6)" }} onClick={() => this.props.toggleReadMore!(post.id)}>{!this.props.expandedPosts?.includes(post.id) && post.attributes.body.length > 250 && `...more`}</span>
                              </Typography>
                            </Grid> :

                            <Grid style={{ padding: "0px 20px" }}>
                              <Typography style={webStyle.postDescription}>
                                <span>{`[${post.attributes.sub_topic}] `}</span>
                                {post.attributes.body}
                                </Typography>
                            </Grid>
                          }

                          {
                            postImages.length > 0 && <Grid
                              style={{ width: "100%", height: "130px", padding: "0px 15px", display: "flex", overflowY: "hidden", overflowX: "hidden" }}
                            >
                              <SliderComponent items={postImages} objectFit />
                            </Grid>
                          }
                          <Grid item container style={{ padding: "10px 20px" }}>

                            <Grid
                              item
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                              id="upvote"
                              data-test-id="upvote"
                              onClick={() => { this.props.upvotePost(post.id, "BxBlockPosts::Post", this.props.activityType) }}
                            >
                              {this.renderLikeIcon(post.attributes.isliked)}
                              &nbsp;
                              <Typography style={webStyle.footerText}>{post.attributes.likes_count}</Typography>
                            </Grid>
                            <Grid
                              item
                              style={{ display: "flex", alignItems: "center", paddingLeft: "5px", cursor: "pointer" }}
                              id="downvote"
                              data-test-id="downvote"
                              onClick={() => { this.props.downvotePost(post.id, "BxBlockPosts::Post", this.props.activityType) }}>
                              {this.renderDislikeIcon(post.attributes.isdisliked)}
                              &nbsp;
                              <Typography style={webStyle.footerText}>{post.attributes.dislikes_count}</Typography>
                            </Grid>

                            <Grid item data-test-id="toggleComment" id="toggle-comment" style={{ paddingLeft: "25px", display: "flex", alignItems: "center", cursor: "pointer" }}
                             onClick={() => this.props.toggleComments(post)}>
                              <img src={commentIcon} />&nbsp;
                              <Typography style={webStyle.footerText}>&nbsp;{post.attributes.comment_count}&nbsp;Comments</Typography>
                            </Grid>
                            <Grid
                              item
                              data-test-id="handleSavePost"
                              style={{ paddingLeft: "25px", display: "flex", alignItems: "center", cursor: "pointer" }}
                              onClick={() => this.props.handleSavePost(post.id, savedPost)}
                            >
                              {
                              this.renderImageOfIcon(savedPost, <img src={saveBlue} />, <img src={saveBlueOutlined} />)
                              }
                              &nbsp;
                              {this.renderSaveText(savedPost)}
                            </Grid>
                          </Grid>
                          {
                            this.props.postShowID !== post.id &&
                            <Divider style={{ height: "1px", width: "100%", marginBottom: "10px" }} />
                          }
                          {
                            this.props.postShowID === post.id &&
                            <PostCommentTextFieldWeb
                              loader={this.props.loaderComment}
                              progress={this.props.progressComment}
                              remainingTime={this.props.remainingTimeComment}
                              getInitials={this.props.getInitials}
                              userDetails={this.props.userDetails}
                              createComment={this.props.createComment}
                              post={post}
                              cmt={post.attributes.comment?.[0] || []}
                              isNested={false}
                              openNestedCommetns={() => { }} 
                              activityType={this.props.activityType} 
                              updateComment={this.props.updateComment}                            
                            />
                          }
                          {
                            this.props.postShowID === post.id &&
                            <Grid
                              style={{ padding: "28px 38px", paddingBottom: "0px", display: "flex", flexDirection: "column", width: "100%" }}
                            >
                              {
                                post.attributes.comment.map((comment: Comment, index: number) =>
                                  <CommentComponent
                                    loader={this.props.loaderComment}
                                    progress={this.props.progressComment}
                                    remainingTime={this.props.remainingTimeComment}
                                    getInitials={this.props.getInitials}
                                    userDetails={this.props.userDetails}
                                    key={index}
                                    comment={comment}
                                    index={index}
                                    isNested={false}
                                    parentlength={0}
                                    lastChild={false}
                                    createComment={this.props.createComment}
                                    post={post}
                                    upvotePostComment={this.props.upvotePostComment}
                                    downvotePostComment={this.props.downvotePostComment}
                                    activityType={this.props.activityType} 
                                    handleDeleteComment={this.props.handleDeleteComment} 
                                    updateComment={this.props.updateComment} 
                                    handleNameClick={this.props.handleNameClick}
                                  />)
                              }
                            </Grid>
                          }
                          {
                            this.props.postShowID === post.id &&
                            <Divider
                              style={{ height: "1px", width: "100%", marginBottom: "10px" }}
                            />
                          }
                        </>
                    }
                  </Grid >
                </>
              )
            }
            )}
        </Grid>
      </CustomScrollBox >
    );
  }
}

const webStyle = {
  root: { margin: "0px", width: "100%", padding: "0px", paddingBottom: "20px", height: "90vh", overflow: "hidden" },
  middle: {
    background: "white",
    padding: "20px 0px 0px",
    borderRadius: "16px",
    paddingBottom: '0px'
  },
  fullPageStyle: {
    maxHeight: '100%',
    width: "100%",
    marginBottom: '20px',
  },
  defaultStyle: {
    maxHeight: '315px',
    maxWidth: '100%',
    marginBottom: '',
  },
  root1: { padding: "0px" },
  commentHeading: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: '#6D97C133',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    paddingLeft: '5px',
    margin: '8px 20px'
  },
  headerContainer: { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", padding: "0px 20px" },
  profileleft: { display: "flex", alignItems: "center", },
  profileTitle: {
    color: "#17365D",
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "18px",
    cursor: 'pointer'
  },
  profileRight: { display: "flex", justifyContent: "space-between", alignItems: "center" },
  timeTitle: {
    fontFamily: "Arial",
    fontSize: "12px",
    fontWeight: 400,
    color: "#1F497D"
  },
  footerText: {
    color: "#17365D",
    fontSize: "12px",
    fontFamily: "Arial"
  },
  postImage: {},
  postDescription: {
    color: "#17365D",
    fontFamily: "Arial",
    fontWeight: 400,
    fontSize: "12px",
    padding: "8px 0px",
    wordBreak: "break-word" as 'break-word' | undefined,
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 16,
    paddingHorizontal: 19,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  images: {
    width: 90,
    height: 90,
    borderRadius: 10,
    marginBottom: '5%',
  },
  input: {
    width: '100%',
    paddingVertical: 10,
    borderBottomWidth: 1,
    color: '#252837',
    fontSize: 16,
    marginTop: 10
  },
  border: {
    borderBottomColor: '#eee',
  },
});

const CustomScrollBox = styled(Box)({
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '87px'
  },
  '&::-webkit-scrollbar-track': {
    borderRadius: '57px',
    background: 'var(--Base-Grey-5, #D9D9D9)'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#6C96C1',
    borderRadius: '57px',
    '&:hover': {
      background: '#3489e0',
    },
  },
  '& .moreText': {
    marginLeft: "6px",
    '&:hover': {
      textDecoration: "underline",
      color: "#0a66c2",
    }
  }
});
