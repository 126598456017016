import React from "react";
// Customizable Area Start
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AddWorkExperienceController, {
    Props,
} from "./AddWorkExperienceController.web";
import CustomTextField from "./components/CustomTextField";
import { bottomLeft, leftArrowicon, topRight } from "./assets";
import CustomSelectField from "./components/CustomSelectField";
import StyledCheckbox from "./components/StyledCheckbox";
import { Autocomplete } from "@material-ui/lab";
import AutoCompleteInputText from "./components/AutoCompleteInputText";
import { ToastContainer } from "react-toastify";
// Customizable Area End

export class AddWorkExperience extends AddWorkExperienceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const validationSchema = Yup.object().shape({
            onRole: Yup.boolean(),
            endRole: Yup.boolean(),
            title: Yup.string().required('Title is a required field'),
            currentTitle: Yup.string().required('Specialization sector is a required field'),
            companyName: Yup.string().required('Company Name is a required field'),
            startMonth: Yup.string().required('Start Date is a required field'),
            startYear: Yup.string().required('Typed value is a required field'),
            endMonth: Yup.string().required('End Date is a required field')
                .when('onRole', (onRole: string, schema: any) => {
                    return (onRole) ? schema.notRequired() : schema.required('End Date is a required field');
                }),
            endYear: Yup.string().required('Typed value is a required field')
                .when('onRole', (onRole: string, schema: any) => {
                    return (onRole) ? schema.notRequired() : schema.required('End Year is a required field');
                })
                .when(['onRole', 'startYear', 'startMonth', 'endMonth'],
                    (onRole: string, startYear: string, startMonth: string, endMonth: string, schema: any) => {
                        if (onRole) {
                            return schema
                        }
                        const months = [
                            'January', 'February', 'March', 'April',
                            'May', 'June', 'July', 'August',
                            'September', 'October', 'November', 'December'
                        ];

                        return schema.test({
                            test: function (endYear: string) {

                                const startYearValue = parseInt(startYear, 10);
                                const startMonthValue = months.indexOf(startMonth);
                                const endYearValue = parseInt(endYear, 10);
                                const endMonthValue = months.indexOf(endMonth);

                                if (endYearValue === startYearValue) {
                                    return endMonthValue > startMonthValue || this.createError({ message: 'End date must be after start date' });
                                } else {
                                    return endYearValue > startYearValue || this.createError({ message: 'End date must be after start date' });
                                }
                            },
                            message: 'End year must be after start year'
                        });
                    }),
        });
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <ProfileContainer>
                <Grid container className="page" id="pageContainer" >
                    <Formik
                        initialValues={this.state.initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            this.state.pageType === "Add" ?
                                this.handleSubmitForm(values, resetForm) :
                                this.editWorkExperience(values)
                        }}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={true}
                    >
                        {({ isSubmitting, handleChange, errors, touched, values, setFieldValue, setFieldTouched, resetForm }) => (
                            <Form style={{ width: "inherit" }}>
                                <Grid className="form1" container>
                                    <img src={topRight} alt="icons" className="topRight" />
                                    <img src={bottomLeft} alt="icons" className="bottomLeft" />
                                    <Grid item style={{ paddingLeft: "12px", display: "flex", alignItems: "center" }} sm={12}>
                                        <Typography
                                            style={{ display: "flex", alignItems: "center" }}
                                            className="cardTitle">
                                            &nbsp;
                                            <img
                                                src={leftArrowicon}
                                                alt="back"
                                                onClick={this.handleGoBack} />
                                            &nbsp;&nbsp;&nbsp;{this.state.pageType} Work Experience
                                        </Typography>
                                    </Grid>

                                    <Grid item container spacing={3} sm={12} style={{ margin: "0px", width: "auto" }}>
                                        <Grid item sm={12} md={6} xs={12} lg={6} className="inputField">
                                            <Field
                                                name="title"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Title"
                                                placeHolder="Data Engineer"
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6} className="inputField">
                                            <Field
                                                name="currentTitle"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Specialization Sector"
                                                placeHolder="Technology"
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6} className="inputField">
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.companyList || []}
                                                getOptionLabel={(option) => option.label || ''}
                                                value={{ value: values.companyName, label: values.companyName, icon: "" }}
                                                onChange={(event) => {
                                                    const selectedValue = (event.target as HTMLInputElement).textContent || '';
                                                    setFieldValue("companyName", selectedValue);
                                                }}
                                                onBlur={() => {
                                                    setFieldTouched('companyName', true);
                                                }}
                                                renderInput={(params) =>
                                                    <AutoCompleteInputText
                                                        name="companyName"
                                                        errors={!!(touched?.companyName && errors.companyName) && errors}
                                                        params={params}
                                                        title="Company"
                                                        placeHolder="Accenture"
                                                        onChange={(event) => {
                                                            setFieldValue("companyName", event.target.value);
                                                            this.companyListHandler(event);
                                                        }}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6}>

                                        </Grid>
                                        <Grid item sm={12}>
                                            <StyledCheckbox name="onRole" onChange={handleChange} label={"I am currently working in this role"} checked={values.onRole} />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6} className="dateField">
                                            <Field
                                                name="startMonth"
                                                component={CustomSelectField}
                                                type="string"
                                                variant="outlined"
                                                title="Start Date"
                                                placeHolder="Month"
                                                options={this.monthsData()}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6} className="dateField">
                                            <Field
                                                name="startYear"
                                                component={CustomSelectField}
                                                type="string"
                                                variant="outlined"
                                                title="&nbsp;"
                                                placeHolder="Year"
                                                options={this.yearsData()}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6} className="dateField">
                                            <Field
                                                name="endMonth"
                                                component={CustomSelectField}
                                                type="string"
                                                variant="outlined"
                                                title="End Date"
                                                placeHolder="Month"
                                                options={this.monthsData()}
                                                disabled={values?.onRole}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6} xs={12} lg={6} className="dateField">
                                            <Field
                                                name="endYear"
                                                component={CustomSelectField}
                                                type="string"
                                                variant="outlined"
                                                title="&nbsp;"
                                                placeHolder="Year"
                                                options={this.yearsData()}
                                                disabled={values?.onRole}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <StyledCheckbox name="endRole" onChange={handleChange} label="End current position for this role." checked={values.endRole} />
                                        </Grid>
                                        <Grid item sm={12} className="btnContainer1">
                                            <Button className="noBtn" onClick={this.handleGoBack}>
                                                Cancel
                                            </Button>
                                            <Button className="yesBtn" type="submit">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <ToastContainer/>
                </ProfileContainer>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
    },
    typography: {
    },
});

const ProfileContainer = styled(Box)({
    "& .page": {
        background: "#F3F4F8",
        padding: "50px 100px",
        maxWidth: "1280px",
        "@media(max-width:600px)": {
            padding: "50px 10px 50px 10px",
        },
    },
    "& .form1": {
        background: "#FFFFFF",
        width: "auto",
        margin: "0px",
        minHeight: "calc(100vh - 100px) !important",
        padding: "24px 40px 10px 40px",
        borderRadius: "8px 8px 32px 8px",
        position: "relative"
    },
    "& .noBtn": {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "1px solid  #1F497D",
        "background": "#FFF",
        "width": "120px",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none"
    },
    "& .yesBtn": {
        "border": "1px solid #C7D9F0",
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "#C7D9F0"
        },
        "borderRadius": "8px",
        "background": "#6D97C1",
        "display": "flex",
        "width": "120px",
        "height": "56px",
        "padding": "16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "textTransform": "none"
    },
    "& .btnContainer": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px"
    },
    "& .btnContainer1": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        paddingTop: "130px !important"
    },
    "& .cardTitle": {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginBottom": "32px",
        "cursor": "pointer"
    },
    "& .uploadTxt": {
        "display": "flex",
        "width": "121px",
        "justifyContent": "flex-end",
        "alignItems": "center",
        "gap": "6px",
        "borderRadius": "4px",
        "background": "#F4F4F4",
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "whiteSpace": "nowrap",
        "marginLeft": "13px"
    },
    "& .errorText": {
        "color": "#DC2626",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
    },
    "& .bottomLeft": {
        position: "absolute",
        bottom: "0px",
        left: "0px"
    },
    "& .topRight": {
        position: "absolute",
        top: "0px",
        right: "0px"
    },
    "& .inputField": {
        "& .MuiTextField-root .MuiInputBase-root": {
            "height": '44px'
        },
        "& .MuiAutocomplete-input" : {
            "padding" : '0 4px 4px 4px !important',
            "height": "100% !important"
        }
    },
    "& .dateField": {
        "& .MuiInput-input": {
            "height": '44px',
        },
        "& .MuiSelect-icon": {
            "right": '14px !important',
        }
    }
})

export default AddWorkExperience;
// Customizable Area End
