import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CustomButton from './CustomButton';
import { brifcaseBlueicon, chaticon, dollaricon, emptyLocation, saveicon, saveselectedicon, unlikedselected, unlikeicon } from '../assets';
import CustomButtonOne from './CustomButtonOne';
import { getLoginToken } from '../../../../components/src/Utils.web';
import { JobDetails } from '../CatalogueController1';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "borderRadius": "16px",
            "border": "1px solid #F4F4F4",
            "background": "#FFF",
            "pdding": "12px 0px",
            "position": "relative",
            "marginTop": "10px"
        },
        tag: {
            "borderRadius": "0px 18.182px 18.182px 0px",
            "background": "#6D97C1",
            "color": "#FFF",
            "textAlign": "right",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "lineHeight": "18px",
            "display": "flex",
            "padding": "0px 10px",
            "justifyContent": "center",
            "alignItems": "center",
            "position": "absolute",
            "let": "0px",
            "top": "12px"
        },
        title: {
            "color": "#17365D",
            "fontSize": "18px",
            "fontWeight": "bold",
            "lineHeight": "26px",
            "fontFamily": "Arial",
        },
        des: {
            "color": "#9C9C9C",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
        },
        expTxt: {
            "color": "#6D97C1",
            "fontFamily": "Arial",
            "fontSize": "14px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
        },
        noLongerText: {
            "color": "#DC2626",
            "fontFamily": "Arial",
            "fontSize": "12px",
            "fontStyle": "normal",
            "fontWeight": "lighter",
            "display": "flex",
            "alignItems": "center"
        },
        dividerHorizontal: {
            "height": "0.8px",
            "width": "100%"
        }
    }),
);
type Props = {
    getJobDetails: JobDetails,
    handleSaveAndUnSaveJob: (flag: boolean) => void,
    applyJobHandler: (status: boolean) => void,
    jobStatus?: boolean,
    handleLikeAndDislikeJob: (flag: boolean) => void
    goToSignUpPage: () => void,
    goToLoginPage: () => void,
    handleNavigationToMessage: () => void,
    getJobFilledDetails: () => void,
    handleApplyJob: (type: string) => void,
    getTitleOfApplyButton: (type: string) => string,
}

const JobTitleComponent = (props: Props) => {
    const { handleApplyJob, getTitleOfApplyButton, getJobFilledDetails, getJobDetails, handleSaveAndUnSaveJob, applyJobHandler, handleLikeAndDislikeJob, goToLoginPage, goToSignUpPage, handleNavigationToMessage } = props

    const classes = useStyles();

    return (
        <Grid item container className={classes.root} sm={12} >
            <div className={classes.tag}>Posted:{getJobDetails?.attributes?.created_at}</div>
            <Grid item container>
                <Grid item sm={8} xs={12} style={{ padding: "13px", paddingTop: "37px", }}>
                    <Typography className={classes.title}>
                        {getJobDetails?.attributes?.job_title}
                    </Typography>
                    <Typography className={classes.des}>
                        {getJobDetails?.attributes?.company_name}
                    </Typography>
                </Grid>
                <Grid item sm={4} style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}>
                    <CustomButton iconORName={chaticon} onlyIcon handleClick={() => handleNavigationToMessage()} /> &nbsp;&nbsp;&nbsp;&nbsp;
                    <CustomButton selected={getJobDetails.attributes.is_disliked} iconORName={getJobDetails.attributes.is_disliked ? unlikedselected : unlikeicon} onlyIcon handleClick={() => handleLikeAndDislikeJob(getJobDetails.attributes.is_disliked)} showTooltip tooltipTitle="Job not matching profile" /> &nbsp;&nbsp;&nbsp;&nbsp;
                    <CustomButton selected={getJobDetails.attributes.is_saved} iconORName={getJobDetails.attributes.is_saved ? saveselectedicon : saveicon} onlyIcon handleClick={() => handleSaveAndUnSaveJob(getJobDetails.attributes.is_saved)} />&nbsp;&nbsp;&nbsp;
                </Grid>
            </Grid>
            <Grid item container style={{ display: "flex", justifyContent: "space-between", padding: "0px 13px 13px 13px", flexWrap: "wrap" }}>
                <Grid item style={{ flex: 1, display: "flex", alignItems: "center" }} >
                    <img alt="icon" src={brifcaseBlueicon} />&nbsp;
                    <Typography className={classes.expTxt}>{getJobDetails?.attributes?.experience_required}</Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item style={{ flex: 1, paddingLeft: "20px", display: "flex", alignItems: "center" }}>
                    <Typography className={classes.expTxt} id="symbol">{getJobDetails.attributes.currency || "$"} </Typography>
                    &nbsp;
                    <Typography className={classes.expTxt} id="salary-text">{(getJobDetails.attributes.disclose ? getJobDetails.attributes.salary_from +" to " + getJobDetails.attributes.salary_to : "Not Disclosed")} </Typography></Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item style={{ flex: 1, paddingLeft: "20px", display: "flex", alignItems: "center" }}>
                    <img alt="icon" src={emptyLocation} />&nbsp;
                    <Typography className={classes.expTxt}>{getJobDetails?.attributes?.city}</Typography>
                </Grid>
            </Grid>
                { getJobDetails.attributes.status === "close" && <Divider orientation="horizontal" flexItem className={classes.dividerHorizontal}/>}
            <Grid item container style={{ padding: "13px" }}>
                {!getLoginToken() ?
                    <>
                                           <CustomButtonOne title="Register to Apply" type="outlined" applyJobHandler={goToSignUpPage } />&nbsp;&nbsp;&nbsp;&nbsp;

                        <CustomButtonOne title="Login to Apply" type="normal" applyJobHandler={goToLoginPage } />
                    </>
                    : <>          
{getJobDetails.attributes.is_applied ? (
                    <>
                    <CustomButtonOne title="Applied" type="secondary" /> &nbsp;&nbsp;&nbsp;
                    </>
                  )  :(
                    <>
               { getJobDetails.attributes.status !== "close"  && <><CustomButtonOne title={getTitleOfApplyButton(getJobDetails.attributes.job_type)} type="normal" applyJobHandler={() => {
                handleApplyJob(getJobDetails.attributes.job_type)
               }} />&nbsp;&nbsp;&nbsp;&nbsp;</>}
                </>
)}                    </>
                }
            {
                getJobDetails.attributes.status === "close" &&  <Typography className={classes.noLongerText}>
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="12" height="12" rx="6" fill="#DC2626"/>
                        <rect x="3.5" y="5.80078" width="5" height="1.4" rx="0.7" fill="white"/>
                    </svg>
                    &nbsp;
                    No longer accepting applications
                 </Typography>
            }
                
            </Grid>
        </Grid>
    )
}

export default JobTitleComponent