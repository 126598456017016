import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { filterIcon } from '../../blocks/leaderboard/src/assets';
import SkillSelect from './SkillSelect';
import ExperienceSelect from './ExperienceSelect';
import LocationSelect from './LocationSelect';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '4px',
    transition: 'background-color 0.3s, border 0.3s',
  },
  filterButtonActive: {
    backgroundColor: '#EEECE1', 
    border:' 1px solid #6D97C1',
    borderRadius:'6px'
  }
}));

const FilterToggle: React.FC = () => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState<string[]>([]);
  const [skillsList] = useState(['JavaScript', 'React', 'Node.js', 'Python']); 
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);

  const handleFilterToggle = () => {
    setShowFilters(!showFilters);
  };

  const handleExperienceClick = (opt: { id: number | string, title: string }) => {
    setSelectedExperience((prevExperience) =>
      prevExperience.includes(opt.title)
        ? prevExperience.filter(exp => exp !== opt.title)
        : [...prevExperience, opt.title]
    );
  };

  const skillsByHandleClick = (opt: { id: number | string, title: string }) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.includes(opt.title)
        ? prevSkills.filter(skill => skill !== opt.title)
        : [...prevSkills, opt.title]
    );
  };

  const handleCountryChange = (value: string) => {
    console.log('Country selected:', value);
  };

  const handleCityChange = (value: string) => {
    console.log('City selected:', value);
  };

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {showFilters && (
        <Box style={{ display: 'flex', gap: '10px', marginRight: '5px' }}>
          
          <ExperienceSelect
            options={['0-2 years', '3-6 years', '7-10 years', '10+ years']}
            fieldName="Year of Experience"
            handleMenuItemClick={handleExperienceClick}
            values={selectedExperience}
          />

          <SkillSelect
            options={skillsList}
            fieldName="Skills"
            handleMenuItemClick={skillsByHandleClick}
            values={selectedSkills}
          />

          <LocationSelect
            fieldName="Location"
            handleCountryChange={handleCountryChange}
            handleCityChange={handleCityChange}
            options={[]} 
            countries={[
              { label: 'USA', value: 'usa' },
              { label: 'Canada', value: 'canada' }
            ]}
            city={[
              { label: 'New York', value: 'new_york' },
              { label: 'San Francisco', value: 'san_francisco' }
            ]}
          />
        </Box>
      )}

      <Box
        className={`${classes.filterButton} ${showFilters ? classes.filterButtonActive : ''}`}
        onClick={handleFilterToggle}
      >
        <img src={filterIcon} alt="filterIcon" />
      </Box>
    </Box>
  );
};

export default FilterToggle;
