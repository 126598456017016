import React from "react";

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Grid, Typography } from "@material-ui/core";

import TermsConditionsDetailController, {
  Props,
} from "./TermsConditionsDetailControllerP2";
import { Helmet } from 'react-helmet';
import { META_TAGS } from '../../../../packages/components/src/Utils.web';
export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div style={{ background: '#f3f4f8', padding: '25px 45px 25px 45px', minWidth:"90%", minHeight: "90vh" }}>
                <Helmet>
          <title>Join Our Platform for Private Equity Professionals</title>
          <meta
            name="description"
            content={`
            Recruit Investor Relations Experts: Join Our Network 
            Corporate Development Recruitment: Join Our Platform 
            Join Our Network for Investor Relations Professionals 
            Corporate Development Jobs: Connect and Grow Here 
          `}
          />
          <meta
            name="keywords"
            content={META_TAGS}
          />

          <meta property="og:title" content="Join Our Platform for Private Equity Professionals" />
          <meta
            property="og:description"
            content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
          />
          <meta property="og:type" content="website" />

          <meta name="twitter:title" content="Join Our Platform for Private Equity Professionals" />
          <meta
            name="twitter:description"
            content="Unlock private equity career opportunities. Join our platform to connect with top firms. Start your journey today!"
          />
        </Helmet>
        <div style={{ background: 'white', padding: '20px 45px 20px 45px' }}>
          <div data-test-id={"btnEmailPage"} style={{ display: 'flex', marginBottom: '20px', cursor: 'pointer', alignItems: "center" }} onClick={() => this.goToLoginPageFromTerms()}>
            <ArrowBackIosRoundedIcon style={{ color: '#9C9C9C', marginLeft: '5px', width: '17px' }} />
            <Typography style={{ fontSize: '14px', marginLeft: '18px', color: '#17365D', fontFamily: "Arial", fontWeight: "bold" }}>{this.state.respTitle === 'privacy_policy' ? 'Privacy Policy' : 'Terms of Use'}</Typography>
          </div>
          <div data-test-id="terms-content"  dangerouslySetInnerHTML={{ __html: this.state.respData }} />
        </div>
      </div>
    );
    // Customizable Area End
  }
}

