import React, { ReactNode, ReactPortal, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Badge, Box, Button, Grid, IconButton, Select, Typography } from "@material-ui/core";
import CustomBottonComponent from "./CustomBottonComponent.web";
import Popover from '@material-ui/core/Popover';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { openMenuicon } from "../../blocks/catalogue/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { getStorageData, removeStorageData } from "../../../packages/framework/src/Utilities";
import { useRunEngine } from "../../../packages/blocks/utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../../packages/blocks/utilities/src/hooks/useBlockHelpers";

//@ts-ignore
import feedicon from "./feed.svg";
//@ts-ignore
import networkicon from "./network.svg";
//@ts-ignore
import jobsicon from "./jobs.svg";
//@ts-ignore
import messageicon from "./message.svg";
//@ts-ignore
import notificationicon from "./notification.svg";
//@ts-ignore
import talenticon from "./talent.svg"
//@ts-ignore
import settingicon from "./setting.svg"
//@ts-ignore
import bussinessIcon from './bussinessIcon.svg'
//@ts-ignore
import userIcon from './userIcon.svg'
//@ts-ignore
import RightIcon from './right_tick.svg'

import { getUserRole } from "./Utils.web";
import MergeEngineUtilities from "../../../packages/blocks/utilities/src/MergeEngineUtilities";
import { getMessaging, onMessage } from "firebase/messaging";

interface Props extends RouteComponentProps {
    loading?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        background: 'white',
        padding: '0px 50px 0px 50px',
        height: "76px",
        maxHeight: "76px",
        maxWidth: "1280px",
        '@media (max-width: 960px)': {
            padding: '0px 10px 0px 10px',
        }
    },
    rootLoggedIn: {
        background: 'white',
        padding: '5px 50px 5px 50px',
        height: "76px",
        maxHeight: "76px",
        maxWidth: "1280px",
        '@media (max-width: 960px)': {
            padding: '0px 10px 0px 10px',
        }
    },
    loginBtn: {
        background: '#6D97C1',
        color: 'white',
        textTransform: 'capitalize',
        marginTop: '5px'
    },
    theamCl: {
        color: '#334155'
    },
    rightSide: {
        marginTop: '5px'
    },
    endContainer: {
        display: "flex",
        alignItems: "center",
        alignSelf: "end"
    },
    linkContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%"
    },
    alingCenter: {
        display: "flex",
        alignItems: "center",
        width: "auto"
    },
    premiumString: {
        color: "rgba(31, 73, 125, 1)",
        textDecoration: "underline",
        textDecorationColor: "rgba(31, 73, 125, 1)",
        fontSize: "14px",
        fontFamily: "Arial",
        textAlign: "center",
        cursor: "pointer",
        padding: "0px 10px",
        textOverflow: "ellipsis",
        '@media (max-width: 960px)': {
            display: "none"
        }
    },
    linkTabContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "100%",
        padding: "0px 20px",
        cursor: "pointer",
        borderBottom: "2px solid transparent",
        '@media (max-width: 1080px)': {
            "padding": "0px 8px",
        },
        '@media (max-width: 960px)': {
            "padding": "0px 15px",
        },
        '@media (max-width: 460px)': {
            "padding": "0px 8px",
        },
        '@media (max-width: 400px)': {
            "padding": "0px 5px",
        }
    },
    linkTabContainerSelected: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "100%",
        borderBottom: "2px solid #1F497D",
        padding: "0px 20px",
        cursor: "pointer",
        '@media (max-width: 1080px)': {
            "padding": "0px 8px",
        },
        '@media (max-width: 960px)': {
            "padding": "0px 15px",
        },
        '@media (max-width: 460px)': {
            "padding": "0px 8px",
        },
        '@media (max-width: 400px)': {
            "padding": "0px 5px",
        }
    },
    badgeItem: {
        "& .MuiBadge-badge": {
            transform: "scale(1) translate(50%, -25%)",
            width: "5px",
            height: "5px",
            minWidth: "14px",
            fontSize: "12px",
            backgroundColor: "#DC2626",
            padding: "9px",
            borderRadius: "20px",
        },
        "& .MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible": {
            transform: "scale(0) translate(50%, -50%)",
        }
    },
    linkTitle: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#1F497D",
        fontWeight: "lighter",
        '@media (max-width: 660px)': {
            display: "none"
        }
    },
    linkTitleSelected: {
        fontFamily: "Arial",
        fontSize: "14px",
        color: "#1F497D",
        fontWeight: "bold",
        '@media (max-width: 660px)': {
            display: "none"
        }
    },
    borderLink: {
        height: "2px",
        color: "#1F497D",
        position: "absolute",
        width: "100px"
    },
    borderheader: {
        height: "6px",
        "width": "100%",
        "background": "linear-gradient(gray,#F3F4F8)",
    },
    profileSelector: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '& .MuiInputBase-root': {
            '& .MuiSelect-icon': {
                color: '#17365D'
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'white'
            }
        },
        '& .MuiInput-underline::before': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline::after': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: 'none'
        },
        '@media (max-width: 960px)': {
            display: "none"
        }
    },
    profileSelector1: {
        '& .MuiInputBase-root': {
            '& .MuiSelect-icon': {
                color: '#17365D'
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'white'
            }
        },
        '& .MuiInput-underline::before': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline::after': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottom: 'none'
        },
        '@media (min-width: 960px)': {
            display: "none"
        }
    },
    selectorOption: {
        color: "#17365D",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        fontFamily: "Arial",
        marginLeft: '10px',

    },
    openProfileItem: {
        backgroundColor: '#ffffff !important',
        cursor: 'alias'
    },
    openProfileButton: {
        borderRadius: 8,
        margin: "5px 0px 0px 17px",
        textTransform: "none",
        border: '1px solid #1F497D',
        '& .MuiButton-label': {
            color: '#1F497D',
            fontSize: '14px',
            fontWeight: '400',
            fontFamily: 'Arial'
        }

    },
    roleSelectorContainer: {
        '@media (max-width: 960px)': {
            display: "none"
        }
    },
    hamburgermenu: {
        display: "none",
        '@media (max-width: 960px)': {
            display: "block"
        }
    },
    roleSelectorContainer1: {

    },
    premiumString1: {
        color: "rgba(31, 73, 125, 1)",
        textDecoration: "underline",
        textDecorationColor: "rgba(31, 73, 125, 1)",
        fontSize: "14px",
        fontFamily: "Arial",
        textAlign: "center",
        cursor: "pointer",
        padding: "0px 4px",
        textOverflow: "ellipsis",
    },
    muiOptions: {
        marginTop: "17px"
    },
    muiOptionContainer: {
        margin: "10px 16px"
    },
    profContainer: {
        paddingLeft: "50px",
        display: "flex",
        alignItems: "center",
        '@media (max-width: 960px)': {
            paddingLeft: "10px",
        }
    },
    settingModal:{
        width:'184px',
        padding: '8px 10px',
        fontFamily: 'Arial',
        fontSize: '14px',
        fontWeight: 400,
        color:'rgba(23, 54, 93, 1)',
        lineHeight:'22px',
        cursor:'pointer'
      },
   
    popover: {
        borderRadius: '10px',
        padding: '4px',
        width:'192px',
        backgroundColor: '#FFFFFF',
        height:'82px',
        marginTop:'10px'
      },
    
}));


const LinkComponent = ({ unreadCount = 0, showBadge = false, url, title, path, handleNavigate = () => { } }: { unreadCount?: number | string, showBadge?: boolean, url: string, title: string, path: string, handleNavigate?: () => void }) => {
    const classes = useStyles();

    const isSelected = (pathName: string) => {
        return pathName.toLocaleLowerCase() === window.location.pathname.toLocaleLowerCase() || (pathName.toLocaleLowerCase() === '/landingpage' && window.location.pathname.toLocaleLowerCase().includes('/advancedsearchresults'))
    }

    return (
        <Grid item className={isSelected(path) ? classes.linkTabContainerSelected : classes.linkTabContainer} onClick={handleNavigate}>
            <Badge color="secondary" badgeContent={unreadCount} className={classes.badgeItem} invisible={!showBadge || unreadCount === 0}>
            <img src={url} alt="icon" style={{ height: "20px", width: "18px" }} />
            </Badge>
            <Typography className={isSelected(path) ? classes.linkTitleSelected : classes.linkTitle}>{title}</Typography>
            <div className={classes.borderLink}></div>
        </Grid >
    )
}


function AppTopHeader({ history }: Props) {
    const classes = useStyles();
    const [isLoggedInUser, setIsLoggedInUser] = React.useState(() => !!localStorage.getItem("authToken"));
    const [isCandidateUser, setIsCandidateUser] = React.useState(() => localStorage.getItem("userRole") === "candidate");
    const [select, setSelect] = React.useState('')
    const [isActiveSubsCription, setIsActiveSubscription] = React.useState(false);
    const [unreadCount, setUnreadCount] = React.useState<number | string>(0)
    const [profileDetails, setProfileDetails] = React.useState({ current_company: "",company_name: "", account_details: { role: "" }, full_name: '', current_title: "", photo: undefined })
    const subscribedMessages = [
        MessageEnum.RestAPIResponceMessage,
        MessageEnum.SessionResponseMessage,
        MessageEnum.NavigationMessage,
        MessageEnum.NavigationTargetMessage
    ];

    const getprofileDetailsCallId = useRef("");
    const getSubscriptionApiCallId = useRef("");
    const getNotificationApiId = useRef("")


    const {
        sendNetworkRequest,
        setReceiveCallback,
        subscribe,
        unsubscribeFromMessage,
        sendMessage
    } = useRunEngine();
    
    const { extractNetworkResponse } = useBlockHelpers();

    useEffect(() => {
        setReceiveCallback(receive);
    
        subscribedMessages.forEach((message) => subscribe(message));
        if(isLoggedInUser) {
            getSubscriptionDetails() 
            getProfileData()
            getNotification()
        }
        return () => {
            subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
        };
    }, []);
    const getSubscriptionDetails=async()=>{
        let token = await getStorageData("authToken");
        const header = {
          'Content-Type': "application/json",
          token: token
        };
        sendNetworkRequest(
          getSubscriptionApiCallId,
          "GET",
          `bx_block_subscriptions/subscriptions/user_subscription_details`,
          header,
        );
      }

    const receive = async (from: string, message: Message) => {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
            if(isLoggedInUser && responseJson?.errors?.[0]?.token) {
                handleLogout();
            }
            if (apiRequestCallId === getprofileDetailsCallId.current) {
                window.dispatchEvent(new CustomEvent("USER_PROFILE_DETAILS", { detail: responseJson.data }))
            }
            if (apiRequestCallId === getSubscriptionApiCallId.current) {
                if(responseJson.error === "You don't have any active subscription plan!") {
                    setIsActiveSubscription(false)
                }
                if(!responseJson.error && responseJson.user_subscription.data.attributes.status === "active") {
                    setIsActiveSubscription(true)
                }
            } 
            if (apiRequestCallId === getNotificationApiId.current) {
                const countValue  = handleCountValue(responseJson.unread_notifications_count || 0)
                setUnreadCount(countValue)
            }
        }
        // Customizable Area End
    };

    const getProfileData = async () => {
        const headers = {
          "Content-Type": "application/json",
          token: await getStorageData("authToken"),
        };

        sendNetworkRequest(
            getprofileDetailsCallId,
            "GET",
            "bx_block_profile/profiles",
            headers,
        );
    };

    const getNotification = async (event?: CustomEvent<any>) => {
        const headers = {
          "Content-Type": "application/json",
          token: await getStorageData("authToken"),
        };

        sendNetworkRequest(
            getNotificationApiId,
            "GET",
            "bx_block_push_notifications/push_notifications",
            headers,
        );
    };

    const gotoLogin = () => {
        history.push("/EmailAccountLoginBlock");
    }

    const gotoHome = () => {
        if(isFirstTimeLogin()) {
            history.push("/");
        }
    }

    const handleSettingNavigation =()=> {
        if(isFirstTimeLogin()) {
            history.push("/Settings2");
        }
      }

    const handleLogout = async () => {
        handleClose()
        await removeStorageData("authToken");
        await removeStorageData("userRole");
        await removeStorageData("userRole");
        await removeStorageData("userID");
        await removeStorageData("companyName");
        setIsLoggedInUser(false);
        setIsCandidateUser(false)
        window.dispatchEvent(new CustomEvent("LOGOUT", { detail: false }))
        history.push("/LandingPage")
    }

    const handleNavigate = () => {
        if(isFirstTimeLogin()) {
            history.push("/Customisableusersubscriptions");
        }
    }

    //Popover props

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [anchorElHam, setAnchorElHam] = React.useState<HTMLButtonElement | null>(null);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickHam = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElHam(event.currentTarget);
    };

    const handleCloseHm = () => {
        setAnchorElHam(null);
    };


    const isCandidate = () => {
        return localStorage.getItem("userRole") === "candidate"
    }
    const isloggedIn = () => {
        return !!localStorage.getItem("authToken")
    }

    const isFirstTimeLogin = () => {
        const setProfile = localStorage.getItem("setProfile")
        const companyName = localStorage.getItem("companyName")
        
        if(getUserRole() === "candidate") {
            return setProfile === 'true'
        } else if(getUserRole() === "recruiter") {
            return setProfile === 'true' && companyName !== "null"
        }
        return false
    }

    const handleChange = (value: string) => {
        setSelect(value);
        window.dispatchEvent(new CustomEvent("USER_PROFILE_NAME", { detail: value }))
    }
    const handleSelect = (event: CustomEvent<any>) => {
        setSelect(event.detail)
    }

    const getProfileDetails = (event: CustomEvent<any>) => {
        setProfileDetails(event.detail.attributes)
        setSelect(event.detail.attributes.selected_group_context)
    }
    const handleProfileClick = () => {
        if(isFirstTimeLogin()) {
            getUserRole() === "recruiter" ? history.push("/RecruiterProfile") : history.push("/JobApplicantProfile")
        }
    }

    const messaging = getMessaging();

    useEffect(() => {
        onMessage(messaging, () => {
            getNotification()
            window.dispatchEvent(new CustomEvent("GET_NOTIFICATION_DATA_REAL_TIME", {detail: {}}))
        })
        window.addEventListener("USER_PROFILE_DETAILS", getProfileDetails as EventListener);
        window.addEventListener("SET_USER_PROFILE_NAME", handleSelect as EventListener);
        
        window.addEventListener("GET_NOTIFICATION_DATA", getNotificationRealTime as unknown as EventListener);
    }, [])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const openHam = Boolean(anchorElHam);
    const idHam = openHam ? 'simple-popover' : undefined;

    const getNotificationRealTime = (event: CustomEvent) => {
        setUnreadCount(handleCountValue(event.detail.unReadCount || 0))
    }

    const handleCountValue = (countNumber: number) => {
        return Number(countNumber) > 99 ? '99+' : countNumber 
    }

    const handleLandingPageRoute = () => {
        window.dispatchEvent(new CustomEvent("RENDER_MY_FEED", {detail: {}}))
        history.push("/LandingPage")
    }

    const handleNavigateNotification = () => {
        getNotification()
        history.push("/Notifications")
    }

    return (
        <>
            <Grid container justifyContent="space-between" className={!isloggedIn() ? classes.rootLoggedIn : classes.root}>
                <Grid item className={classes.alingCenter}>
                    <img src={require("./topLogo.png")} width={50} height={50} style={{ cursor: "pointer" }} onClick={gotoHome} />
                </Grid>
                <Grid style={{ display: "flex", justifyContent: "end", alignItems: " center", flex: 1 }}>
                    {isloggedIn() && isFirstTimeLogin() && <LinkComponent title="My Feed" url={feedicon} path="/LandingPage" handleNavigate={() => handleLandingPageRoute()} />}
                    {isloggedIn() && isFirstTimeLogin() && <LinkComponent title="My Network" url={networkicon} path="/Followers" handleNavigate={() => history.push("/Followers")} />}
                    {isloggedIn() && isFirstTimeLogin() && isCandidate() && <LinkComponent title="Jobs" url={jobsicon} path="/Catalogue" handleNavigate={() => history.push("/Catalogue")} />}
                    {isloggedIn() && isFirstTimeLogin() && !isCandidate() && <LinkComponent title="Job Post" url={jobsicon} path="/JobListingRecruiter" handleNavigate={() => history.push("/JobListingRecruiter")} />}
                    {isloggedIn() && isFirstTimeLogin() && !isCandidate() && <LinkComponent title="Talent" url={talenticon} path="/Talent" handleNavigate={() => history.push("/Talent")} />}
                    {isloggedIn() && isFirstTimeLogin() && <LinkComponent title="Messaging" url={messageicon} path="/Messaging" handleNavigate={() => history.push("/Messaging")}/>}
                    {isloggedIn() && isFirstTimeLogin() && <LinkComponent unreadCount ={unreadCount} showBadge title="Notifications" url={notificationicon} path="/Notifications" handleNavigate={()=> handleNavigateNotification()}/>}
                    {
                        (!isLoggedInUser) && <Avatar style={{ marginRight: "30px", backgroundColor: "transparent", width: 40, height: 40, fontSize: '14px' }}><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="20" fill="#F4F4F4" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 12C17.79 12 16 13.79 16 16C16 18.21 17.79 20 20 20C22.21 20 24 18.21 24 16C24 13.79 22.21 12 20 12ZM22.1 16C22.1 14.84 21.16 13.9 20 13.9C18.84 13.9 17.9 14.84 17.9 16C17.9 17.16 18.84 18.1 20 18.1C21.16 18.1 22.1 17.16 22.1 16ZM26.1 25C26.1 24.36 22.97 22.9 20 22.9C17.03 22.9 13.9 24.36 13.9 25V26.1H26.1V25ZM12 25C12 22.34 17.33 21 20 21C22.67 21 28 22.34 28 25V27C28 27.55 27.55 28 27 28H13C12.45 28 12 27.55 12 27V25Z" fill="#1F497D" />
                        </svg>
                        </Avatar>
                    }
                    {isLoggedInUser && <div className={classes.profContainer}>
                        <Avatar style={{ backgroundColor: "transparent", width: 40, height: 40, fontSize: '14px' }} src={profileDetails.photo}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="20" fill="#F4F4F4" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 12C17.79 12 16 13.79 16 16C16 18.21 17.79 20 20 20C22.21 20 24 18.21 24 16C24 13.79 22.21 12 20 12ZM22.1 16C22.1 14.84 21.16 13.9 20 13.9C18.84 13.9 17.9 14.84 17.9 16C17.9 17.16 18.84 18.1 20 18.1C21.16 18.1 22.1 17.16 22.1 16ZM26.1 25C26.1 24.36 22.97 22.9 20 22.9C17.03 22.9 13.9 24.36 13.9 25V26.1H26.1V25ZM12 25C12 22.34 17.33 21 20 21C22.67 21 28 22.34 28 25V27C28 27.55 27.55 28 27 28H13C12.45 28 12 27.55 12 27V25Z" fill="#1F497D" />
                        </svg>
                        </Avatar>
                        <Box className={classes.profileSelector}>
                            <Select
                                IconComponent={KeyboardArrowDownIcon}
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={select}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        className: classes.muiOptions
                                    }
                                }}
                                //@ts-ignore
                                renderValue={(value) => <span className={classes.selectorOption} style={{ fontWeight: '400' }}>{value}</span>}
                            >
                                <div className={classes.muiOptionContainer} style={{ opacity: 'initial', color: "#17365D", fontSize: "12px", fontWeight: 400, lineHeight: "18px", fontFamily: "Arial", cursor: "default" }}>
                                    Select what others will see when you post
                                </div>
                                <div className={classes.muiOptionContainer} onClick={() => handleChange(profileDetails?.full_name)}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', cursor: "pointer" }}>
                                        <Avatar style={{ backgroundColor: "#6D97C1", width: 32, height: 32, fontSize: '14px' }}>{profileDetails?.full_name?.split('')?.[0]}</Avatar>
                                        <Typography className={classes.selectorOption} style={{ fontWeight: select === profileDetails?.full_name ? '700' : '400' }}>{profileDetails?.full_name}</Typography>
                                        {(!select || select === profileDetails?.full_name) && <img style={{ position: 'absolute', right: "40px" }} src={RightIcon} />
                                        }
                                    </div>
                                </div>
                                <div className={classes.muiOptionContainer} onClick={() => handleChange(`Work at ${profileDetails?.current_company}`)}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', cursor: "pointer" }}>
                                        <Avatar style={{ backgroundColor: "#6D97C1", width: 32, height: 32 }}><img src={bussinessIcon} /></Avatar>
                                        <Typography className={classes.selectorOption} style={{ fontWeight: select === `Work at ${profileDetails?.current_company}` ? '700' : '400' }}>Work at {profileDetails?.current_company || profileDetails?.company_name}</Typography>
                                        {select === `Work at ${profileDetails?.current_company || profileDetails?.company_name}` && <img style={{ position: 'absolute', right: "40px" }} src={RightIcon} />
                                        }
                                    </div>
                                </div>
                                {isCandidate() && <div className={classes.muiOptionContainer} onClick={() => handleChange(`as ${profileDetails?.current_title}`)}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center', cursor: "pointer" }}>
                                        <Avatar style={{ backgroundColor: "#6D97C1", width: 32, height: 32 }}><img src={userIcon} /></Avatar>
                                        <Typography className={classes.selectorOption} style={{ fontWeight: select === `as ${profileDetails?.current_title}` ? '700' : '400' }}>as {profileDetails?.current_title}</Typography>
                                        {select === `as ${profileDetails?.current_title}` && <img style={{ position: 'absolute', right: "40px" }} src={RightIcon} />
                                        }
                                    </div>
                                </div>}
                                <Button variant="outlined" className={classes.openProfileButton} onClick={handleProfileClick}>Open my profile</Button>
                            </Select>
                        </Box>
                    </div>}
                    {isLoggedInUser &&
                        <IconButton onClick={handleClick} style={{ padding: "0px 5px" }}>
                            <img src={settingicon} />
                        </IconButton>
                    }
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        classes={{
                            paper: classes.popover, 
                          }}
                    

                    >
                        {<div >
                            <Grid item  >
                            {!isLoggedInUser ? <></> : 
                                  <Box 
                                  className={classes.settingModal}
                                  onClick={()=>{handleSettingNavigation()
                                    handleClose()
                                  }}
                                  >Go to Settings</Box> 
                                }
                                {!isLoggedInUser ? <></> :
                                  <Box className={classes.settingModal}
                                  onClick={() => {handleLogout() 
                                    handleClose()}}
                                  >Logout</Box>                           
                                }
                              

                            </Grid>

                        </div>}
                    </Popover>
                    {!isLoggedInUser ? <CustomBottonComponent title="Login" onClick={() => gotoLogin()} type="normal" style={{ height: "40px", marginLeft: "10px" }} /> : <></>}
                    <IconButton style={{ padding: "0px 5px" }} id="modal-one" className={classes.hamburgermenu} onClick={handleClickHam}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM10 18C10 16.9 10.9 16 12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18Z" fill="#64748B" />
                        </svg>
                    </IconButton>
                    <Popover
                        id={idHam}
                        open={openHam}
                        anchorEl={anchorElHam}
                        onClose={handleCloseHm}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}

                    >
                        {<div style={{
                            padding: "20px"
                        }}>
                            {isLoggedInUser && <Typography className={classes.premiumString1}>
                                Get top Candidates
                                Try Premium
                                free
                            </Typography>}
                            <Grid item >
                                {!isLoggedInUser ? <></> : <CustomBottonComponent title="Log Out" onClick={() => handleLogout()} type="normal" style={{ height: "40px" }} />}
                            </Grid>

                        </div>}
                    </Popover>
                    {isLoggedInUser && !isCandidate() && !isActiveSubsCription && <Typography onClick={() => handleNavigate()} className={classes.premiumString}>
                        Get top Candidates<br />
                        Try Premium<br />
                        free
                    </Typography>}

                </Grid>
            </Grid >
            <div className={classes.borderheader}></div>
        </>
    )
}

export default withRouter(AppTopHeader);